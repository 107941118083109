import {
    Avatar, Button, Card, Col, Form,
    Input, Modal, Row, Select,
    Space, Typography
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RiNumber1, RiNumber2 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from '../api/axios';
const PRODUCT_URL = (business_id) => `business/${business_id}/product/`;
const CATEGORY_URL = (business_id) => `business/${business_id}/category/`;

const { Title } = Typography;
const { Option } = Select;

const AddProduct = () => {
    const errRef = useRef(null);
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id");
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [price, setPrice] = useState('');
    const [name, setName] = useState('');
    const [category_name, setCategoryName] = useState('');
    const [category_id, setCategoryId] = useState();
    const [category_list, setCategorList] = useState([]);
    const [units_available, setUnits] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setErrMsg('');
    }, [name, units_available, price])

    const onChangeCategoryName = (e) => {
        const category_name = e.target.value;
        setCategoryName(category_name);
    }

    const onChangeName = (e) => {
        const name = e.target.value;
        setName(name);
    };
    const onChangePrice = (e) => {
        const price = e.target.value;
        setPrice(price);
    };
    const onChangeUnits = (e) => {
        const units_available = e.target.value;
        setUnits(units_available);
    };

    const fetchCategoryData = useCallback(async () => {
        try {
            const response = await axios.get(CATEGORY_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setCategorList(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id, setCategorList])

    useEffect(() => {
        fetchCategoryData().catch(console.error)
    }, [fetchCategoryData]);

    const handleCategory = async () => {
        try {
            await axios.post(CATEGORY_URL(business_id),
                JSON.stringify({ name: category_name }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            fetchCategoryData()
            handleOk()
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add Category');
            }
            errRef.current.focus();
        }
    }

    const handleSubmit = async () => {
        try {
            await axios.post(PRODUCT_URL(business_id),
                JSON.stringify({ name, price, units_available, category_id }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            setSuccess(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add Product');
            }
            errRef.current.focus();
        }
    }

    const handleCategorySelect = (key) => {
        setCategoryId(key)
    }

    if (success) {
        return <Redirect to="/products" />
    }
    if (!token) {
        return <Redirect to="/" />
    }
    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                <Col xs={24} md={12} className="col-info">
                    <Card bordered={false} className="criclebox ">
                        <Title className="font-regular text-muted" level={4}>
                            Add Product Details here
                        </Title>
                        <p ref={errRef} className='Error-p'>
                            {errMsg}
                        </p>
                        <Form
                            onFinish={handleSubmit}
                            layout="vertical"
                            className="row-col"
                        >
                            <Form.Item
                                label={<>Product Name<span style={{ color: "red" }}>*</span></>}
                                className="username"
                                name="name"
                                onChange={onChangeName}
                                value={name}
                                rules={[{ required: true, message: 'Please input product name' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={<>Price<span style={{ color: "red" }}>*</span></>}
                                className="username"
                                name="price"
                                onChange={onChangePrice}
                                value={price}
                                rules={[{ required: true, message: 'Please input price' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Category"
                                className="username"
                            >
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={14}>
                                            <Select
                                                showSearch
                                                placeholder="Select a Category"
                                                optionFilterProp="children"
                                                onChange={handleCategorySelect}
                                                filterOption={(input, option) =>
                                                    option.value1.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: 200 }}
                                            >
                                                {category_list.map((c) => (

                                                    <Option value1={c.name} key={c.category_id}>{c.name}</Option>

                                                ))}
                                            </Select>
                                        </Col>
                                        <Col span={10}>
                                            <div className="header-col sign-in-btn">
                                                <Button
                                                    type="primary"
                                                    shape="round"
                                                    style={{
                                                        background: "#45BC5B",
                                                        borderColor: "#45BC5B"
                                                    }}
                                                    onClick={showModal}
                                                ><p style={{ color: '#fff' }}>Add Category</p>
                                                </Button>
                                            </div>
                                            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                                                <Card className="criclebox ">
                                                    <Title className="font-regular text-muted" level={4}>
                                                        Add Category Details here
                                                    </Title>
                                                    <p ref={errRef} className='Error-p'>
                                                        {errMsg}
                                                    </p>
                                                    <Form onFinish={handleCategory}>
                                                        <Form.Item
                                                            className="username"
                                                            label="Category Name"
                                                            name="name"
                                                            id="name"
                                                            onChange={onChangeCategoryName}
                                                            value={name}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please input Category Name!",
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Category Name" />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <div className="header-col sign-in-btn">
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    style={{ width: "100%", background: "#45BC5B" }}
                                                                    shape="round"
                                                                >
                                                                    ADD
                                                                </Button>
                                                            </div>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label={<>Units Available<span style={{ color: "red" }}>*</span></>}
                                className="username"
                                name="units_available"
                                onChange={onChangeUnits}
                                value={units_available}
                                rules={[{ required: true, message: 'Please input price' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <div className="header-col sign-in-btn">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%", background: "#45BC5B" }}
                                        shape="round"
                                    >
                                        ADD
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Row className="dash-web-layout">
                <Space direction="vertical" size='large'>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={24}>
                                <Title level={3}>Add Product</Title>
                            </Col>
                        </Row>
                    </div>

                    <Form onFinish={handleSubmit}
                        layout="vertical"
                        className="row-col">
                        <Row gutter={[24, 0]}>
                            <Col span={24} md={20} lg={20} className="mb-24 ">
                                <Card bordered={false} className="criclebox ">
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col span={20}>
                                                <Avatar shape="circle" icon={<RiNumber1 />} />
                                                <Title level={4}>Select Category</Title>
                                            </Col>
                                            <Col span={20}>
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={14}>
                                                            <Select
                                                                showSearch
                                                                width="200px"
                                                                placeholder="Select a Category"
                                                                optionFilterProp="children"
                                                                onChange={handleCategorySelect}
                                                                filterOption={(input, option) =>
                                                                    option.value1.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                style={{ width: 200 }}
                                                            >
                                                                {category_list.map((c) => (

                                                                    <Option value1={c.name} key={c.category_id}>{c.name}</Option>

                                                                ))}
                                                            </Select>
                                                        </Col>
                                                        <Col span={10}>
                                                            <div className="header-col sign-in-btn">
                                                                <Button
                                                                    type="primary"
                                                                    shape="round"
                                                                    onClick={showModal}
                                                                ><p style={{ color: '#fff' }}>Add Category</p>
                                                                </Button>
                                                            </div>
                                                            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                                                                <Card className="criclebox ">
                                                                    <Title className="font-regular text-muted" level={4}>
                                                                        Add Category Details here
                                                                    </Title>
                                                                    <p ref={errRef} className='Error-p'>
                                                                        {errMsg}
                                                                    </p>
                                                                    <Form onFinish={handleCategory}>
                                                                        <Form.Item
                                                                            className="username"
                                                                            label="Category Name"
                                                                            name="name"
                                                                            id="name"
                                                                            onChange={onChangeCategoryName}
                                                                            value={name}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please input Category Name!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Category Name" />
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            <div className="header-col sign-in-btn">
                                                                                <Button
                                                                                    type="primary"
                                                                                    htmlType="submit"
                                                                                    style={{ width: "100%", background: "#45BC5B" }}
                                                                                    shape="round"
                                                                                >
                                                                                    <p style={{ color: '#fff' }}> ADD</p>
                                                                                </Button>
                                                                            </div>
                                                                        </Form.Item>
                                                                    </Form>
                                                                </Card>
                                                            </Modal>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={[24, 0]}>
                            <Col span={24} md={20} lg={20} className="mb-24 ">
                                <Card bordered={false} className="criclebox ">
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col span={20}>
                                                <Avatar shape="circle" icon={<RiNumber2 />} />
                                                <Title level={4}>Enter Details</Title>
                                            </Col>
                                            <Col span={20}>
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={<>Product Name<span style={{ color: "red" }}>*</span></>}
                                                                className="username"
                                                                name="name"
                                                                onChange={onChangeName}
                                                                value={name}
                                                                rules={[{ required: true, message: 'Please input product name' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={<>Price <span style={{ color: "red" }}>*</span></>}
                                                                className="username"
                                                                name="price"
                                                                onChange={onChangePrice}
                                                                value={price}
                                                                rules={[{ required: true, message: 'Please input price' }]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    label={<>Units Available<span style={{ color: "red" }}>*</span></>}
                                                    className="username"
                                                    name="units_available"
                                                    onChange={onChangeUnits}
                                                    value={units_available}
                                                    rules={[{ required: true, message: 'Please input price' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10} offset={2}>
                                                <Form.Item>
                                                    <div className="header-col sign-in-btn">
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            style={{ width: "80%", marginTop: "25px", background: "#45BC5B", borderColor: "#45BC5B" }}
                                                            shape="round"
                                                        >
                                                            <p style={{ color: "#ffff" }}>ADD</p>
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Space>

            </Row>
        </div>
    )
};

export default AddProduct;
