import {
  Avatar, Button, Card, Col, Descriptions, Divider, Input, List, Modal, Row, Space, Table, Tag, Typography
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  AiOutlineArrowRight, AiOutlineLineChart,
  AiOutlineSearch
} from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { FaBullseye, FaRegLaugh } from "react-icons/fa";
import Moment from "react-moment";
import { Link, NavLink, Redirect } from "react-router-dom";
import axios from "../api/axios";
import TotalsChart from "../components/charts/TotalsChart";
import Footer from "../components/layout/Footer/FooterMobile";
import AuthContext from "../context/AuthProvider";

const GET_PRODUCTS_URL = (business_id) => `business/${business_id}/product/`;
const GET_TRANSACTION_URL = (business_id, transaction_id) => `business/${business_id}/transaction/${transaction_id}/`
const GET_CATEGORIES_URL = (business_id) => `business/${business_id}/category/`;;
const GET_SUMMARY_DATA_URL = (business_id) => `/business/${business_id}/data/summary/`;
const TRANSACTIONS_URL = (business_id) => `/business/${business_id}/transaction/`;

const Sales = () => {
  const { products, setProducts, recent_sales, setRecentSales } =
    useContext(AuthContext);
  const token = window.localStorage.getItem("Bearer");
  const business_id = window.localStorage.getItem("business_id");
  const access_token = window.localStorage.getItem("access_token");
  const auth_header = {
    "Authorization": access_token,
    "Content-Type": "application/json",
    "Accept": "application/json",
  };
  const [isEditingSales, setIsEditingSales] = useState(false);
  const [period] = useState("7days");
  const { Title } = Typography;
  const [categories, setCategories] = useState([]);
  const [summary, setSummary] = useState();
  const [singleSale, setsingleSale] = useState(null);
  const [transDetailsP, setTransDetailsP] = useState([]);
  const [transDetailsS, setTransDetailsS] = useState([]);

  const fetchTransData = async (business_id) => {
    const transaction_data = window.localStorage.getItem("transaction_data") ? JSON.parse(window.localStorage.getItem("transaction_data")) : null;
    if (transaction_data) {
      console.log("Using cached data")
      setRecentSales(transaction_data);
      fetchSummaryData(business_id);
      return transaction_data;
    } else {
      try {
        const response = await axios.get(TRANSACTIONS_URL(business_id), {
          headers: auth_header,
        });
        const filteredData = response?.data;
        setRecentSales(filteredData);
        fetchSummaryData(business_id);
        window.localStorage.setItem("transaction_data", JSON.stringify(filteredData));
      } catch (error) {
        console.log(error);
      }
    }
  }

  const fetchSummaryData = async (business_id) => {

    try {
      const response = await axios.get(GET_SUMMARY_DATA_URL(business_id), {
        headers: auth_header,
      });
      setSummary(response?.data);
    } catch {
      console.log("Error");
    }
  }
  const fetchProductData = async (business_id) => {
    try {
      const response = await axios.get(GET_PRODUCTS_URL(business_id), {
        headers: auth_header,
      });
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchCategoryData = async (business_id) => {
    try {
      const response = await axios.get(GET_CATEGORIES_URL(business_id), {
        headers: auth_header,
      });
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  const fetchTransactionData = async (transaction_id) => {
    try {
      const response = await axios.get(`${GET_TRANSACTION_URL(business_id, transaction_id)}`, {
        headers: auth_header,
        withCredentials: true,
      });
      setTransDetailsP(response?.data.products);
      setTransDetailsS(response?.data.services);
    } catch (error) {
      console.log(error);
    }
  }
  const columns = [
    {
      title: <p className="table-titles">Names</p>,
      dataIndex: "customer",
      render: (customer) => `${customer.first_name} ${customer.last_name}`,
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search by Name"
              value={selectedKeys}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onBlur={() => {
                confirm();
              }}
              onPressEnter={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return (
          record?.customer.first_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          record?.customer.last_name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: <p className="table-titles">Phone Number</p>,
      dataIndex: "customer",
      render: (customer) => `${customer.phone_no}`,
    },
    {
      title: <p className="table-titles">Total Cost</p>,
      dataIndex: "transaction",
      render: (transaction) => (
        <h6 style={{ color: "green" }}>{transaction?.total_cost}</h6>
      ),
    },
    {
      title: <p className="table-titles">Transaction Date</p>,
      dataIndex: "transaction",
      render: (transaction) => {
        var dateLocal = new Date(transaction?.transaction_date);
        var newDate = new Date(
          dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
        );
        return <Moment format="MMMM Do YYYY, h:mm:ss a">{newDate}</Moment>;
      },
    },
    {
      title: <p className="table-titles">Action</p>,
      key: "action",
      render: (record) => (
        <div className="sales-btn">
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              onClickSales(record);
            }}
            style={{
              background: "#dcf0f9",
              borderColor: "#dcf0f9",
            }}
          >
            <p style={{ color: "#1DAFED" }}>View</p>
          </Button>
        </div>
      ),
    },
  ];
  const mobile_columns = [
    {
      title: <p className="table-titles">Names</p>,
      dataIndex: "customer",
      render: (customer) => `${customer.first_name} ${customer.last_name}`,
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search by Name"
              value={selectedKeys}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onBlur={() => {
                confirm();
              }}
              onPressEnter={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return (
          record?.customer.first_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          record?.customer.last_name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: <p className="table-titles">Total Cost</p>,
      dataIndex: "transaction",
      render: (transaction) => (
        <h6 style={{ color: "#45BC5B" }}>{transaction.total_cost}</h6>
      ),
    },
  ];
  const onClickSales = (record) => {
    setIsEditingSales(true);
    fetchTransactionData(record?.transaction?.transaction_id);
    setsingleSale({ ...record });
  };
  const resetEditing = () => {
    setIsEditingSales(false);
    setTransDetailsP([]);
    setTransDetailsS([]);
  };
  useEffect(() => {
    if (recent_sales.length < 1) {
      fetchTransData(business_id).catch(console.error);
    }
  }, [recent_sales]);
  useEffect(() => {
    if (products.length < 1) {
      fetchProductData(business_id).catch(console.error);
    }
  }, [products]);
  useEffect(() => {
    if (categories.length < 1) {
      fetchCategoryData(business_id).catch(console.error);
    }
  }, [categories]);
  useEffect(() => {
    if (!summary) {
      fetchSummaryData(business_id).catch(console.error);
    }
  }, [summary]);

  if (!token) {
    return <Redirect to="/" />;
  }
  if (!business_id) {
    return <Redirect to="/businesses" />;
  }
  return (
    <div className="layout-content">
      <Row gutter={[24, 0]} className="dash-mobile-layout">
        {recent_sales.length === 0 && (
          <Card
            bordered={false}
            className="criclebox "
            style={{ background: "#fce8e8" }}
          >
            <Row align="middle" gutter={[24, 0]} span={24}>
              <Col span={14}>
                <h6>
                  <b>You do not have any Sales</b>
                </h6>
              </Col>
              <Col span={10}>
                <div className="log-btn">
                  <Link to="/add-sale">
                    <Button
                      type="primary"
                      shape="round"
                      style={{
                        background: "#45BC5B",
                        borderColor: "#45BC5B",
                      }}
                    >
                      <p style={{ color: "#fff" }}>Add Sale</p>
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Card>
        )}
        {recent_sales.length > 0 && (
          <>
            <Col
              className=""
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={24}>
                      <h3>
                        <span>KES </span>
                        {summary?.sales}
                        <span>.00</span>
                      </h3>
                    </Col>
                    <Col span={8}>
                      <Title level={3} style={{ color: "#1DAFED" }}>
                        {products.length}
                      </Title>
                      <p className="mobile-card-p">products</p>
                    </Col>
                    <Col span={8}>
                      <Title level={3} style={{ color: "#1DAFED" }}>
                        {categories.length}
                      </Title>
                      <p className="mobile-card-p">categories</p>
                    </Col>
                    <Col span={8}>
                      <div className="log-btn">
                        <NavLink to="/add-sale">
                          <Button
                            type="primary"
                            shape="round"
                            style={{
                              background: "#45BC5B",
                              borderColor: "#45BC5B",
                            }}
                          >
                            <p style={{ color: "#fff" }}>Sell Now</p>
                          </Button>
                        </NavLink>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col span={24}>
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={12}>
                      <h2>Recent Sales</h2>
                    </Col>
                  </Row>
                </div>
                <Card bordered={false} className="criclebox ">
                  <Table
                    dataSource={recent_sales}
                    columns={mobile_columns}
                    pagination={{
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "15"],
                    }}
                  />
                </Card>
                {/* <Card bordered={false}
                                    bodyStyle={{ paddingTop: 0 }}
                                    className="header-solid h-full  ant-list-yes">
                                    {recent_sales.length > 0 &&
                                        <List
                                            className="transactions-list ant-newest"
                                            itemLayout="horizontal"
                                            dataSource={recent_sales}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={item.customer.first_name}
                                                        // description={<span >{item.price} x {item.details.quantity}</span >}
                                                    />
                                                    <div className="amount">
                                                        <span className='receipt-cost'>KES  {item.transaction.total_cost}</span>
                                                    </div>
                                                </List.Item>
                                            )}
                                        />
                                    }
                                </Card> */}
              </Card>
            </Col>
          </>
        )}
        <Footer />
      </Row>
      <Row className="dash-web-layout">
        <Col span={24}>
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={20}>
                <Title level={3}> Sales Overview</Title>
              </Col>
              <Col span={4}>
                <div className="log-btn">
                  <Link to="/add-sale">
                    <Button
                      type="primary"
                      shape="round"
                      style={{
                        marginLeft: "auto",
                        background: "#ffff",
                        borderColor: "#1DAFED",
                      }}
                    >
                      <p style={{ color: "#1DAFED" }}>Add Sales Record</p>
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Divider dashed="true" />
        {recent_sales.length === 0 && (
          <Row gutter={[24, 0]}>
            <Col span={24} md={24} lg={24} className="mb-24 ">
              <Card
                bordered={false}
                className="criclebox "
                style={{ background: "#fef5f5" }}
              >
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={12}>
                      <Descriptions>
                        <Descriptions.Item>
                          You do not have any Sales recorded.<br></br>
                          Get started to experience automatic sychronization for
                          your business
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={12}>
                      <Col
                        span={24}
                        md={16}
                        lg={16}
                        className="mb-24 "
                        style={{
                          marginBottom: "-150px",
                          left: "100px",
                          position: "relative",
                        }}
                      >
                        <Card bordered={false} className="criclebox ">
                          <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                              <Col span={24}>
                                <Title level={5}>Transactions</Title>
                                <br></br>
                              </Col>
                              <Col span={24}>
                                <div className="number">
                                  <Row align="middle" gutter={[24, 0]}>
                                    <Col
                                      span={6}
                                      className="sales-record-amount"
                                    >
                                      <Col span={6} style>
                                        <AiOutlineLineChart size={30} />
                                      </Col>
                                    </Col>
                                    <Col span={18}>
                                      <h6>
                                        Make a sale and have a record of all
                                        your transactions
                                      </h6>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <div>
                                <br></br>
                              </div>
                              <Col xs={24} md={24} className="d-flex">
                                <div className="log-btn">
                                  <Link to="/add-sale">
                                    <Button
                                      type="primary"
                                      shape="round"
                                      style={{
                                        background: "#1DAFED",
                                        borderColor: "#1DAFED",
                                      }}
                                    >
                                      <p style={{ color: "#fff" }}>Sales Now</p>
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        )}
        {recent_sales.length > 0 && (
          <>
            <Col span={17} className="mb-24 ">
              <Card bordered={false} className="criclebox ">
                <TotalsChart />
              </Card>
            </Col>
            <Col span={6} offset={1} className="mb-24 ">
              <Space direction="vertical" size={35}>
                <Card bordered={false} className="criclebox ">
                  <Space direction="vertical" size={20}>
                    <Title level={3}>
                      Insights <BsStars style={{ color: "#ffaa00" }} />
                    </Title>
                    <Row>
                      <Space direction="vertical" size={40}>
                        <Row>
                          <Col span={4}>
                            <FaRegLaugh
                              style={{
                                fontSize: 25,
                                color: "#faac17",
                                marginTop: "5px",
                              }}
                            />
                          </Col>
                          <Col span={20}>
                            <h6>
                              80% of your sales are from 20% of your customers.
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4}>
                            <FaRegLaugh
                              style={{
                                fontSize: 25,
                                color: "#faac17",
                                marginTop: "2px",
                              }}
                            />
                          </Col>
                          <Col span={20}>
                            <h6>60% of customers are male</h6>
                          </Col>
                        </Row>
                      </Space>
                    </Row>
                    <Col xs={24} md={24} className="d-flex">
                      <div className="log-btn">
                        <Link to="/insights">
                          <Button
                            type="primary"
                            shape="round"
                            style={{
                              background: "#dcf0f9",
                              borderColor: "#dcf0f9",
                            }}
                          >
                            <p style={{ color: "#1DAFED" }}>
                              ALL{" "}
                              <AiOutlineArrowRight
                                style={{ marginTop: "5px" }}
                              />
                            </p>
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Space>
                </Card>
                <Card>
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={18}>
                      <p className="span-large" style={{ marginBottom: "2px" }}>
                        <b> Discounts Requested</b>
                      </p>
                    </Col>
                    <Col span={6}>
                      <div className="sales-btn">
                        <Tag color="#ffbb36" shape="circle">
                          <span className="discount-p">14</span>
                        </Tag>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card>
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={18}>
                      <p className="span-large" style={{ marginBottom: "2px" }}>
                        <b>Refunds Requested</b>
                      </p>
                    </Col>
                    <Col span={6}>
                      <div className="sales-btn">
                        <Tag color="#f43939" shape="circle">
                          <span className="discount-p">24</span>
                        </Tag>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Space>
            </Col>
            <Col span={24}>
              <Card bordered={false} className="criclebox ">
                <Table dataSource={recent_sales} columns={columns} />
              </Card>
            </Col>
          </>
        )}
      </Row>
      <Modal
        visible={isEditingSales}
        onCancel={() => {
          resetEditing();
        }}
        onOk={() => {
          resetEditing();
        }}
        width={600}
      >
        <Space direction="vertical">
          <Title level={3}>Transaction Details</Title>
          <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Name">
              {singleSale?.customer.first_name} {singleSale?.customer.last_name}
            </Descriptions.Item>
            <Descriptions.Item label="Total Cost">
              <h3 style={{ color: "#d63aab" }}>
                <b>{singleSale?.transaction.total_cost}</b>
              </h3>
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {singleSale?.customer.phone_no}{" "}
            </Descriptions.Item>
            <Descriptions.Item label="Gender">
              {singleSale?.customer.gender}{" "}
            </Descriptions.Item>
          </Descriptions>
          {transDetailsP.length > 0 && (
            <>
              <Title level={5} style={{ color: "#d63aab" }}>
                Products
              </Title>
              <List
                className="transactions-list ant-newest"
                itemLayout="horizontal"
                dataSource={transDetailsP}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size="small"
                          style={{
                            color: "#ffaa00",
                            backgroundColor: "#1e2a6c",
                            verticalAlign: "middle",
                          }}
                        >
                          <FaBullseye />
                        </Avatar>
                      }
                      title={item.product.name}
                      description={
                        <span>
                          {item.product.price} x {item.details.quantity}
                        </span>
                      }
                    />
                    <div className="amount">
                      <span className="receipt-cost">
                        KES {item.details.cost}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            </>
          )}
          {transDetailsS.length > 0 && (
            <>
              <Title level={5} style={{ color: "#d63aab" }}>
                Services
              </Title>
              <List
                className="transactions-list ant-newest"
                itemLayout="horizontal"
                dataSource={transDetailsS}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size="small"
                          style={{
                            color: "#ffaa00",
                            backgroundColor: "#1e2a6c",
                            verticalAlign: "middle",
                          }}
                        >
                          <FaBullseye />
                        </Avatar>
                      }
                      title={item.service.name}
                      description={
                        <span>
                          {item.service.price} x {item.details.quantity}
                        </span>
                      }
                    />
                    <div className="amount">
                      <span className="receipt-cost">
                        KES {item.details.cost}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            </>
          )}
        </Space>
      </Modal>
    </div>
  );
};

export default Sales;
