import {
    Avatar,
    Card, Col, Divider, Row, Space, Spin, Typography
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { RiNumber1, RiNumber2 } from "react-icons/ri";
import { Redirect } from 'react-router-dom';
import axios from '../api/axios';
import Cart from '../components/Cart';
import CashAmountInput from '../components/CashAmountInput';
import PaymentOption from '../components/PaymentOption';
import PaymentRow from '../components/PaymentRow';
import AuthContext from "../context/AuthProvider";
const ADD_PAYMENT_URL = (business_id, transaction_id) => `/business/${business_id}/transaction/${transaction_id}/payment/`;
const ONE_TRANSACTION_URL = (business_id) => `/business/${business_id}/transaction/`;

const Payment = () => {
    const { Title } = Typography;
    const errRef = useRef(null);
    const [errMsg, setErrMsg] = useState('')
    const { setTransaction } = useContext(AuthContext);
    const [amount_paid, setAmountPaid] = useState(0)
    const [payment_type] = useState("Cash")
    const { setCompleteTransaction } = useContext(AuthContext);
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    const transaction_id = window.localStorage.getItem("transaction_id")
    const [success, setSuccess] = useState(false);
    const [transDetails, setTransDetails] = useState({})
    const [iscash, setIsCash] = useState(true)
    const [isSpin, setisSpin] = useState(false)
    const [disabled] = useState(false)
    useEffect(() => {
        const fetchBusinessData = async () => {
            try {
                const response = await axios.get(`${ONE_TRANSACTION_URL(business_id)}${transaction_id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                })
                setTransaction(response?.data)
                setTransDetails(response?.data.transaction)
            } catch (error) {
                console.log(error)
            }
        }
        fetchBusinessData()
    }, [setTransaction]);

    const onChangeAmount = (e) => {
        const amount_paid = e.target.value;
        setAmountPaid(amount_paid);
    };

    const completeMpesa = () => {
        setSuccess(true)
        setisSpin(false)
    }

    const handleSubmit = async () => {
        const amount_paid = window.localStorage.getItem("amount_paid");
        setAmountPaid(amount_paid);
        if (iscash) {
            if (amount_paid > 0) {
                try {
                    const response = await axios.post(ADD_PAYMENT_URL(business_id, transaction_id),
                        JSON.stringify({ amount_paid, payment_type }),
                        {
                            headers: {
                                "Authorization": `Bearer ${token}`,
                                "content-type": "application/json"
                            },
                            withCredentials: true,
                        }
                    )
                    setCompleteTransaction(response.data)
                    setSuccess(true)
                } catch (err) {
                    if (!err?.response) {
                        setErrMsg('No Server Response')
                    } else {
                        setErrMsg('Something went wrong, Please try again');
                    }
                    errRef.current.focus();
                }
            } else {
                setErrMsg('Please input Amount Received')
            }
        }
        if (!iscash) {
            try {
                setisSpin(true)
                const response = await axios.post(ADD_PAYMENT_URL(business_id),
                    JSON.stringify({ amount_paid, transaction_id, payment_type }),
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            "content-type": "application/json"
                        },
                        withCredentials: true,
                        // params: {
                        //     "business_id": business_id,
                        //     "mpesa": true
                        // }
                    }
                )
                setCompleteTransaction(response.data)
                completeMpesa()
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response')
                } else {
                    setErrMsg('Something went wrong, Please try again');
                }
                errRef.current.focus();
            }
        }
    }

    if (success) {
        return <Redirect to="/completed" />
    }
    if (!token) {
        return <Redirect to="/" />
    }
    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                <Col span={24}>
                    <Cart />
                    {/* <PaymentRow transDetails={transDetails} /> */}
                    <>
                        <br></br>
                        <br></br>
                    </>
                    {iscash ?
                        <CashAmountInput errMsg={errMsg} onChangeAmount={onChangeAmount} amount_paid={amount_paid} errRef={errRef} />
                        : <>{isSpin && <Spin size="large" className='spin_C' />}</>}
                    <>
                        <br></br>
                    </>
                    <Row className="mobile-footer mobileViewc">
                        <PaymentOption isCash={iscash} handleSubmit={handleSubmit} setIsCash={setIsCash} disabled={disabled} />
                    </Row>
                </Col>
            </Row>
            <Row className="dash-web-layout">
                <Space direction="vertical" size='large'>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={24}>
                                <Title level={3}>Cash Payment</Title>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={20} lg={20} className="mb-24 ">
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={24}>
                                            <Avatar shape="circle" icon={<RiNumber1 />} />
                                            <Title level={4}>Transaction Details</Title>
                                        </Col>
                                        <Col span={24}><Cart /></Col>
                                        <Col span={24}>
                                            <PaymentRow transDetails={transDetails} />
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={20} lg={20} className="mb-24 ">
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={20}>
                                            <Avatar shape="circle" icon={<RiNumber2 />} />
                                            {iscash ? <Title level={4}>Cash Payment</Title> : <Title level={4}>Mpesa Payment</Title>}
                                        </Col>
                                        <Col span={20}>
                                            {iscash ?
                                                <CashAmountInput errMsg={errMsg} onChangeAmount={onChangeAmount} amount_paid={amount_paid} errRef={errRef} /> :
                                                <><Divider />
                                                    {isSpin && <Spin size="large" className='spin_C' />}</>
                                            }
                                        </Col>
                                        <Col span={20}>
                                            <Row>
                                                <PaymentOption isCash={iscash} handleSubmit={handleSubmit} setIsCash={setIsCash} disabled={disabled} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Row>
        </div>
    )
}

export default Payment
