import {
    Button, Col, Form,
    Input, Layout, Row, Typography
} from "antd";
import qs from "qs";
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import headerlogo from "../assets/images/reja-logo.jpg";
import AuthContext from "../context/AuthProvider";

import axios from '../api/axios';

const LOGIN_URL = 'auth/';

const { Title } = Typography;
const { Header, Content } = Layout;
const SignIn = () => {
    const { auth, setAuth } = useContext(AuthContext);
    const { setBusiness } = useContext(AuthContext);
    const userRef = useRef(null);
    const errRef = useRef(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(LOGIN_URL,
                qs.stringify({ username, password }),
                {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    },
                    withCredentials: true
                }
            );
            const access_token = response?.data?.access_token;
            const businesses = response?.data?.businesses;

            setAuth({ username, businesses });
            window.localStorage.setItem("Bearer", access_token);
            window.localStorage.setItem("access_token", `Bearer ${access_token}`);
            setPassword('')
            setUsername('')
            setSuccess(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Incorrect username or password');
            } else {
                setErrMsg('Login Failed');
            }
        }
    }

    if (success) {
        if (auth.businesses.length === 0) {
            return <Redirect to="/dashboard" />
        } else if (auth.businesses.length === 1) {
            const key = auth.businesses[0].business_id;
            setBusiness(auth.businesses[0]);
            window.localStorage.setItem("business_id", key);
            return <Redirect to="/dashboard" />
        } else if (auth.businesses.length > 1) {
            return <Redirect to="/businesses" />
        }
    }

    return (
        <>
            <Layout className="layout-default layout-signin" style={{ background: "#ffff" }}>
                <Header>
                    <div className="header-col header-brand">
                        <img src={headerlogo} alt="" />
                    </div>
                    <div className="header-col header-nav">
                    </div>
                    <div className="header-col header-btn">
                        <Link to="/sign-up" className="text-dark font-bold">
                            <Button >SIGN UP</Button>
                        </Link>
                    </div>
                </Header>
                <Content className="signin">
                    <Row gutter={[24, 0]} justify="space-around">
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 6, offset: 2 }}
                            md={{ span: 12 }}
                        >
                            <Title className="mb-15">Sign In</Title>
                            <Title className="font-regular text-muted" level={5}>
                                Enter your email and password to sign in
                            </Title>
                            <Form
                                onFinish={handleSubmit}
                                layout="vertical"
                                className="row-col"
                            >
                                <p ref={errRef} className='Error-p'>
                                    {errMsg}
                                </p>
                                <Form.Item
                                    className="username"
                                    label={<>Email<span style={{ color: "red" }}>*</span></>}
                                    name="username"
                                    id="username"
                                    ref={userRef}
                                    onChange={onChangeUsername}
                                    value={username}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please input your email!",
                                    //     },{type:"email"}
                                    // ]}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your email!",
                                        },
                                        {
                                            type: "email",
                                            message: "Please enter a valid email address!",
                                            validateTrigger: "onSubmit" // add this line
                                        }
                                    ]}
                                >
                                    <Input ref={userRef} placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    className="username"
                                    label={<>Password<span style={{ color: "red" }}>*</span></>}
                                    name="password"
                                    id="password"
                                    onChange={onChangePassword}
                                    value={password}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your password!",
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Password" />
                                </Form.Item>
                                <Form.Item>
                                    <div className="header-col sign-in-btn">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ width: "100%", background: "#1e2a6c" }}
                                            shape="round"
                                        >
                                            SIGN IN
                                        </Button>
                                    </div>
                                </Form.Item>
                                <p className="font-semibold text-muted">
                                    Don't have an account?{" "}
                                    <Link to="/sign-up" className="text-dark font-bold">
                                        Sign Up
                                    </Link>
                                </p>
                            </Form>
                        </Col>
                        <Col
                            className="sign-img"
                            style={{
                                padding: 12,
                                alignContent: 'center'
                            }}
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                        >
                            <img src={headerlogo} alt="" />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    )
}

export default SignIn
