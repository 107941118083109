import React, { useState, useContext } from 'react';
import AuthContext from "../context/AuthProvider";
import {
    Row,
    Col,
    Card,
    Button,
    Form
} from "antd";
import FormItem from 'antd/lib/form/FormItem';

const SingleDeliveryProduct = ({ c, setCartDetail, cartdetail , setSearchTerm}) => {
    const delivery_id = window.localStorage.getItem("delivery_id")
    const [unit_count] = useState("1")
    const [cost_per_unit] = useState("0")
    const [countErr] = useState("")
    const { delivery_cart, setDeliveryCart } = useContext(AuthContext);

    const handleSubmit = () => {
        setDeliveryCart([...delivery_cart, { "product_id": c.product_id, "delivery_id": delivery_id, "unit_count": unit_count, "cost_per_unit": cost_per_unit }])
        setCartDetail([...cartdetail, { "product_id": c.product_id, "name": c.name, "delivery_id": delivery_id, "unit_count": unit_count, "cost_per_unit": cost_per_unit }])
        setSearchTerm("")
    }

    return (
        <Card bordered={true} className="criclebox ">
            <div className="number">
                <Row align="middle" gutter={[24, 0]} key={c.id}  style={{marginTop:"-15px", marginBottom:"-25px" }}>
                    <p>{countErr}</p>
                    <Col span={12} className="sales-record-amount">
                        <p style={{ marginBottom: "2px" }}>
                            {c.name}
                        </p>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <Form onFinish={handleSubmit}>
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]} >
                                    <Col span={12}>
                                        <FormItem>
                                            <div className="sales-btn">
                                                <Button
                                                    type="primary"
                                                    shape="round"
                                                    htmlType="submit"
                                                    style={{
                                                        background: "#f4fbfe",
                                                        borderColor: "#f4fbfe"
                                                    }}
                                                ><p style={{
                                                    color: '#1DAFED',
                                                }}>Add</p>
                                                </Button>
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Card>
    )
};

export default SingleDeliveryProduct;
