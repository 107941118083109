
import {
    Row, Col,Space, Typography
} from 'antd'
import { Redirect } from 'react-router-dom'
import ExpenseForm from './forms/ExpenseForm'
const { Title } = Typography
const AddExpense = () => {
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    if (!token) {
        return <Redirect to="/" />
    }
    if (!business_id) {
        return <Redirect to="/businesses" />
    }
    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout"></Row>
            <Row className="dash-web-layout">
                <Space direction="vertical" size='large'>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={24}>
                                <Title level={3}>Add Expense</Title>
                            </Col>
                        </Row>
                    </div>
                </Space>
            </Row>
            <ExpenseForm/>

        </div>
    )
}

export default AddExpense