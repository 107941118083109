import {
    Row,
    Col,
    Card,
    Typography,
    Divider
} from "antd";
import { Redirect } from "react-router-dom"
const { Title } = Typography;

const MoneyAccounts = () => {
    const token = window.localStorage.getItem("Bearer")
    const business_id = window.localStorage.getItem("business_id")

    if (!token) {
        return <Redirect to="/" />
    }
    if (!business_id) {
        return <Redirect to="/businesses" />
    }
    return (
        <Row className="dash-web-layout">
            <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                    <Col span={24}>
                        <Title level={3}>Money Accounts Overview</Title>
                    </Col>
                    {/* <Col span={4}>
                            <div className="log-btn">
                                <Link to="/add-account">
                                    <Button
                                        disabled
                                        type="primary"
                                        shape="round"
                                        style={{
                                            marginLeft: "auto",
                                            background: "#ffff",
                                            borderColor: "#1DAFED"
                                        }}
                                    ><p style={{ color: '#1DAFED' }}>Add Money Account</p>
                                    </Button>
                                </Link>
                            </div>
                        </Col> */}
                </Row>
            </div>
            <Divider dashed/>
            <Col span={24} md={24} lg={24} className="mb-24 ">
                <Card bordered={false} className="criclebox " style={{ background: "#f4fbfe",height:"300px"}}>
                           <Row>
                               <Col span={8} offset={10} style={{paddingTop:"70px"}}>
                               <Title>Coming Soon..</Title> 
                               </Col>
                           </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default MoneyAccounts