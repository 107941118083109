import {
    Button,
    Row,
    Col,
    Card,
    Typography,
    Space,
    Avatar,
    Form,
    Input,
    Select
} from "antd";
import { AiOutlineBank } from "react-icons/ai"
import { RiNumber2, RiNumber1 } from "react-icons/ri";
const { Title } = Typography;
const { Option } = Select;

const AddInventory = () => {
    return (
        <Row className="dash-web-layout">
            <Space direction="vertical" size='large'>
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col span={24}>
                            <Title level={3}>Add Invetory</Title>
                        </Col>
                    </Row>
                </div>

                <Row gutter={[24, 0]}>
                    <Col span={24} md={20} lg={20} className="mb-24 ">
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={20}>
                                        <Avatar shape="circle" icon={<RiNumber1 />} />
                                        <Title level={4}>Select Type</Title>
                                    </Col>
                                    <Col span={20}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col span={10}>
                                                    <Select
                                                        showSearch
                                                        style={{ width: 300 }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value="1">Supplier 1</Option>
                                                        <Option value="2">Supplier 2</Option>
                                                        <Option value="3">Supplier3</Option>
                                                    </Select>,
                                                </Col>
                                                <Col span={10}>
                                                    <p>Products are goods ready to sell, while
                                                        supplies are your raw materials </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col span={24} md={20} lg={20} className="mb-24 ">
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={20}>
                                        <Avatar shape="circle" icon={<RiNumber2 />} />
                                        <Title level={4}>Enter Details</Title>
                                    </Col>
                                    <Col span={20}>
                                        <Form>
                                            <div className="number">
                                                <Row align="middle" gutter={[24, 0]}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            className="username"
                                                            name="url"
                                                            id="url"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Account Number" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            className="username"
                                                            name="sector"
                                                            id="sector"
                                                            rules={[
                                                                {
                                                                    required: false
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Account Name" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            className="username"
                                            name="url"
                                            id="url"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Account Branch" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Col span={24} md={18} lg={16} className="mb-24 " style={{
                                            marginBottom: "-150px",
                                            left: "100px",
                                            position: "relative"
                                        }}>
                                            <Card bordered={false} className="criclebox ">
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={24}>
                                                            <Title level={5}>
                                                                Add Inventory
                                                            </Title><br></br>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div className="number">
                                                                <Row align="middle" gutter={[24, 0]}>
                                                                    <Col span={6} className="sales-record-amount">
                                                                        <Col span={6} style>
                                                                            <AiOutlineBank size={30} />
                                                                        </Col>
                                                                    </Col>
                                                                    <Col span={18}>
                                                                        <h6>Published Products become immediately available for sale.</h6>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <div>
                                                            <br></br>
                                                        </div>
                                                        <Col xs={24} md={24} className="d-flex">
                                                            <div className="log-btn">
                                                                {/* <Link to="/add-invetory"> */}
                                                                    <Button
                                                                        type="primary"
                                                                        shape="round"
                                                                        style={{
                                                                            background: "#1DAFED",
                                                                            borderColor: "#1DAFED"
                                                                        }}
                                                                    ><p style={{ color: '#fff' }}> Add Inventory</p>
                                                                    </Button>
                                                                {/* </Link> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        </Col>

                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Space>

        </Row>

    )
}

export default AddInventory