import { useEffect, useState, useRef } from 'react'
import { Link, Redirect } from "react-router-dom";
import {
    Layout,
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input
} from "antd";
import headerlogo from "../assets/images/reja-logo.jpg";

import axios from '../api/axios';
const SIGNUP_URL = 'user/';
const { Title } = Typography;
const { Header, Content } = Layout;
const SignUp = () => {
    const userRef = useRef(null);
    const errRef = useRef(null);

    const [email, setEmail] = useState('');
    const [first_name, setFirstname] = useState('');
    const [last_name, setLastname] = useState('');
    const [phone_no, setPhoneNo] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangeFirstname = (e) => {
        const first_name = e.target.value;
        setFirstname(first_name);
    };

    const onChangeLastname = (e) => {
        const last_name = e.target.value;
        setLastname(last_name);
    };

    const onChangePhoneNo = (e) => {
        const phone_no = e.target.value;
        setPhoneNo(phone_no);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangePasswordConfirm = (e) => {
        const password_confirm = e.target.value;
        setPasswordConfirm(password_confirm);
    };

    const handleSubmit = async () => {
        console.warn(email, first_name, last_name, phone_no, password, password_confirm)

        if (password !== password_confirm) {
            setErrMsg("Passwords don't match");
        } else if (password.length < 8 || password_confirm.length < 8) {
            setErrMsg("Password must be longer than 7 characters");
        } else {
            try {
                await axios.post(SIGNUP_URL,
                    JSON.stringify({ email, first_name, last_name, phone_no, password }),
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        withCredentials: true
                    }
                )
                setEmail('')
                setFirstname('')
                setLastname('')
                setPassword('')
                setPhoneNo('')
                setSuccess(true)
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else {
                    setErrMsg('Sign Up Failed');
                }
                errRef.current.focus();
            }
        }


    }

    if (success) {
        return <Redirect to="/" />
    }

    return (
        <>
            <Layout className="layout-default layout-signin"  style={{background:"#ffff"}}>
                <Header>
                    <div className="header-col header-brand">
                        <img src={headerlogo} alt="" />
                    </div>
                    <div className="header-col header-nav">
                    </div>
                    <div className="header-col header-btn">
                        <Link to="/" className="text-dark font-bold">
                            <Button >SIGN IN</Button>
                        </Link>
                    </div>
                </Header>
                <Content className="signin">
                    <Row gutter={[24, 0]} justify="space-around">
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 6, offset: 2 }}
                            md={{ span: 12 }}
                        >
                            <Title className="mb-15">Sign Up</Title>
                            <Title className="font-regular text-muted" level={5}>
                                Fill the form below to Sign Up
                            </Title>
                            <Form
                                onFinish={handleSubmit}
                                layout="vertical"
                                className="row-col"
                            >
                                <p ref={errRef} className='Error-p'>
                                    {errMsg}
                                </p>
                                <Form.Item
                                    className="username"
                                    label={<>Email<span style={{ color: "red" }}>*</span></>}
                                    name="username"
                                    id="username"
                                    ref={userRef}
                                    onChange={onChangeEmail}
                                    value={email}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your email!",
                                        },{type:"email"}
                                    ]}
                                   
                                >
                                    <Input ref={userRef} placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label="First Name"
                                    name="first_name"
                                    id="first_name"
                                    ref={userRef}
                                    onChange={onChangeFirstname}
                                    value={first_name}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please input your first name",
                                        },
                                    ]}
                                >
                                    <Input ref={userRef} placeholder="First Name" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label="Last Name"
                                    name="last_name"
                                    id="last_name"
                                    ref={userRef}
                                    onChange={onChangeLastname}
                                    value={last_name}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please input your last name",
                                        },
                                    ]}
                                >
                                    <Input ref={userRef} placeholder="Last Name" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label={<>Phone Number<span style={{ color: "red" }}>*</span></>}
                                    name="phone_no"
                                    id="phone_no"
                                    ref={userRef}
                                    onChange={onChangePhoneNo}
                                    value={phone_no}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your phone number",
                                        },
                                    ]}
                                >
                                    <Input ref={userRef} placeholder="Phone Number" />
                                </Form.Item>

                                <Form.Item
                                    className="username"
                                    label={<>Password<span style={{ color: "red" }}>*</span></>}
                                    name="password"
                                    id="password"
                                    onChange={onChangePassword}
                                    value={password}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your password!",
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Password" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label={<>Confirm Password<span style={{ color: "red" }}>*</span></>}
                                    name="password_confirm"
                                    id="password_confirm"
                                    onChange={onChangePasswordConfirm}
                                    value={password_confirm}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please confirm your password!",
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Confirm Password" />
                                </Form.Item>
                                <Form.Item>
                                    <div className="header-col sign-in-btn">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ width: "100%", background: "#1e2a6c" }}
                                            shape="round"
                                        >
                                            SIGN UP
                                        </Button>
                                    </div>
                                </Form.Item>
                                <p className="font-semibold text-muted">
                                    Already have an account?{" "}
                                    <Link to="/" className="text-dark font-bold">
                                        Sign In
                                    </Link>
                                </p>
                            </Form>
                        </Col>
                        <Col
                            className="sign-img"
                            style={{
                                padding: 12,
                                alignContent: 'center'
                            }}
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                        >
                            <img src={headerlogo} alt="" />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    )
}

export default SignUp