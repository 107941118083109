import {
    Avatar, Button,
    Card, Col, Input, message, Modal, Row, Space, Typography
} from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { useContext, useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import { RiNumber1 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import SingleDeliveryProduct from "./SingleDeliveryProduct";
const ADD_DELIVERy_URL = 'productdelivery/'
const GET_PRODUCTS_URL = `business/${window.localStorage.getItem("business_id")}/product/`

const AddProductDelivery = () => {
    const { Title } = Typography;
    const { products, setProducts } = useContext(AuthContext)
    const [IsEditing, setIsEditing] = useState(false)
    const [editRecord, setEditRecord] = useState(null)
    const [editRecordDetails, setEditRecordDetails] = useState(null)
    const token = window.localStorage.getItem("Bearer")
    const business_id = window.localStorage.getItem("business_id")
    const delivery_id = window.localStorage.getItem("delivery_id")
    const { delivery_cart, setDeliveryCart } = useContext(AuthContext)
    const [searchTerm, setSearchTerm] = useState("")
    const [cartdetail, setCartDetail] = useState([])
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const token = window.localStorage.getItem("Bearer");
        const business_id = window.localStorage.getItem("business_id")
        const fetchProductData = async () => {
            try {
                const response = await axios.get(GET_PRODUCTS_URL, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // }
                })
                setProducts(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchProductData()
    }, [setProducts]);

    const handleDelivery = async () => {
        if (delivery_cart.length > 0) {
            try {
                await axios.post(ADD_DELIVERy_URL,
                    JSON.stringify(delivery_cart),
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            "content-type": "application/json"
                        },
                        withCredentials: true,
                        // params: {
                        //     "business_id": business_id
                        // }
                    }
                )
                window.localStorage.setItem("delivery_id", null)
                setDeliveryCart([])
                message.success('You have successfully made a Delivery', 5)
                setSuccess(true)
            } catch (err) {
                console.log(err)
            }
        } else {
            message.error("Please add a product delivery to proceed!")
        }
    }

    const onDeleteProduct = (item) => {
        Modal.confirm({
            title: "Are you sure you want to Delete?",
            okText: "Yes",
            okType: "danger",
            onOk: () => {
                setDeliveryCart(delivery_cart.filter((c) => c.product_id !== item.product_id))
                setCartDetail(cartdetail.filter((c) => c.product_id !== item.product_id))
            }
        })
    }
    const resetEditing = () => {
        setEditRecord(null)
        setEditRecordDetails(null)
        setIsEditing(false)
    }

    const onEditProduct = (item) => {
        setIsEditing(true)
        setEditRecord({ "product_id": item.product_id, "delivery_id": delivery_id })
        setEditRecordDetails({ "product_id": item.product_id, "quantity": item.count, "name": item.name, "price": item.price })
    }
    if (success) {
        return <Redirect to="/suppliers" />
    }
    if (!token) {
        return <Redirect to="/" />
    }
    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                <Col span={24}>
                    <Input
                        className="header-search"
                        placeholder="Find and add products..."
                        prefix={<AiOutlineSearch />}
                        onChange={(event) => {
                            setSearchTerm(event.target.value)
                        }}
                    />
                    {products.filter((c) => {
                        if (searchTerm === "") {
                            return null
                        } else if (c.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return c
                        } else {
                            return null
                        }
                    }).map((c) => (
                        <SingleDeliveryProduct c={c} key={c.id} cartdetail={cartdetail} setCartDetail={setCartDetail} setSearchTerm={setSearchTerm} />
                    ))}
                    <div>
                        <br></br>
                        <br></br>
                    </div>
                    {cartdetail.length > 0 &&
                        <Card className='product-list-card'>
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={8}>
                                        <p>
                                            <b>Product Name</b>
                                        </p>
                                    </Col>
                                    <Col span={5}>
                                        <p>
                                            <b>Count</b>
                                        </p>
                                    </Col>
                                    <Col span={5}>
                                        <p>
                                            <b>Cost per unit</b>
                                        </p>
                                    </Col>
                                </Row>
                                {cartdetail.map((item) =>
                                    <Row align="middle" gutter={[24, 0]} key={item.product_id}>
                                        <Col span={8}>
                                            <Title level={5}>
                                                {item.name}
                                            </Title><br></br>
                                        </Col>
                                        <Col span={5}>
                                            <p>
                                                {item.unit_count}
                                            </p>
                                        </Col>
                                        <Col span={5}>
                                            <p>
                                                {item.cost_per_unit}
                                            </p>
                                        </Col>
                                        <Col span={3}>
                                            <AiOutlineEdit
                                                onClick={() => {
                                                    onEditProduct(item)
                                                }}
                                                style={{ color: "#45BC5B" }}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <AiOutlineDelete
                                                onClick={() => {
                                                    onDeleteProduct(item)
                                                }}
                                                style={{ color: "red" }}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Modal
                                    okText="Save"
                                    visible={IsEditing}
                                    onCancel={() => {
                                        resetEditing()
                                    }}
                                    onOk={() => {
                                        setDeliveryCart((pre) => {
                                            return pre.map((record) => {
                                                if (record.product_id === editRecord.product_id) {
                                                    return editRecord
                                                } else {
                                                    return record
                                                }
                                            })
                                        })
                                        setCartDetail((pre) => {
                                            return pre.map((record) => {
                                                if (record.product_id === editRecordDetails.product_id) {
                                                    return editRecordDetails
                                                } else {
                                                    return record
                                                }
                                            })
                                        })
                                        resetEditing()
                                    }}
                                >
                                    <Form><div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col span={10}>
                                                <FormItem className="username" label="Unit Amount" >
                                                    <Input value={editRecord?.unit_count} placeholder="Add Unit Amount"
                                                        onChange={(e) => {
                                                            setEditRecord(pre => {
                                                                return { ...pre, unit_count: Number(e.target.value) }
                                                            })
                                                            setEditRecordDetails(pre => {
                                                                return { ...pre, unit_count: Number(e.target.value) }
                                                            })
                                                        }}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={10}>
                                                <FormItem className="username" label="Cost Per unit" >
                                                    <Input value={editRecord?.cost_per_unit} placeholder="Add Cost Per unit"
                                                        onChange={(e) => {
                                                            setEditRecord(pre => {
                                                                return { ...pre, cost_per_unit: Number(e.target.value) }
                                                            })
                                                            setEditRecordDetails(pre => {
                                                                return { ...pre, cost_per_unit: Number(e.target.value) }
                                                            })
                                                        }}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </div>
                                    </Form>
                                </Modal>
                            </div>
                        </Card>
                    }
                    <div>
                        <br></br>
                        <br></br>
                    </div>
                    <div className="header-col payment-button">
                        <Button
                            onClick={() =>
                                handleDelivery()
                            }
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%", background: "#1e2a6c" }}
                            shape="round"
                        >
                            Make Delivery
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="dash-web-layout">
                <Space direction="vertical" size='large'>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={24}>
                                <Title level={3}>Add Delivery</Title>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={2} lg={2} className="mb-24 "></Col>
                        <Col span={24} md={20} lg={20} className="mb-24 ">
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={20}>
                                            <Avatar shape="circle" icon={<RiNumber1 />} />
                                            <Title level={4}>Select and Add Product</Title>
                                        </Col>
                                        <Col span={20}>
                                            <Input
                                                className="header-search"
                                                placeholder="Find and add products..."
                                                prefix={<AiOutlineSearch />}
                                                onChange={(event) => {
                                                    setSearchTerm(event.target.value)
                                                }}
                                            />
                                            {products.filter((c) => {
                                                if (searchTerm === "") {
                                                    return null
                                                } else if (c.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                    return c
                                                } else {
                                                    return null
                                                }
                                            }).map((c) => (
                                                <SingleDeliveryProduct c={c} key={c.id} cartdetail={cartdetail} setCartDetail={setCartDetail} setSearchTerm={setSearchTerm} />
                                            ))}

                                        </Col>
                                        <Col span={20}>
                                            <div>
                                                <br></br>
                                                <br></br>
                                            </div>
                                            {cartdetail.length > 0 &&
                                                <Card className='product-list-card'>
                                                    <div className="number">
                                                        <Row align="middle" gutter={[24, 0]}>
                                                            <Col span={8}>
                                                                <p>
                                                                    <b>Product Name</b>
                                                                </p>
                                                            </Col>
                                                            <Col span={5}>
                                                                <p>
                                                                    <b>Count</b>
                                                                </p>
                                                            </Col>
                                                            <Col span={5}>
                                                                <p>
                                                                    <b>Cost per unit</b>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                        {cartdetail.map((item) =>
                                                            <Row align="middle" gutter={[24, 0]} key={item.product_id}>
                                                                <Col span={8}>
                                                                    <Title level={5}>
                                                                        {item.name}
                                                                    </Title><br></br>
                                                                </Col>
                                                                <Col span={5}>
                                                                    <p>
                                                                        {item.unit_count}
                                                                    </p>
                                                                </Col>
                                                                <Col span={5}>
                                                                    <p>
                                                                        {item.cost_per_unit}
                                                                    </p>
                                                                </Col>
                                                                <Col span={3}>
                                                                    <AiOutlineEdit
                                                                        onClick={() => {
                                                                            onEditProduct(item)
                                                                        }}
                                                                        style={{ color: "#45BC5B" }}
                                                                    />
                                                                </Col>
                                                                <Col span={3}>
                                                                    <AiOutlineDelete
                                                                        onClick={() => {
                                                                            onDeleteProduct(item)
                                                                        }}
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        <Modal
                                                            okText="Save"
                                                            visible={IsEditing}
                                                            onCancel={() => {
                                                                resetEditing()
                                                            }}
                                                            onOk={() => {
                                                                setDeliveryCart((pre) => {
                                                                    return pre.map((record) => {
                                                                        if (record.product_id === editRecord.product_id) {
                                                                            return editRecord
                                                                        } else {
                                                                            return record
                                                                        }
                                                                    })
                                                                })
                                                                setCartDetail((pre) => {
                                                                    return pre.map((record) => {
                                                                        if (record.product_id === editRecordDetails.product_id) {
                                                                            return editRecordDetails
                                                                        } else {
                                                                            return record
                                                                        }
                                                                    })
                                                                })
                                                                resetEditing()
                                                            }}
                                                        >
                                                            <Form><div className="number">
                                                                <Row align="middle" gutter={[24, 0]}>
                                                                    <Col span={10}>
                                                                        <FormItem className="username" label="Unit Amount">
                                                                            <Input value={editRecord?.unit_count}
                                                                                onChange={(e) => {
                                                                                    setEditRecord(pre => {
                                                                                        return { ...pre, unit_count: Number(e.target.value) }
                                                                                    })
                                                                                    setEditRecordDetails(pre => {
                                                                                        return { ...pre, unit_count: Number(e.target.value) }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </FormItem>
                                                                    </Col>
                                                                    <Col span={2}></Col>
                                                                    <Col span={10}>
                                                                        <FormItem className="username" label="Cost Per unit">
                                                                            <Input value={editRecord?.cost_per_unit}
                                                                                onChange={(e) => {
                                                                                    setEditRecord(pre => {
                                                                                        return { ...pre, cost_per_unit: Number(e.target.value) }
                                                                                    })
                                                                                    setEditRecordDetails(pre => {
                                                                                        return { ...pre, cost_per_unit: Number(e.target.value) }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </FormItem>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            </Form>
                                                        </Modal>
                                                    </div>
                                                </Card>
                                            }
                                            <div>
                                                <br></br>
                                                <br></br>
                                            </div>
                                            <div className="header-col payment-button">
                                                <Button
                                                    onClick={() =>
                                                        handleDelivery()
                                                    }
                                                    type="primary"
                                                    htmlType="submit"
                                                    style={{ width: "100%", background: "#1e2a6c" }}
                                                    shape="round"
                                                >
                                                    <p style={{ color: '#ffff' }}>Make Delivery</p>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col span={24} md={2} lg={2} className="mb-24 "></Col>
                    </Row>
                </Space>
            </Row>

        </div>
    )
}

export default AddProductDelivery
