import { Row, Col, Button } from 'antd'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Redirect } from 'react-router-dom'
import React, {  useState } from 'react'

const PaymentOption = ({ isCash, handleSubmit, setIsCash, disabled }) => {
    const [redirect, setRedirect] = useState(false);

    const handleButtonClick = () => {
      setRedirect(true);
    };
  
    if (redirect) {
      return <Redirect to="/paymentcheckcart" />;
    }
    return (
        <Col span={24}>
            <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                    <Col span={12}>
                        <span className="" onClick={handleButtonClick}>
                            <AiOutlineArrowLeft style={{ fontSize: 20, marginTop: 10, marginRight: 10 }} />
                            <span style={{ color: "gray", fontSize: 20, marginTop: 10 }}>Back</span>
                        </span>
                    </Col>
                    <Col span={12}>
                        <div className="header-col payment-button" style={{ marginRight: 20 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    width: "100%",
                                    background: "#45BC5B",
                                    borderColor: "#45BC5B",

                                }}
                                shape="round"
                                onClick={handleSubmit}
                            >
                                {isCash ? <p style={{
                                    color: '#fff',
                                    alignContent: "center",
                                    marginTop: "5px",

                                }}>Complete</p> : <p style={{
                                    color: '#fff',
                                    alignContent: "center",
                                    marginTop: "5px",

                                }}>Validate</p>}

                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default PaymentOption