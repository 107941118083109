import {
    Button, Card, Col, Descriptions, Divider, Form,
    Input, Modal, Row, Typography
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from '../api/axios';
const USER_URL = 'user/'
const GET_ONE_BUSINESS = 'business/'

const Settings = () => {
    const { Title } = Typography;
    const [user, setUser] = useState([])
    const token = window.localStorage.getItem("Bearer")
    const business_id = window.localStorage.getItem("business_id")
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editingUser, setEditingUser] = useState(null)
    const [editBiz, setEditBiz] = useState(null);
    const [business, setBusiness] = useState({})


    const fetchUserData = useCallback(async () => {
        try {
            const response = await axios.get(USER_URL, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            setUser(response?.data)
        }
        catch {
            console.log("Error")
        }
    }, [token])
    const fetchBusinessData = useCallback(async () => {
        try {
            const response = await axios.get(`${GET_ONE_BUSINESS}${business_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            setBusiness(response?.data)
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id])
    useEffect(() => {
        fetchBusinessData().catch(console.error)
        fetchUserData().catch(console.error)
    }, [fetchBusinessData, fetchUserData])
    const PatchUserData = async (data) => {
        try {
            await axios.patch(USER_URL,
                JSON.stringify({ first_name: data?.first_name, last_name: data?.last_name, phone_no: data?.phone_no }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true
                }
            )
            fetchUserData()
        } catch (err) {
            console.log(err)
        }

    }
    const patchBusinessData = async (data) => {
        console.warn("business data", data)
        try {
            await axios.patch(`${GET_ONE_BUSINESS}${business_id}`,
                JSON.stringify({ name: data?.name, email: data?.email, phone_no: data?.phone_no, sector: data?.sector, url: data?.url, city: data?.city, country: data?.country }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // },
                    withCredentials: true
                }
            )
            fetchBusinessData()
        } catch (err) {
            console.log(err)
        }

    }
    const onUserEdit = () => {
        setIsModalVisible(true)
        setEditingUser({ ...user })
    }
    const onBusinessEdit = () => {
        setIsEditing(true)
        setEditBiz({ ...business })
    }
    const resetEditing = () => {
        setIsModalVisible(false)
        setEditingUser(null)
    }
    const resetEditingBiz = () => {
        setEditBiz(null)
        setIsEditing(false)
    }
    if (!token) {
        return <Redirect to="/businesses" />
    }
    if (!business_id) {
        return <Redirect to="/businesses" />
    }
    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout"></Row>
            <Row gutter={[24, 0]} className="dash-web-layout">
                <Col span={24}>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={18}>
                                <Title level={3}> User and Business Overview</Title>
                            </Col>
                            <Col span={3} offset={1}>
                                <div className="log-btn">
                                    <Link to="/add-business">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                marginLeft: "auto",
                                                background: "#ffff",
                                                borderColor: "#1DAFED"
                                            }}
                                        ><p style={{ color: '#1DAFED' }}>Add a Business</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Divider dashed="true" />
                <Col span={24} md={24} lg={24} className="mb-24 ">
                    <Card span={24} md={24} lg={24} xl={24} >
                        <Descriptions title="User Info" bordered column={{ xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                            extra={<div className="header-col sign-in-btn">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    onClick={() => {
                                        onUserEdit()
                                    }}
                                >
                                    EDIT INFO
                                </Button>
                            </div>}>
                            <Descriptions.Item label="First Name">{user.first_name} </Descriptions.Item>
                            <Descriptions.Item label="Last Name">{user.last_name}</Descriptions.Item>
                            <Descriptions.Item label="Phone Number">{user.phone_no}</Descriptions.Item>
                            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col span={24} md={24} lg={24} className="mb-24 ">
                    <Card span={24} md={24} lg={24} xl={24} >
                        <Descriptions title="Business Info" bordered column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            extra={<div className="header-col sign-in-btn">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    onClick={() => {
                                        onBusinessEdit()
                                    }}
                                >
                                    EDIT INFO
                                </Button>
                            </div>}>
                            <Descriptions.Item label="Business Name">{business.name}</Descriptions.Item>
                            <Descriptions.Item label="Business Email">{business.email}</Descriptions.Item>
                            <Descriptions.Item label="Business Phone Number">{business.phone_no}</Descriptions.Item>
                            <Descriptions.Item label="City">{business.city}</Descriptions.Item>
                            <Descriptions.Item label="Country">{business.country}</Descriptions.Item>
                            <Descriptions.Item label="Sector">{business.sector}</Descriptions.Item>
                            <Descriptions.Item label="Currency">{business.currency}</Descriptions.Item>
                            <Descriptions.Item label="URL">{business.url}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>

            <Modal okText="Save" title="Edit your information" visible={isModalVisible} onCancel={() => (resetEditing())}
                onOk={() => {
                    PatchUserData(editingUser)
                    resetEditing()
                }} >
                <Form.Item className="username" label="First Name"
                >
                    <Input value={editingUser?.first_name}
                        onChange={(e) => {
                            setEditingUser(pre => {
                                return { ...pre, first_name: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item
                    className="username"
                    label="Last Name"
                >
                    <Input value={editingUser?.last_name}
                        onChange={(e) => {
                            setEditingUser(pre => {
                                return { ...pre, last_name: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item
                    className="username"
                    label="Phone Number"
                >
                    <Input value={editingUser?.phone_no}
                        onChange={(e) => {
                            setEditingUser(pre => {
                                return { ...pre, phone_no: e.target.value }
                            })
                        }} />
                </Form.Item>
            </Modal>
            <Modal okText="Save" title="Edit Business Information" visible={isEditing} onCancel={() => (resetEditingBiz())}
                onOk={() => {
                    patchBusinessData(editBiz)
                    resetEditingBiz()
                }}>
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item className="username" label="Business Name">
                                <Input value={editBiz?.name}
                                    onChange={(e) => {
                                        setEditBiz(pre => {
                                            return { ...pre, name: e.target.value }
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="username" label="Business Email">
                                <Input value={editBiz?.email}
                                    onChange={(e) => {
                                        setEditBiz(pre => {
                                            return { ...pre, email: e.target.value }
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="username" label="Phone Number">
                                <Input value={editBiz?.phone_no}
                                    onChange={(e) => {
                                        setEditBiz(pre => {
                                            return { ...pre, phone_no: e.target.value }
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="username" label="URL">
                                <Input value={editBiz?.url}
                                    onChange={(e) => {
                                        setEditBiz(pre => {
                                            return { ...pre, url: e.target.value }
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="username" label="Sector">
                                <Input value={editBiz?.sector}
                                    onChange={(e) => {
                                        setEditBiz(pre => {
                                            return { ...pre, sector: e.target.value }
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="username" label="City">
                                <Input value={editBiz?.city}
                                    onChange={(e) => {
                                        setEditBiz(pre => {
                                            return { ...pre, city: e.target.value }
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="username" label="Country">
                                <Input value={editBiz?.country}
                                    onChange={(e) => {
                                        setEditBiz(pre => {
                                            return { ...pre, country: e.target.value }
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div >

    )
}

export default Settings
