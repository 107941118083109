import {
  Avatar, Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Typography
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { RiNumber1, RiNumber2 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from "../api/axios";
import Cart from "../components/Cart";
import CashAmountInput from "../components/CashAmountInput";
import PaymentOption from "../components/PaymentOption";
import PaymentRow from "../components/PaymentRow";
import AuthContext from "../context/AuthProvider";

const PAYMENT_URL = (business_id, transaction_id) => `/business/${business_id}/transaction/${transaction_id}/payment/`;
const TRANSACTION_URL = (business_id) => `/business/${business_id}/transaction/`;

const PaymentCheckCart = () => {
  const { Title } = Typography;
  const errRef = useRef(null);
  const [errMsg, setErrMsg] = useState("");
  const { transaction, setTransaction } = useContext(AuthContext);
  const [amount_paid, setAmountPaid] = useState(0);
  const [payment_type, setPaymentType] = useState("cash");
  const { setCompleteTransaction } = useContext(AuthContext);
  const token = window.localStorage.getItem("Bearer");
  const business_id = window.localStorage.getItem("business_id");
  const transaction_id = window.localStorage.getItem("transaction_id");
  const [success, setSuccess] = useState(false);
  const [transDetails, setTransDetails] = useState({});
  const [iscash, setIsCash] = useState(true);
  const [isSpin, setisSpin] = useState(false);
  const [disabled] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [valid, setValid] = useState("");
  const [OnNaviPaymentScreen, setOnNaviPaymentScreen] = useState(false);
  const [isCash, setCash] = useState();
  const [cashPayment, setCashPayment] = useState(true);
  const [mpesaComplete, setMpesaComplete] = useState(false);

  useEffect(() => {
    if (success && payment_type === "mpesa") {
      handleMpesaPayment();
    }
  }, [cashPayment, success]);

  const handleMpesaPayment = async () => {
    try {

      console.log("making mpesa payment", phone_number, PAYMENT_URL(business_id, transaction_id));
      const response = await axios.post(
        PAYMENT_URL(business_id, transaction_id),
        JSON.stringify({ amount_paid, payment_type, phone_number }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      setCompleteTransaction(response.data);
      setMpesaComplete(true);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg("Something went wrong, Please try again");
      }
      errRef.current.focus();
    }

  }

  useEffect(() => {
    if ((business_id && transaction_id)) {
      const token = window.localStorage.getItem("Bearer");

      const fetchBusinessData = async () => {
        try {
          const response = await axios.get(
            `${TRANSACTION_URL(business_id)}${transaction_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              withCredentials: true,
            }
          );
          setTransaction(response?.data);
          setTransDetails(response?.data.transaction);
        } catch (error) {
          console.log(error);
        }
      };
      fetchBusinessData();
    }
  }, [setTransaction, business_id, transaction_id]);

  const onChangeAmount = (e) => {
    const amount_paid = e.target.value;
    setAmountPaid(amount_paid);
  };


  const handleSubmit = async () => {
    console.warn(amount_paid, transaction_id, payment_type);
    // setDisabled(true)
    // if (!iscash) {
    //     setPaymentType("Mpesa")
    // }

    if (iscash) {
      if (amount_paid > 0) {
        try {
          const response = await axios.post(
            PAYMENT_URL(business_id, transaction_id),
            JSON.stringify({ amount_paid, payment_type }),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
              },
              withCredentials: true,
            }
          );
          setCompleteTransaction(response.data);
          setSuccess(true);
        } catch (err) {
          if (!err?.response) {
            setErrMsg("No Server Response");
          } else {
            setErrMsg("Something went wrong, Please try again");
          }
          errRef.current.focus();
        }
      } else {
        setErrMsg("Please input amount1 Received");
      }
    }
  };

  // if (success) {
  //   return <Redirect to="/completed" />;
  // }

  if (OnNaviPaymentScreen) {
    if (isCash) {
      return <Redirect to="/payment" />;
    }
    // return <Redirect to="/completed" />;
  }

  if (mpesaComplete) {
    return <Redirect to="/completed" />;
  }
  if (!token) {
    return <Redirect to="/" />;
  }

  function handleInputChange(event) {
    const inputValue = event.target.value;
    const pattern = /(0|254)7(\d){8}/; // regular expression pattern
    const isValid = pattern.test(inputValue); // validate input value with pattern
    setInputValue(inputValue);
    if (isValid) {
      setphone_number(inputValue);
    } else { setphone_number(""); }
    console.log(isValid); // log the validation result
    setValid(isValid);
  }

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]} className="dash-mobile-layout">
        <Col span={24}>
          <Cart />
          {/* <PaymentRow transDetails={transDetails} /> */}
          <>
            <br></br>
            <br></br>
          </>
          {/* {iscash ?
                        <CashAmountInput errMsg={errMsg} onChangeAmount={onChangeAmount} amount_paid={amount_paid} errRef={errRef} />
                        : <>{isSpin && <Spin size="large" className='spin_C' />}</>}
                    <>
                        <br></br>
                    </> */}
        </Col>
      </Row>

      <div className="mobile-footer dash-mobile-layout">
        <Form style={{ marginLeft: 10, marginRight: 10 }}>
          <Form.Item
            className="username"
            name="email"
            id="email"
            rules={[
              {
                required: false,
                message: "Please input Amount!",
              },
            ]}
          >
            <Input
              style={{
                backgroundColor: "#e6e6e6",
                height: 60,
                border: "none",
                fontFamily: "inherit",
              }}
              placeholder="Enter Customer Mobile"
              value={inputValue}
              onChange={handleInputChange}
            />
            {valid || valid === "" ? null : (
              <p className="text-danger"> please enter valid mobile number(254....)</p>
            )}
          </Form.Item>
        </Form>
        <br />
        <Form style={{ marginLeft: 10, marginRight: 10 }}>
          <Form.Item
            className="username"
            name="email"
            id="email"
            rules={[
              {
                required: false,
                message: "Please input Amount!",
              },
            ]}
          >
          </Form.Item>
        </Form>
        <Row>
          {" "}
          <Col span={12}>
            <div className="header-col payment-button">
              <p
                style={{
                  color: "gray",
                  alignContent: "center",
                  marginTop: "5px",
                  fontWeight: "bold",
                  letterSpacing: 0.4,
                  fontSize: 20,
                }}
                onClick={() => {
                  setOnNaviPaymentScreen(true);
                  setCash(true);
                }}
              >
                Receive Cash
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div className="header-col payment-button">
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  background: "#45BC5B",
                  borderColor: "#45BC5B",
                }}
                shape="round"
                disabled={!valid}
                onClick={() => {
                  setCashPayment(false);
                  setPaymentType("mpesa");
                  setSuccess(true);
                  setCash(false);
                  setAmountPaid(transaction?.transaction?.total_cost);
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    alignContent: "center",
                    marginTop: "5px",
                  }}
                >
                  Validate
                </p>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="dash-web-layout">
        <Space direction="vertical" size="large">
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={24}>
                <Title level={3}>Cash Payment</Title>
              </Col>
            </Row>
          </div>
          <Row gutter={[24, 0]}>
            <Col span={24} md={20} lg={20} className="mb-24 ">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={24}>
                      <Avatar shape="circle" icon={<RiNumber1 />} />
                      <Title level={4}>Transaction Details</Title>
                    </Col>
                    <Col span={24}>
                      <Cart />
                    </Col>
                    <Col span={24}>
                      <PaymentRow transDetails={transDetails} />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24} md={20} lg={20} className="mb-24 ">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={20}>
                      <Avatar shape="circle" icon={<RiNumber2 />} />
                      {iscash ? (
                        <Title level={4}>Cash Payment</Title>
                      ) : (
                        <Title level={4}>Mpesa Payment</Title>
                      )}
                    </Col>
                    <Col span={20}>
                      {iscash ? (
                        <CashAmountInput
                          errMsg={errMsg}
                          onChangeAmount={onChangeAmount}
                          amount_paid={amount_paid}
                          errRef={errRef}
                        />
                      ) : (
                        <>
                          <Divider />
                          {isSpin && <Spin size="large" className="spin_C" />}
                        </>
                      )}
                    </Col>
                    <Col span={20}>
                      <Row>
                        <PaymentOption
                          isCash={iscash}
                          handleSubmit={handleSubmit}
                          setIsCash={setIsCash}
                          disabled={disabled}
                        />
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Space>
      </Row>
    </div >
  );
};

export default PaymentCheckCart;
