import { Avatar, Button, Card, Col, DatePicker, Form, Input, message, Row, Select, Typography } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { RiNumber1, RiNumber2 } from "react-icons/ri"
import { Redirect } from "react-router-dom"
import axios from '../../api/axios'
import ExpenseCategory from './categories/ExpenseCategory'
const EXPENSE_URL = (business_id) => `business/${business_id}/expense/`;
const EXPENSE_CATEGORY_URL = (business_id) => `business/${business_id}/expense/category/`;

const { Title } = Typography
const { Option } = Select

const ExpenseForm = () => {
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    const errRef = useRef(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [description, setDescription] = useState('')
    const [expense_type, setExpenseType] = useState('')
    const [expense_amount, setAmount] = useState(0)
    const [expense_date, setExpenseDate] = useState('')
    const [category_id, setCategoryId] = useState();
    const [category_list, setCategorList] = useState([]);
    const [errMsg, setErrMsg] = useState('')
    const [success, setSucess] = useState('')


    const onChangeDescription = (e) => {
        const description = e.target.value;
        setDescription(description);
    }
    const onChangeType = (value) => {
        const expense_type = value;
        setExpenseType(expense_type);
    }
    const onChangeAmount = (e) => {
        const expense_amount = Number(e.target.value)
        setAmount(expense_amount);
    }
    const onChangeDate = (date, dateString) => {
        const expense_date = dateString
        setExpenseDate(expense_date);
    }
    const handleCategorySelect = (key) => {
        setCategoryId(key)
    }
    const fetchCategoryData = useCallback(async () => {
        try {
            const response = await axios.get(EXPENSE_CATEGORY_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setCategorList(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id, setCategorList])
    useEffect(() => {
        fetchCategoryData().catch(console.error())
    }, [fetchCategoryData])
    if (success) {
        return <Redirect to="/expense" />
    }

    const handleSubmit = async () => {
        try {
            await axios.post(EXPENSE_URL(business_id),
                JSON.stringify({ description, expense_type, expense_amount, expense_date, category_id }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            message.success('You have successfully added an Expense', 3)
            setSucess(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('An Error occurred. Please try again');
            }
            errRef.current.focus();
        }
    }
    return (
        <>
            <Form layout="vertical" className="row-col" onFinish={handleSubmit}>
                <Row gutter={[24, 0]}>
                    <Col span={24} md={20} lg={20} className="mb-24 ">
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={20}>
                                        <Avatar shape="circle" icon={<RiNumber1 />} />
                                        <Title level={4}>Select Category</Title>
                                    </Col>
                                    <Col span={20}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col span={14}>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a Category"
                                                        optionFilterProp="children"
                                                        onChange={handleCategorySelect}
                                                        filterOption={(input, option) =>
                                                            option.value1.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "90%" }}
                                                    >
                                                        {category_list.map((c) => (

                                                            <Option value1={c.name} key={c.category_id}>{c.name}</Option>

                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col span={7} offset={3}>
                                                    <div className="header-col sign-in-btn">
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            onClick={() => { setIsModalVisible(true) }}
                                                        ><p style={{ color: '#fff' }}>Add Category</p>
                                                        </Button>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24} md={20} lg={20} className="mb-24 ">
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={20}>
                                        <Avatar shape="circle" icon={<RiNumber2 />} />
                                        <Title level={4}>Enter Details</Title>
                                    </Col>
                                    <Col span={20}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        className="username"
                                                        label={<>Expense Description<span style={{ color: "red" }}>*</span></>}
                                                        name="description"
                                                        id="description"
                                                        onChange={onChangeDescription}
                                                        value={description}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input Expense Description!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        className="username"
                                                        label={<>Expense Amount<span style={{ color: "red" }}>*</span></>}
                                                        name="expense_amount"
                                                        id="expense_amount"
                                                        onChange={onChangeAmount}
                                                        value={expense_amount}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input Expense Amount!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        className="username"
                                                        label={<>Expense Date<span style={{ color: "red" }}>*</span></>}
                                                        name="expense_date"
                                                        id="expense_date"
                                                        value={expense_date}
                                                        rules={[
                                                            {
                                                                required: true
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker style={{ width: "100%" }} onChange={onChangeDate} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        className="username"
                                                        label={<>Expense Type<span style={{ color: "red" }}>*</span></>}
                                                        name="expense_type"
                                                        id="expense_type"

                                                        value={expense_type}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input Expense Type!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder="Select Expense Type"
                                                            onChange={onChangeType}
                                                            allowClear
                                                        >
                                                            <Option value="operating">Operating</Option>
                                                            <Option value="payroll">Payroll</Option>
                                                            <Option value="cog">Cog</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10} offset={7}>
                                                    <Form.Item>
                                                        <div className="header-col sign-in-btn">
                                                            <Button
                                                                type="primary"
                                                                htmlType="submit"
                                                                style={{ width: "100%", marginTop: "25px", background: "#45BC5B", borderColor: "#45BC5B" }}
                                                                shape="round"
                                                            >
                                                                <p style={{ color: "#ffff" }}>ADD</p>
                                                            </Button>
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Form>
            <ExpenseCategory fetchCategoryData={fetchCategoryData} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </>
    )
}

export default ExpenseForm
