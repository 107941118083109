import {
    Card, Col, Collapse, Descriptions, Input, List,
    Modal, Row
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";

import Title from 'antd/lib/skeleton/Title';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineShopping } from 'react-icons/ai';

const ADD_TRANSACTION_URL = (business_id, transaction_id) => `/business/${business_id}/transaction/${transaction_id}/`
const CUSTOMER_URL = (business_id) => `/business/${business_id}/customer/`;
const GET_PRODUCTS_URL = `/business/${window.localStorage.getItem("business_id")}/product/`
const GET_SERVICES_URL = `/business/${window.localStorage.getItem("business_id")}/service/`

const TRANSACTION_URL = (business_id) => `/business/${business_id}/transaction/`;

const Cart = () => {
    const { complete_transaction } = useContext(AuthContext);
    const { service_cart, setServiceCart } = useContext(AuthContext);
    const { cart, setCart } = useContext(AuthContext);
    const { transaction, setTransaction } = useContext(AuthContext);

    const [product_data, setProductData] = useState([])
    const [service_data, setServiceData] = useState([])
    const [transDetails, setTransDetails] = useState({})

    const business_id = window.localStorage.getItem("business_id");
    const transaction_id = window.localStorage.getItem("transaction_id");
    const customer_id = window.localStorage.getItem("customer_id");

    const { Panel } = Collapse

    const [customerData, setCutomerData] = useState([])
    const [transData] = useState(complete_transaction?.transaction)

    const [IsEditing, setIsEditing] = useState(false);
    const [editRecord, setEditRecord] = useState(null);
    const [editRecordDetails, setEditRecordDetails] = useState(null);

    const IsLoggedIn = window.localStorage.getItem("Bearer");

    const IsBusiness = window.localStorage.getItem("business_id");
    const [cartdetail, setCartDetail] = useState([]);
    const [serviceCartdetail, setServiceCartDetail] = useState([]);
    const resetEditing = () => {
        setEditRecord(null);
        setEditRecordDetails(null);
        setIsEditing(false);
    };
    const onDeleteService = (item) => {
        Modal.confirm({
            title: "Are you sure you want to Delete?",
            okText: "Yes",
            okType: "danger",
            onOk: () => {
                setServiceCart(
                    service_cart.filter((c) => c.service_id !== item.service_id)
                );
                setServiceCartDetail(
                    serviceCartdetail.filter((c) => c.service_id !== item.service_id)
                );
            },
        });
    };

    const onDeleteProduct = (item) => {
        Modal.confirm({
            title: "Are you sure you want to Delete?",
            okText: "Yes",
            okType: "danger",
            onOk: () => {
                setCart(cart.filter((c) => c.product_id !== item.product_id));
                setCartDetail(
                    cartdetail.filter((c) => c.product_id !== item.product_id)
                );

            },
        });
        console.log(cart);
    };
    const onEditProduct = (item) => {
        setIsEditing(true);
        setEditRecord({ product_id: item.product_id, quantity: item.count });
        setEditRecordDetails({
            product_id: item.product_id,
            quantity: item.count,
            name: item.name,
            price: item.price,
        });
    };
    useEffect(() => {
        const token = window.localStorage.getItem("Bearer");
        const fetchBusinessData = async (business_id) => {
            try {
                const response = await axios.get(`${TRANSACTION_URL(business_id)}${transaction_id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                })
                setTransDetails(response?.data.transaction)
                setTransaction(response?.data)
                setServiceData(response?.data.services)
                setProductData(response?.data.products)
                if (response.data.products !== 0) {
                    var cartt = [];
                    response?.data.products.map((data) => {
                        cartt.push({ "product_id": data.product.product_id, "quantity": data.details.quantity });
                    });
                    setCart(cartt);
                }
                setCartDetail(response?.data.products);
            } catch (error) {
                console.log(error)
            }
        }
        fetchBusinessData(business_id)
        const fetchCutomerData = async () => {
            try {
                const response = await axios.get(`${CUSTOMER_URL(business_id)}${customer_id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // }
                })
                setCutomerData(response?.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchCutomerData()
    }, [setTransaction, setProductData, setServiceData]);

    const [first_name, setFirstName] = useState("Anonymous");

    const handleTransaction = async (reasonhit) => {

        setCart((pre) => {
            return pre.map((record) => {
                if (
                    record.product_id === editRecord.product_id
                ) {
                    return editRecord;
                } else {
                    return record;
                }
            });
        });

        console.log(cart);
        console.log(window.localStorage.getItem("Bearer"));
        if (product_data.length > 0 || service_data.length > 0) {
            // try {
            await axios
                .post(CUSTOMER_URL(business_id), JSON.stringify({ first_name }), {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("Bearer")}`,
                        "content-type": "application/json",
                    },
                    withCredentials: true,

                })
                .then(async (response) => {
                    const formData = {
                        transaction: { customer_id: response?.data?.customer_id },
                        transaction_details: { products: cart, services: service_cart },
                    };
                    const responses = await axios.post(
                        ADD_TRANSACTION_URL(business_id, transaction_id),
                        JSON.stringify(formData),
                        {
                            headers: {
                                Authorization: `Bearer ${window.localStorage.getItem("Bearer")}`,
                                "content-type": "application/json",
                            },
                            withCredentials: true,
                            params: {
                                business_id: window.localStorage.getItem("business_id"),
                            },
                        }
                    );
                    const transaction_id = responses?.data?.transaction.transaction_id;
                    window.localStorage.setItem("transaction_id", transaction_id);

                    try {
                        const response = await axios.get(`${TRANSACTION_URL(business_id)}${transaction_id}`, {
                            headers: {
                                "Authorization": `Bearer ${window.localStorage.getItem("Bearer")}`,
                                "Content-Type": "application/json",
                                "Accept": "application/json"
                            },
                            withCredentials: true,
                            params: {
                                "business_id": window.localStorage.getItem("business_id")
                            }
                        })
                        setTransaction(response?.data)
                        setTransDetails(response?.data.transaction)

                        setServiceData(response?.data.services)
                        setProductData(response?.data.products)
                    } catch (error) {
                        console.log(error)
                    }


                    // setSuccess(true);
                });
            // } catch (err) {
            //     console.log(err);
            // }
        } else if (cart == null) {
            console.log("Please Add Product or service to proceed!");
        } else {
            console.log("Please add product or service to proceed!");
        }
    };
    return (
        <div>
            <Collapse accordion expandIconPosition="right" style={{ color: '#F0F0F0', margin: 0 }}>
                <Panel header={`Anonymous Customer`} key="1">
                    {/* <Panel header={`${customerData.first_name} ${customerData.last_name}`} key="1"> */}
                    {/* <Descriptions bordered column={{ xxl: 4, xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
                        <Descriptions.Item >{customerData.first_name} {customerData.last_name} </Descriptions.Item>
                        <Descriptions.Item >{customerData.phone_no}</Descriptions.Item>
                    </Descriptions> */}
                    {product_data.length > 0 && (
                        <>

                            {transaction.products.map((item) => (
                                <div>

                                    <Row
                                        align="middle"
                                        gutter={[24, 0]}
                                        key={item.product.product_id}
                                        style={{ marginBottom: 5, marginTop: 5, }}
                                    >
                                        <Col span={15}>
                                            <div level={5} style={{ color: "black", fontFamily: "inherit", letterSpacing: 1 }}>{item.product.name}</div>

                                        </Col>
                                        <Col span={3}>
                                            {/* {item.product.quantity} x <b>KES</b>{" "} */}
                                            <b style={{ color: "black", fontFamily: "inherit" }}>{item.product.price}.00</b>                                {/* <span>.00</span> */}
                                        </Col>
                                        <Col span={2}>
                                            <AiOutlineEdit
                                                onClick={() => {
                                                    onEditProduct(item.product);
                                                }}
                                                style={{ color: "#45BC5B" }}
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <AiOutlineDelete
                                                onClick={() => {
                                                    onDeleteProduct(item.product);
                                                }}
                                                style={{ color: "red" }}
                                            />
                                        </Col>
                                    </Row>
                                    <div style={{ width: "100%", height: 0.5, backgroundColor: "#d3cdcd", marginBottom: 10 }}></div>
                                </div>
                            ))}
                        </>
                    )}
                    {service_data.length > 0 &&

                        <>

                            {transaction.services.map((item) => (<div>

                                <Row
                                    align="middle"
                                    gutter={[24, 0]}

                                    style={{ marginBottom: 5, marginTop: 5, }}
                                >
                                    <Col span={15}>
                                        <div level={5} style={{ color: "black", fontFamily: "inherit", letterSpacing: 1 }}>{item.service.name}</div>

                                    </Col>
                                    <Col span={3}>
                                        {/* {item.product.quantity} x <b>KES</b>{" "} */}
                                        <b style={{ color: "black", fontFamily: "inherit" }}>{item.service.price}.00</b>                                {/* <span>.00</span> */}
                                    </Col>
                                    <Col span={2}>
                                        <AiOutlineEdit
                                            // onClick={() => {
                                            //     onEditProduct(item.service);
                                            // }}
                                            style={{ color: "#45BC5B" }}
                                        />
                                    </Col>
                                    <Col span={2}>
                                        <AiOutlineDelete
                                            onClick={() => {
                                                onDeleteService(item.service);
                                            }}
                                            style={{ color: "red" }}
                                        />
                                    </Col>
                                </Row>
                                <div style={{ width: "100%", height: 0.5, backgroundColor: "#d3cdcd", marginBottom: 10 }}></div>
                            </div>))}
                        </>

                    }
                </Panel>
            </Collapse>
            <Row style={{ backgroundColor: "white", marginLeft: 2, marginRight: 2 }}>
                <Col span={7}>
                    <Col span={24} className="sales-record-amount ">
                        <div style={{ color: "gray", fontWeight: "bolder", fontSize: 15, letterSpacing: 1, marginTop: 5, marginLeft: 10 }}>
                            Totals
                        </div>
                    </Col>
                </Col>
                <Col span={1}><div style={{ height: "100%", width: 0.8, backgroundColor: "#dbd0d0" }}></div></Col>

                <Col span={7}>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={6}>
                                <AiOutlineShopping size={30} color="#45BC5B" />
                            </Col>
                            <Col span={18} className="sales-record-amount ">
                                <div style={{ color: "#45BC5B", fontWeight: "bolder", fontSize: 15, letterSpacing: 1 }}>
                                    {product_data.length + service_data.length} items
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={1}><div style={{ height: "100%", width: 0.8, backgroundColor: "#dbd0d0", marginLeft: 10 }}></div></Col>

                <Col span={8}>
                    <Col span={24} className="sales-record-amount ">
                        <div style={{ color: "#45BC5B", fontWeight: "bolder", fontSize: 15, letterSpacing: 1, marginTop: 5 }}>
                            KES {transDetails.total_cost}.00
                        </div>
                    </Col>

                </Col>
            </Row>

            <Modal
                title="Change the quantity"
                okText="Save"
                visible={IsEditing}
                onCancel={() => {
                    resetEditing();
                }}
                onOk={() => {
                    console.log(editRecord);
                    console.log(editRecordDetails);
                    setCart((pre) => {
                        return pre.map((record) => {
                            if (
                                record.product_id === editRecord.product_id
                            ) {
                                return editRecord;
                            } else {
                                return record;
                            }
                        });
                    });
                    setCartDetail((pre) => {
                        return pre.map((record) => {
                            if (
                                record.product_id ===
                                editRecordDetails.product_id
                            ) {
                                return editRecordDetails;
                            } else {
                                return record;
                            }
                        });
                    });
                    console.log(cart);
                    // if (cart.length !== 0) {
                    handleTransaction();
                    // }
                    resetEditing();
                }}
            >
                <Input
                    value={editRecord?.quantity}
                    onChange={(e) => {
                        setEditRecord((pre) => {
                            return {
                                ...pre,
                                quantity: Number(e.target.value),
                            };
                        });
                        setCart((pre) => {
                            return pre.map((record) => {
                                if (
                                    record.product_id === editRecord.product_id
                                ) {
                                    return { product_id: editRecord.product_id, quantity: Number(e.target.value) };
                                } else {
                                    return record;
                                }
                            });
                        });
                        console.log(cart);
                        setEditRecordDetails((pre) => {
                            return {
                                ...pre,
                                quantity: Number(e.target.value),
                            };
                        });
                    }}
                />
            </Modal>
        </div>
        // <Row gutter={[24, 24]} span={24}>
        //     <Col span={24}>
        //         <Card bordered={false}
        //             bodyStyle={{ paddingTop: 0 }}
        //             className="header-solid h-full  ant-list-yes"
        //             title={<h6 className="font-semibold m-0">Sale Info</h6>}>
        //             {product_data.length > 0 &&


        //                 // <List
        //                 //     className="transactions-list ant-newest"
        //                 //     itemLayout="horizontal"
        //                 //     dataSource={transaction.products}
        //                 //     renderItem={(item) => (
        //                 //         <List.Item>
        //                 //             <List.Item.Meta
        //                 //                 title={item.product.name}
        //                 //                 description={<span >{item.product.price} x {item.details.quantity}</span >}
        //                 //             />
        //                 //             <div className="amount">
        //                 //                 <span className='receipt-cost'>KES  {item.details.cost}</span>
        //                 //             </div>
        //                 //         </List.Item>
        //                 //     )}
        //                 // />
        //             }
        //             {service_data.length > 0 &&
        //                 <List
        //                     className="transactions-list ant-newest"
        //                     itemLayout="horizontal"
        //                     dataSource={transaction.services}
        //                     renderItem={(item) => (
        //                         <List.Item>
        //                             <List.Item.Meta
        //                                 title={item.service.name}
        //                                 description={<span >{item.service.price} x {item.details.quantity}</span >}
        //                             />
        //                             <div className="amount">
        //                                 <span className='receipt-cost'>KES  {item.details.cost}</span>
        //                             </div>
        //                         </List.Item>
        //                     )}
        //                 />
        //             }
        //         </Card>
        //     </Col>
        // </Row>
    )
};

export default Cart;
