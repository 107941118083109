import {
  Avatar, Button, Card, Col, DatePicker, Form,
  Input, Row, Select, Space, Typography
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { RiNumber1 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from '../api/axios';
const ADD_CUSTOMER_URL = (business_id) => `/business/${business_id}/customer/`

const { Title } = Typography;
const { Option } = Select;

const AddCustomer = () => {
  const errRef = useRef(null);
  const token = window.localStorage.getItem("Bearer");
  const business_id = window.localStorage.getItem("business_id")

  const [email, setEmail] = useState('');
  const [first_name, setFirstName] = useState('');
  const [phone_no, setPhoneNo] = useState('');
  const [last_name, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [date_of_birth, setDateOfBirth] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setErrMsg('');
  }, [first_name, email, phone_no, last_name])

  const onChangeFirstName = (e) => {
    const first_name = e.target.value;
    setFirstName(first_name);
  };
  const onChangeLastName = (e) => {
    const last_name = e.target.value;
    setLastName(last_name);
  };
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePhoneNo = (e) => {
    const phone_no = e.target.value
    if (phone_no.startsWith("0")) {
      const phone_now = "254" + phone_no.replace("0", "")
      setPhoneNo(phone_now)
    } else if (phone_no.startsWith("7")) {
      const phone_now = "254" + phone_no
      setPhoneNo(phone_now)
    } else if (phone_no.startsWith("1")) {
      const phone_now = "254" + phone_no
      setPhoneNo(phone_now)
    } else {
      setPhoneNo(phone_no)
    }
  };
  const onChangeGender = (value) => {
    const gender = value;
    setGender(gender);
  };
  const onChangeDateOfBirth = (date, dateString) => {
    const date_of_birth = dateString
    setDateOfBirth(date_of_birth)
  };

  const handleSubmit = async () => {
    console.warn(first_name, last_name, phone_no, email, date_of_birth)

    try {
      await axios.post(ADD_CUSTOMER_URL(business_id),
        JSON.stringify({ first_name, email, phone_no, last_name, date_of_birth, gender }),
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "application/json"
          },
          withCredentials: true,
          // params: {
          //   "business_id": business_id
          // }
        }
      )
      setSuccess(true)
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else {
        setErrMsg('Cant add customer');
      }
      errRef.current.focus();
    }
  }
  if (!token) {
    return <Redirect to="/" />
  }

  if (success) {
    return <Redirect to="/customer" />
  }
  return (
    <>
      <Row gutter={[24, 0]} className="dash-mobile-layout">
        <Col xs={24} md={12} className="col-info">
          <Card bordered={false} className="criclebox ">
            <Title className="font-regular text-muted" level={4}>
              Add Customer Details here
            </Title>
            <p ref={errRef} className='Error-p'>
              {errMsg}
            </p>
            <Form
              onFinish={handleSubmit}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="username"
                label={<>First Name <span style={{ color: "red" }}>*</span></>}
                name="first_name"
                id="first_name"
                onChange={onChangeFirstName}
                value={first_name}
                rules={[
                  {
                    required: true,
                    message: "Please input First name!",
                  },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>

              <Form.Item
                className="username"
                label={<>Last Name  <span style={{ color: "red" }}>*</span></>}
                name="last_name"
                id="last_name"
                onChange={onChangeLastName}
                value={last_name}
                rules={[
                  {
                    required: true,
                    message: "Please input Last Name!",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>

              <Form.Item
                className="username"
                label={<>Email <span style={{ color: "red" }}>*</span></>}
                name="email"
                id="email"
                onChange={onChangeEmail}
                value={email}
                rules={[
                  {
                    required: true,
                    message: "Please input Email!",
                  }, { type: "email" }
                ]}
              >
                <Input placeholder="Customer Email" />
              </Form.Item>

              <Form.Item
                className="username"
                label="Phone Number"
                name="phone_no"
                id="phone_no"
                onChange={onChangePhoneNo}
                value={phone_no}
                rules={[
                  {
                    required: false,
                    message: "Please input Phone Number!",
                  },
                ]}
              >
                <Input placeholder="Phone Number: 2547xxxxx" />
              </Form.Item>

              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Gender"
                      name="gender"
                      id="gender"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select an Option"
                        onChange={onChangeGender}
                        allowClear
                      >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                        <Option value="Other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Date of Birth"
                      name="date_of_birth"
                      id="date_of_birth"
                      value={date_of_birth}
                      rules={[
                        {
                          required: false
                        },
                      ]}
                    >
                      <Space>
                        <DatePicker onChange={onChangeDateOfBirth} />
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Form.Item>
                <div className="header-col sign-in-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", background: "#45BC5B", borderColor: "#45BC5B" }}
                    shape="round"
                  >
                    ADD
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row className="dash-web-layout">
        <Space direction="vertical" size='large'>
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={24}>
                <Title level={3}>Add Customer</Title>
              </Col>
            </Row>
          </div>

          <Row gutter={[24, 0]}>
            <Col span={24} md={24} lg={24} className="mb-24 ">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={24}>
                      <Avatar shape="circle" icon={<RiNumber1 />} />
                      <Title level={4}>Enter Details</Title>
                    </Col>
                    <Col span={24}>
                      <p ref={errRef} className='Error-p'>
                        {errMsg}
                      </p>
                      <Form onFinish={handleSubmit} layout="vertical" className="row-col">
                        <div className="number">
                          <Row align="middle" gutter={[24, 0]}>
                            <Col span={12}>
                              <Form.Item
                                className="username"
                                label={<>First Name<span style={{ color: "red" }}>*</span></>}
                                name="first_name"
                                id="first_name"
                                onChange={onChangeFirstName}
                                value={first_name}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input First name!",
                                  },
                                ]}
                              >
                                <Input placeholder="First name" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                className="username"
                                label={<>Last Name<span style={{ color: "red" }}>*</span></>}
                                name="last_name"
                                id="last_name"
                                onChange={onChangeLastName}
                                value={last_name}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Last Nane!",
                                  },
                                ]}
                              >
                                <Input placeholder="Last Name" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                className="username"
                                label={<>Email<span style={{ color: "red" }}>*</span></>}
                                name="email"
                                id="email"
                                onChange={onChangeEmail}
                                value={email}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Email!",
                                  }, { type: "email" }
                                ]}
                              ><Input placeholder="Customr Email" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                className="username"
                                label="Phone Number"
                                name="phone_no"
                                id="phone_no"
                                onChange={onChangePhoneNo}
                                value={phone_no}
                                rules={[
                                  {
                                    required: false,
                                    message: "Please input Phone Number!",
                                  },
                                ]}
                              >
                                <Input placeholder="Phone Number: 2547xxxxx" />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                className="username"
                                label="Gender"
                                name="gender"
                                id="gender"
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select an Option"
                                  onChange={onChangeGender}
                                  allowClear
                                >
                                  <Option value="Male">Male</Option>
                                  <Option value="Female">Female</Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                className="username"
                                label="Date of Birth"
                                name="date_of_birth"
                                id="date_of_birth"
                                value={date_of_birth}
                                rules={[
                                  {
                                    required: false
                                  },
                                ]}
                                style={{ width: 200 }}
                              >
                                <Space>
                                  <DatePicker onChange={onChangeDateOfBirth} style={{ width: "350px" }} />
                                </Space>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <Col span={12} offset={6}>
                          <Form.Item>
                            <div className="header-col sign-in-btn">
                              <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "100%", background: "#45BC5B" }}
                                shape="round"
                              >
                                <p style={{ color: '#ffff' }}>ADD</p>
                              </Button>
                            </div>
                          </Form.Item>
                        </Col>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Space>
      </Row>
    </>
  )
};

export default AddCustomer;
