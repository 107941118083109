import {
    Avatar, Button, Card, Col, Form,
    Input, Row, Select, Space, Typography
} from "antd";
import { useEffect, useRef, useState } from 'react';
import { AiOutlineBank } from "react-icons/ai";
import { RiNumber1 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from '../api/axios';
const ACCOUNT_URL = (business_id) => `business/${business_id}/account/`;
const { Title } = Typography;
const { Option } = Select;

const AddMoneyAccount = () => {
    const errRef = useRef(null);
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")

    const [account_name, setAccountName] = useState('');
    const [account_number, setAccountNumber] = useState('');
    const [currency, setCurrency] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    const onChangeAccountName = (e) => {
        const account_name = e.target.value;
        setAccountName(account_name);
    };
    const onChangeAccountNumber = (e) => {
        const account_number = e.target.value;
        setAccountNumber(account_number);
    };

    const onChangeCurrency = (value) => {
        setCurrency(value)
    }
    useEffect(() => {
        setErrMsg('');
    }, [account_number, account_name, currency])

    const handleSubmit = async () => {
        console.warn(account_number, account_name, currency)

        try {
            await axios.post(ACCOUNT_URL(business_id),
                JSON.stringify({ account_number, account_name, currency }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            setSuccess(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add Account');
            }
            errRef.current.focus();
        }
    }
    if (!token) {
        return <Redirect to="/" />
    }

    if (success) {
        return <Redirect to="/accounts" />
    }

    return (
        <Row className="dash-web-layout">
            <Space direction="vertical" size='large'>
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col span={24}>
                            <Title level={3}>Connect Money Account</Title>
                        </Col>
                    </Row>
                </div>

                {/* <Row gutter={[24, 0]}>
                    <Col span={24} md={20} lg={20} className="mb-24 ">
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={20}>
                                        <Avatar shape="circle" icon={<RiNumber1 />} />
                                        <Title level={4}>Select Source</Title>
                                    </Col>
                                    <Col span={20}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col span={10}>
                                                    <Form.Item
                                                        className="username"
                                                        name="url"
                                                        id="url"
                                                        // onChange={onChangeUrl}
                                                        // value={url}
                                                        rules={[
                                                            {
                                                                required: false,
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Account Number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item
                                                        className="username"
                                                        name="sector"
                                                        id="sector"
                                                        // onChange={onChangeSector}
                                                        // value={sector}
                                                        rules={[
                                                            {
                                                                required: false
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Account Name" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row> */}

                <Row gutter={[24, 0]}>
                    <Col span={24} md={22} lg={22} className="mb-24 ">
                        <Card bordered={false} className="criclebox " style={{ background: "#f7f7f7" }} >
                            <div className="number">
                                <Form onFinish={handleSubmit}>
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={20}>
                                            <Avatar shape="circle" icon={<RiNumber1 />} style={{ background: "white" }} />
                                            <Title level={4}>Enter Details</Title>
                                            <p ref={errRef} className='Error-p'>
                                                {errMsg}
                                            </p>
                                        </Col>
                                        <Col span={20}>
                                            <div className="number">
                                                <Row align="middle" gutter={[24, 0]}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            className="username"
                                                            name="account_number"
                                                            id="account_number"
                                                            onChange={onChangeAccountNumber}
                                                            value={account_number}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Account Number" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            className="username"
                                                            name="account_name"
                                                            id="account_name"
                                                            onChange={onChangeAccountName}
                                                            value={account_name}
                                                            rules={[
                                                                {
                                                                    required: true
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Account Name" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                className="username"
                                                name="currency"
                                                id="currency"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: 300 }}
                                                    placeholder="Search to Select Currency"
                                                    onChange={onChangeCurrency}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    <Option value="KES">KES</Option>
                                                    <Option value="UGX">UGX</Option>
                                                    <Option value="TZS">TZS</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item>
                                                <Col span={24} md={18} lg={16} className="mb-24 " style={{
                                                    marginBottom: "-150px",
                                                    left: "100px",
                                                    position: "relative"
                                                }}>
                                                    <Card bordered={false} className="criclebox ">
                                                        <div className="number">
                                                            <Row align="middle" gutter={[24, 0]}>
                                                                <Col span={24}>
                                                                    <Title level={5}>
                                                                        Money Account
                                                                    </Title><br></br>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <div className="number">
                                                                        <Row align="middle" gutter={[24, 0]}>
                                                                            <Col span={6} className="sales-record-amount">
                                                                                <Col span={6} style>
                                                                                    <AiOutlineBank size={30} />
                                                                                </Col>
                                                                            </Col>
                                                                            <Col span={18}>
                                                                                <h6>Sync your account</h6>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                                <div>
                                                                    <br></br>
                                                                </div>
                                                                <Col xs={24} md={24} className="d-flex">
                                                                    <div className="log-btn">
                                                                        {/* <NavLink to="/add-sale"> */}
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            htmlType="submit"
                                                                            style={{
                                                                                background: "#1DAFED",
                                                                                borderColor: "#1DAFED"
                                                                            }}
                                                                        ><p style={{ color: '#fff' }}>Add Account</p>
                                                                        </Button>
                                                                        {/* </NavLink> */}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Space>

        </Row>

    )
}

export default AddMoneyAccount
