import {
    Button, Card, Col, Descriptions, Divider, Form, Input, Modal, Row, Space, Table, Typography
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import Moment from 'react-moment';
import { Link, Redirect } from "react-router-dom";
import axios from '../api/axios';
import Footer from '../components/layout/Footer/FooterMobile';

const SUPPLIER_URL = (business_id) => `business/${business_id}/supplier/`;
const DELIVERY_URL = (business_id, supplier_id) => `business/${business_id}/supplier/${supplier_id}/delivery/`;
// const DELIVERY_SINGLE_URL = (business_id, supplier_id, delivery_id) => `business/${business_id}/supplier/${supplier_id}/delivery/${delivery_id}`;

const Suppliers = () => {
    const token = window.localStorage.getItem("Bearer")
    const business_id = window.localStorage.getItem("business_id")
    const supplierId = window.localStorage.getItem("supplier_id")
    const { Title } = Typography
    const [suppliers, setSuppliers] = useState([])
    const [deliveries, setDeliveries] = useState([])
    const [success, setSuccess] = useState(false)
    const [isEditingSupplier, setIsEditingSupplier] = useState(false)
    const [editSuppplier, setEditSupplier] = useState(null)
    const [supplier_details, setSupplierDetails] = useState(false)
    const [supplierDeliveries, setSupplierDeliveries] = useState([])
    const [tableTitle, setTableTitle] = useState("Suppliers Data")

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_no',
            key: 'phone_no',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <div className="sales-btn">
                    <Space direction='vertical'>
                        <Button
                            type="primary"
                            shape="round"
                            onClick={() => {
                                onClickSupplier(record)
                            }} style={{
                                background: "#dcf0f9",
                                borderColor: "#dcf0f9"
                            }}
                        ><p style={{ color: '#1DAFED' }}>Edit</p>
                        </Button>
                        <Button
                            type="primary"
                            shape="round"
                            onClick={() => {
                                onClickDeliver(record)
                            }} style={{
                                background: "#dcf0f9",
                                borderColor: "#dcf0f9"
                            }}
                        ><p style={{ color: '#1DAFED' }}>Deliver</p>
                        </Button>
                    </Space>
                </div>
            ),
        }
    ]
    const web_columns = [
        {
            title: (<p className="table-titles">Name</p>),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (<p className="table-titles">Phone Number</p>),
            dataIndex: 'phone_no',
            key: 'phone_no',
        },
        {
            title: (<p className="table-titles">Email</p>),
            dataIndex: 'email',
            key: 'phone_email',
        },
        {
            title: (<p className="table-titles">Action</p>),
            key: 'action',
            render: (record) => (
                <div className="sales-btn">
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickSupplier(record)
                        }} style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>Edit</p>
                    </Button>
                    <Divider type='vertical' />
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickDeliver(record)
                        }} style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>Deliver</p>
                    </Button>
                    <Divider type='vertical' />
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickDeliverRecord(record)
                        }} style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>View Deliveries</p>
                    </Button>
                </div>
            ),
        }
    ]
    const deliveries_columns = [
        {
            title: (<p className="table-titles">Date</p>),
            dataIndex: 'delivery_date',
            key: 'delivery_date',
            render: delivery_date => {
                var dateLocal = new Date(delivery_date);
                var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
                return <Moment format='MMMM Do YYYY, h:mm:ss a'>{newDate}</Moment>
            }
        },
        {
            title: (<p className="table-titles">Action</p>),
            key: 'action',
            render: (record) => (
                <div className="sales-btn">
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickDelivery(record)
                        }}
                        style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>View</p>
                    </Button>
                </div>
            ),
        }
    ]
    const single_delivery_columns = [
        {
            title: (<p className="table-titles">Product</p>),
            dataIndex: 'product',
            render: product => `${product?.name}`,
        },
        {
            title: (<p className="table-titles">Unit Count</p>),
            dataIndex: 'unit_count',
            key: 'unit_count'
        },
        {
            title: (<p className="table-titles">Cost Per Unit</p>),
            dataIndex: 'cost_per_unit',
            key: 'cost_per_unit'
        }
    ]
    const onClickDelivery = (record) => {
        Modal.info({
            title: "Supply Details",
            width: 500,
            content: <>
                <Table dataSource={record?.details} columns={single_delivery_columns} pagination={false} />
            </>
        })
    }
    const onClickDeliverRecord = (record) => {
        setTableTitle(`${record?.name}'s Delivery List`)
        window.localStorage.setItem("supplier_id", record.supplier_id)
        const fetchSupplierDeliveries = async () => {
            try {
                const response = await axios.get(DELIVERY_URL(business_id, record.supplier_id), {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id,
                    //     "supplier_id": record.supplier_id
                    // }
                })
                setSupplierDeliveries(response?.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSupplierDeliveries()
        setSupplierDetails(true)
    }

    const onClickDeliver = (record) => {
        Modal.confirm({
            title: "Create Delivery",
            okText: "Yes",
            okType: "danger",
            onOk: async () => {
                window.localStorage.setItem("supplier_id", record.supplier_id)
                makeDelivery(record.supplier_id)
            }
        })
    }
    const onClickSupplier = (record) => {
        setIsEditingSupplier(true)
        setEditSupplier({ ...record })
    }

    const resetEditing = () => {
        setIsEditingSupplier(false)
        setEditSupplier(null)
    }
    const makeDelivery = useCallback(async (supplier_id) => {
        try {
            const response = await axios.post(DELIVERY_URL(business_id, supplier_id),
                null,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            const delivery_id = response?.data?.delivery_id
            window.localStorage.setItem("delivery_id", delivery_id)
            setSuccess(true)
        } catch (err) {
            console.log(err)
        }
    }, [token, business_id])
    const fetchDeliveryData = useCallback(async () => {
        const supplier_id = window.localStorage.getItem("supplier_id")

        try {
            const response = await axios.get(DELIVERY_URL(business_id, supplier_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setDeliveries(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id])
    const fetchSupplierData = useCallback(async () => {
        try {
            const response = await axios.get(SUPPLIER_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setSuppliers(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id])
    const patchSupplierData = async (data) => {
        try {
            await axios.patch(`${SUPPLIER_URL(business_id)}${data?.supplier_id}`,
                JSON.stringify({ name: data?.name, email: data?.email, phone_no: data?.phone_no }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // }
                })
            fetchSupplierData()
        } catch (error) {
            console.log(error)
        }
    }
    const onClickAddDelivery = () => {
        Modal.confirm({
            title: "Create Delivery",
            okText: "Yes",
            okType: "danger",
            onOk: async () => {
                makeDelivery(supplierId)
            }
        })

    }
    useEffect(() => {
        fetchDeliveryData().catch(console.error)
        fetchSupplierData().catch(console.error)
    }, [fetchDeliveryData, fetchSupplierData])

    if (success) {
        return <Redirect to="/add-product-delivery" />
    }

    if (!token) {
        return <Redirect to="/" />
    }
    if (!business_id) {
        return <Redirect to="/businesses" />
    }

    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                {suppliers.length === 0 &&
                    <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                        <Row align="middle" gutter={[24, 0]} span={24}>
                            <Col span={14}>
                                <h6><b>You do not have any Suppliers</b></h6>
                            </Col>
                            <Col span={10}>
                                <div className="log-btn">
                                    <Link to="/add-supplier">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                background: "#45BC5B",
                                                borderColor: "#45BC5B"
                                            }}
                                        ><p style={{ color: '#fff' }}>Add Supplier</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Card>}
                {suppliers.length > 0 &&
                    <Row>
                        <Col
                            style={{ padding: 12 }}
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}
                            md={{ span: 24 }}
                        >
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]} span={24}>
                                        <Col span={7}>
                                            <Title level={3}>
                                                {suppliers.length}
                                            </Title>
                                            <p className="card-p">Suppliers</p>
                                        </Col>
                                        <Col span={7}>
                                            <Title level={3}>
                                                {deliveries.length}
                                            </Title>
                                            <p className="card-p">Deliveries</p>
                                        </Col>
                                        <Col span={10}>
                                            <div className="log-btn">
                                                <Link to="/add-supplier">
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        style={{
                                                            background: "#45BC5B",
                                                            borderColor: "#45BC5B"
                                                        }}
                                                    ><p style={{ color: '#fff' }}>Add Supplier</p>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col span={24} sm={24} md={24}>
                            <Card bordered={false} className="criclebox ">
                                <Table dataSource={suppliers} columns={columns} />
                            </Card>
                        </Col>
                    </Row>}
                <Footer />
            </Row>
            <Row className="dash-web-layout">
                <Col span={24}>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={20}>
                                <Title level={3}>Supplier Overview</Title>
                            </Col>
                            <Col span={4}>
                                {supplier_details ?
                                    <div className="log-btn">
                                        <Button
                                            onClick={() => {
                                                onClickAddDelivery()
                                            }}
                                            type="primary"
                                            shape="round"
                                            style={{
                                                marginLeft: "auto",
                                                background: "#ffff",
                                                borderColor: "#1DAFED"
                                            }}
                                        ><p style={{ color: '#1DAFED' }}>Add a Delivery</p>
                                        </Button> </div> :
                                    <div className="log-btn">
                                        <Link to="/add-supplier">
                                            <Button
                                                type="primary"
                                                shape="round"
                                                style={{
                                                    marginLeft: "auto",
                                                    background: "#ffff",
                                                    borderColor: "#1DAFED"
                                                }}
                                            ><p style={{ color: '#1DAFED' }}>Add Supplier</p>
                                            </Button>
                                        </Link>
                                    </div>}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Divider dashed={2} />
                {suppliers.length === 0 &&
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={24} lg={24} className="mb-24 ">
                            <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={12}>
                                            <Descriptions >
                                                <Descriptions.Item>You do not have any Suppliers recorded.<br></br>
                                                    Get started to experience automatic sychronization for your business
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Col span={12}>
                                            <Col span={24} md={16} lg={16} className="mb-24 " style={{
                                                marginBottom: "-150px",
                                                left: "100px",
                                                position: "relative"
                                            }}>
                                                <Card bordered={false} className="criclebox ">
                                                    <div className="number">
                                                        <Row align="middle" gutter={[24, 0]}>
                                                            <Col span={24}>
                                                                <Title level={5}>
                                                                    Suppliers
                                                                </Title><br></br>
                                                            </Col>
                                                            <Col span={24}>
                                                                <div className="number">
                                                                    <Row align="middle" gutter={[24, 0]}>
                                                                        <Col span={6} className="sales-record-amount">
                                                                            <Col span={6} style>
                                                                                <AiOutlineDeliveredProcedure size={30} />
                                                                            </Col>
                                                                        </Col>
                                                                        <Col span={18}>
                                                                            <h6>Make a sale and have a record of all your transactions</h6>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <div>
                                                                <br></br>
                                                            </div>
                                                            <Col xs={24} md={24} className="d-flex">
                                                                <div className="log-btn">
                                                                    <Link to="/add-supplier">
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            style={{
                                                                                background: "#1DAFED",
                                                                                borderColor: "#1DAFED"
                                                                            }}
                                                                        ><p style={{ color: '#fff' }}>Add Supplier</p>
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>

                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                }
                {suppliers.length > 0 &&
                    <Col span={24} className="">
                        <Card bordered={false} className="criclebox tablearea mb-24" title={tableTitle}
                            extra={
                                <div className="log-btn">
                                    {supplier_details &&
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                background: "#ffff",
                                                borderColor: "#1DAFED"
                                            }}
                                            onClick={() => { setSupplierDetails(false); setTableTitle("Suppliers Data"); }}
                                        >
                                            <p style={{ color: '#1DAFED' }}> Back to Supplier List</p>
                                        </Button>
                                    }
                                </div>
                            }
                        >
                            {supplier_details ?
                                <> {supplierDeliveries.length < 1 ? <Col span={24} md={24} lg={24} className="mb-24 ">
                                    <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col span={12}>
                                                    <Descriptions >
                                                        <Descriptions.Item>The supplier does not have any Deliveries recorded.<br></br>
                                                            Get started to experience automatic sychronization for your business
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col span={12}>
                                                    <Col span={24} md={16} lg={16} className="mb-24 " style={{
                                                        marginBottom: "-150px",
                                                        left: "100px",
                                                        position: "relative"
                                                    }}>
                                                        <Card bordered={false} className="criclebox ">
                                                            <div className="number">
                                                                <Row align="middle" gutter={[24, 0]}>
                                                                    <Col span={24}>
                                                                        <Title level={5}>
                                                                            Deliveries
                                                                        </Title><br></br>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <div className="number">
                                                                            <Row align="middle" gutter={[24, 0]}>
                                                                                <Col span={6} className="sales-record-amount">
                                                                                    <Col span={6} style>
                                                                                        <AiOutlineDeliveredProcedure size={30} />
                                                                                    </Col>
                                                                                </Col>
                                                                                <Col span={18}>
                                                                                    <h6>Make a Delivery and have a record of deliveries.</h6>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                    <div>
                                                                        <br></br>
                                                                    </div>
                                                                    <Col xs={24} md={24} className="d-flex">
                                                                        <div className="log-btn">
                                                                            <Button
                                                                                onClick={() => {
                                                                                    onClickAddDelivery()
                                                                                }}
                                                                                type="primary"
                                                                                shape="round"
                                                                                style={{
                                                                                    background: "#1DAFED",
                                                                                    borderColor: "#1DAFED"
                                                                                }}
                                                                            ><p style={{ color: '#fff' }}>Add a Delivery</p>
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    </Col>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col> : <Table dataSource={supplierDeliveries} columns={deliveries_columns} />}   </>
                                :
                                <Table dataSource={suppliers} columns={web_columns} />
                            }
                        </Card>
                    </Col>
                }

            </Row>

            <Modal title="Edit Supplier Information" okText="Save" visible={isEditingSupplier} onCancel={() => { resetEditing() }}
                onOk={() => {
                    setSuppliers((pre) => {
                        return pre.map((record) => {
                            if (record?.supplier_id === editSuppplier?.supplier_id) {
                                return patchSupplierData(editSuppplier)
                            } else {
                                return record
                            }
                        })
                    })
                    resetEditing()
                }}>
                <Form.Item label="Supplier Name" className="username">
                    <Input value={editSuppplier?.name} onChange={(e) => {
                        setEditSupplier(pre => {
                            return { ...pre, name: e.target.value }
                        })
                    }} />
                </Form.Item>
                <Form.Item label="Supplier Email" className="username">
                    <Input value={editSuppplier?.email} onChange={(e) => {
                        setEditSupplier(pre => {
                            return { ...pre, email: e.target.value }
                        })
                    }} />
                </Form.Item>
                <Form.Item label="Supplier Phone Number" className="username">
                    <Input value={editSuppplier?.phone_no} onChange={(e) => {
                        setEditSupplier(pre => {
                            return { ...pre, phone_no: e.target.value }
                        })
                    }} />
                </Form.Item>
            </Modal>
        </div >
    )
};

export default Suppliers;
