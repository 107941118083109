import { createContext, useState } from "react";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
    const [auth, setAuth] = useState({});
    const [auth_header, setAuthHeader] = useState({});
    const [cart, setCart] = useState([]);
    const [service_cart, setServiceCart] = useState([]);
    const [delivery_cart, setDeliveryCart] = useState([]);
    const [products, setProducts] = useState([])
    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState([])
    const [services, setServices] = useState([])
    const [transaction, setTransaction] = useState([])
    const [complete_transaction, setCompleteTransaction] = useState([])
    const [sales_data, setSalesData] = useState([])
    const [business, setBusiness] = useState({})
    const [recent_sales, setRecentSales] = useState([])
    const [expenseData, SetExpenseData] = useState([])
    const [recurringExpenseData, SetRecurringExpenseData] = useState([])



    return (
        <AuthContext.Provider value={{
            auth,
            setAuth,
            auth_header,
            setAuthHeader,
            customer,
            setCustomer,
            cart,
            setCart,
            products,
            setProducts,
            transaction,
            setTransaction,
            customers,
            setCustomers,
            complete_transaction,
            setCompleteTransaction,
            delivery_cart,
            setDeliveryCart,
            sales_data,
            setSalesData,
            business,
            setBusiness,
            recent_sales,
            setRecentSales,
            services,
            setServices, service_cart, setServiceCart,
            expenseData, SetExpenseData,
            recurringExpenseData, SetRecurringExpenseData
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
