import {
    Col, Row
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineShopping } from "react-icons/ai";
import axios from '../api/axios';
const ONE_TRANSACTION_URL = (business_id) => `/business/${business_id}/transaction/`;
const business_id = window.localStorage.getItem("business_id")
const transaction_id = window.localStorage.getItem("transaction_id")
const PaymentRow = () => {
    const [transDetails, setTransDetails] = useState({})
    const [product_data, setProductData] = useState([])
    const [service_data, setServiceData] = useState([])
    const token = window.localStorage.getItem("Bearer");

    useEffect(() => {
        if ((business_id && transaction_id)) {
            const fetchBusinessData = async () => {
                try {
                    const response = await axios.get(`${ONE_TRANSACTION_URL(business_id)}${transaction_id}`, {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        withCredentials: true,
                        // params: {
                        //     "business_id": business_id
                        // }
                    })
                    setTransDetails(response?.data.transaction)
                    window.localStorage.setItem("total", transDetails?.total_cost)
                    setProductData(response?.data.products)
                    setServiceData(response?.data.services)
                } catch (error) {
                    console.log(error)
                }
            }
            fetchBusinessData()
        }
    }, [business_id, transaction_id]);
    return (
        <Row style={{ backgroundColor: "white", marginLeft: 2, marginRight: 2 }}>
            <Col span={7}>
                <Col span={24} className="sales-record-amount ">
                    <div style={{ color: "gray", fontWeight: "bolder", fontSize: 15, letterSpacing: 1, marginTop: 5, marginLeft: 10 }}>
                        Totals
                    </div>
                </Col>
            </Col>
            <Col span={1}><div style={{ height: "100%", width: 0.8, backgroundColor: "#dbd0d0" }}></div></Col>

            <Col span={7}>
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col span={6}>
                            <AiOutlineShopping size={30} color="#45BC5B" />
                        </Col>
                        <Col span={18} className="sales-record-amount ">
                            <div style={{ color: "#45BC5B", fontWeight: "bolder", fontSize: 15, letterSpacing: 1 }}>

                                {product_data.length + service_data.length} items
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col span={1}><div style={{ height: "100%", width: 0.8, backgroundColor: "#dbd0d0", marginLeft: 10 }}></div></Col>

            <Col span={8}>
                <Col span={24} className="sales-record-amount ">
                    <div style={{ color: "#45BC5B", fontWeight: "bolder", fontSize: 15, letterSpacing: 1, marginTop: 5 }}>
                        kes {transDetails.total_cost}.00
                        {localStorage.setItem('total', transDetails.total_cost)}
                    </div>
                </Col>

            </Col>
        </Row>
    )
}

export default PaymentRow
