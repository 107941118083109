import {
    Button, Card, Col, Descriptions, Divider, Form, Input, Modal, Row, Table, Typography
} from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AiOutlineCustomerService } from "react-icons/ai";
import { Link, Redirect } from "react-router-dom";
import axios from '../api/axios';
import Footer from '../components/layout/Footer/FooterMobile';
import AuthContext from "../context/AuthProvider";
const SERVICE_URL = (business_id) => `business/${business_id}/service/`;
const CATEGORY_URL = (business_id) => `business/${business_id}/category/`;

const Services = () => {
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    const { Title } = Typography
    const { services, setServices } = useContext(AuthContext)
    const [categories, setCategories] = useState([])
    const [isEditingSrv, setIsEditingSrv] = useState(false)
    const [editService, setEditService] = useState(null)

    const onClickService = (record) => {
        setIsEditingSrv(true)
        setEditService({ ...record })
    }
    const resetEditing = () => {
        setIsEditingSrv(false)
        setEditService(null)
    }
    const columns = [
        {
            title: (<p className="table-titles">Name</p>),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (<p className="table-titles">Price'</p>),
            dataIndex: 'price',
            key: 'price',
            sorter: (a, b) => a.price - b.price
        },
        {
            title: (<p className="table-titles">Action</p>),
            key: 'action',
            render: (record) => (
                <div className="sales-btn">
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickService(record)
                        }} style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>Edit</p>
                    </Button>
                </div>
            ),
        }
    ];

    const PatchServiceData = async (data) => {
        try {
            await axios.patch(`${SERVICE_URL(business_id)}${data?.service_id}`,
                JSON.stringify({ name: data?.name, price: data?.price }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                })
            fetchServiceData()
        } catch (error) {
            console.log(error)
        }
    }
    const fetchServiceData = useCallback(async () => {
        try {
            const response = await axios.get(SERVICE_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            })
            setServices(response.data.reverse())
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id, setServices])
    const fetchCategoryData = useCallback(async () => {
        try {
            const response = await axios.get(CATEGORY_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            })
            setCategories(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id])
    useEffect(() => {
        fetchServiceData().catch(console.error)
        fetchCategoryData().catch(console.error)
    }, [fetchServiceData, fetchCategoryData])

    if (!token) {
        return <Redirect to="/" />
    }
    if (!business_id) {
        return <Redirect to="/businesses" />
    }

    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                {services.length === 0 &&
                    <Card bordered={false} className="criclebox " style={{ background: "#fce8e8" }}>
                        <Row align="middle" gutter={[24, 0]} span={24}>
                            <Col span={14}>
                                <h6><b>You do not have any Services</b></h6>
                            </Col>
                            <Col span={10}>
                                <div className="log-btn">
                                    <Link to="/add-service">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                background: "#45BC5B",
                                                borderColor: "#45BC5B"
                                            }}
                                        ><p style={{ color: '#fff' }}>Add Service</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Card>}
                {services.length > 0 &&
                    <Row>
                        <Col style={{ padding: 12 }} xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 24 }}>
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]} span={24}>
                                        <Col span={8}>
                                            <Title level={3}>
                                                {services.length}
                                            </Title>
                                            <p className="card-p">Servises</p>
                                        </Col>
                                        <Col span={8}>
                                            <Title level={3}>
                                                {categories.length}
                                            </Title>
                                            <p className="card-p">Categories</p>
                                        </Col>
                                        <Col span={8}>
                                            <div className="log-btn">
                                                <Link to="/add-service">
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        style={{
                                                            background: "#45BC5B",
                                                            borderColor: "#45BC5B"
                                                        }}
                                                    ><p style={{ color: '#fff' }}>Add Service</p>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col span={22} offset={1}>
                            <Card bordered={false} className="criclebox ">
                                <Table dataSource={services} columns={columns} />
                            </Card>
                        </Col>
                    </Row>}
                <Footer />
            </Row>

            <Row className="dash-web-layout">
                <Col span={24} >
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={18}>
                                <Title level={3}>Service Overview</Title>
                            </Col>
                            <Col span={4} offset={1}>
                                <div className="log-btn">
                                    <Link to="/add-service">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                marginLeft: "100px",
                                                background: "#ffff",
                                                borderColor: "#1DAFED"
                                            }}
                                        ><p style={{ color: '#1DAFED' }}>Add Service</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Divider dashed="true" />
                {services.length === 0 &&
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={24} lg={24} className="mb-24 ">
                            <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={12}>
                                            <Descriptions >
                                                <Descriptions.Item>You do not have any Services recorded.<br></br>
                                                    Get started to experience automatic sychronization for your business
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Col span={12}>
                                            <Col span={24} md={16} lg={16} className="mb-24 " style={{
                                                marginBottom: "-150px",
                                                left: "100px",
                                                position: "relative"
                                            }}>
                                                <Card bordered={false} className="criclebox ">
                                                    <div className="number">
                                                        <Row align="middle" gutter={[24, 0]}>
                                                            <Col span={24}>
                                                                <Title level={5}>
                                                                    Services
                                                                </Title><br></br>
                                                            </Col>
                                                            <Col span={24}>
                                                                <div className="number">
                                                                    <Row align="middle" gutter={[24, 0]}>
                                                                        <Col span={6} className="sales-record-amount">
                                                                            <Col span={6} style>
                                                                                <AiOutlineCustomerService size={30} />
                                                                            </Col>
                                                                        </Col>
                                                                        <Col span={18}>
                                                                            <h6>Add services that are related to your business.</h6>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <div>
                                                                <br></br>
                                                            </div>
                                                            <Col xs={24} md={24} className="d-flex">
                                                                <div className="log-btn">
                                                                    <Link to="/add-service">
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            style={{
                                                                                background: "#1DAFED",
                                                                                borderColor: "#1DAFED"
                                                                            }}
                                                                        ><p style={{ color: '#fff' }}>Add Service</p>
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>

                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                }
                {services.length > 0 &&
                    <Col span={24} >
                        <Card bordered={false} className="criclebox " >
                            <Table dataSource={services} columns={columns} span={24} />
                        </Card>
                    </Col>
                }

            </Row>

            <Modal title="Edit Product" okText="Save" visible={isEditingSrv} onCancel={() => { resetEditing() }}
                onOk={() => {
                    setServices((pre) => {
                        return pre.map((record) => {
                            if (record.service_id === editService.service_id) {
                                return PatchServiceData(editService)
                            } else {
                                return record
                            }
                        })
                    })
                    resetEditing()
                }}>
                <Form.Item label="Service Name" className="username">
                    <Input value={editService?.name} onChange={(e) => {
                        setEditService(pre => {
                            return { ...pre, name: e.target.value }
                        })
                    }} />
                </Form.Item>
                <Form.Item label="Service Price" className="username">
                    <Input value={editService?.price} onChange={(e) => {
                        setEditService(pre => {
                            return { ...pre, price: e.target.value }
                        })
                    }} />
                </Form.Item>
            </Modal>
        </div >
    )
};

export default Services;
