import { Typography } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from '../../api/axios';
const CUSTOMER_DATA_URL = (business_id) => `business/${business_id}/data/customers/`;

const CustomerBar = () => {
    const [totals, setTotals] = useState([])
    const { Title } = Typography

    useEffect(() => {
        const token = window.localStorage.getItem("Bearer");
        const business_id = window.localStorage.getItem("business_id");

        const fetchCustomersData = async () => {
            try {
                const response = await axios.get(CUSTOMER_DATA_URL(business_id), {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // }
                });
                setTotals(response?.data.data)
            }
            catch {
                console.log("Error");
            }
        };
        fetchCustomersData().catch(console.error)
    }, [])
    const eChart = {
        series: [{
            name: 'Customers',
            data: totals?.map((item) => item.customers)
        }],
        options: {
            annotations: {
                points: [{
                    x: '',
                    seriesIndex: 0,
                    label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                            color: '#fff',
                            background: '#775DD0',
                        },
                        text: '',
                    }
                }]
            },
            chart: {
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2
            },

            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    show: false
                },
                categories: totals?.map((item) => item.date),
                tickPlacement: 'on'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100]
                },
            }
        },
    }
    return (
        <div id="chart">

            <div>
                <Title level={5} style={{ color: "#D89546" }}>Customer Base</Title>
            </div>
            <ReactApexChart
                // className="bar-chart"
                options={eChart.options}
                series={eChart.series}
                type="bar"
                height={250}
            />
        </div>
    )
}

export default CustomerBar
