import {
  Typography,
  Row,
  Col,
  Card,
  Divider,
} from "antd"

const Insights = () => {
  const { Title } = Typography

  return (
    <div className="layout-content">
      <Row className="dash-web-layout">
        <Col span={24}>
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={24}>
                <Title level={3}> Sales Insights</Title>
              </Col>
            </Row>
          </div>
        </Col>
        <Divider dashed="true" />
        <Col span={24} md={24} lg={24} className="mb-24 ">
          <Card bordered={false} className="criclebox " style={{ background: "#f4fbfe", height: "300px" }}>
            <Row>
              <Col span={8} offset={10} style={{ paddingTop: "70px" }}>
                <Title>Coming Soon..</Title>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Insights