import {
    Input,
    Form,
    DatePicker,
    Card,
    Select,
} from 'antd'
const { Option } = Select


const EditExpense = ({ setEditExpense, editExpense }) => {

    return (
        <Card className="criclebox ">
            <Form>
                <Form.Item className="username" label="Expense Description">
                    <Input value={editExpense?.description}
                        onChange={(e) => {
                            setEditExpense(pre => {
                                return { ...pre, description: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item className="username" label="Expense Type">
                    <Select
                        placeholder={editExpense?.expense_type}
                        onChange={(value) => {
                            setEditExpense(pre => {
                                return { ...pre, expense_type: value }
                            })
                        }}
                        allowClear
                    >
                        <Option value="operating">Operating</Option>
                        <Option value="payroll">Payroll</Option>
                        <Option value="cog">Cog</Option>
                    </Select>
                </Form.Item>
                <Form.Item className="username" label="Expense Amount">
                    <Input value={editExpense?.expense_amount}
                        onChange={(e) => {
                            setEditExpense(pre => {
                                return { ...pre, expense_amount: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item className="username" label="Expense Date">
                    <DatePicker style={{ width: "100%" }} placeholder={editExpense?.expense_date}
                        onChange={(date, dateString) => {
                            setEditExpense(pre => {
                                return { ...pre, expense_date: dateString }
                            })
                        }} />
                </Form.Item>
            </Form>
        </Card>
    )
}

export default EditExpense