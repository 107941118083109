import {
    Button, Card, Col, Descriptions, Divider, Form, Input, Modal, Row, Table, Typography
} from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AiOutlineBarChart } from "react-icons/ai";
import { Link, Redirect } from "react-router-dom";
import axios from '../api/axios';
import Footer from '../components/layout/Footer/FooterMobile';
import AuthContext from "../context/AuthProvider";
const PRODUCTS_URL = (business_id) => `/business/${business_id}/product/`;
const CATEGORY_URL = (business_id) => `business/${business_id}/category/`;

const Products = () => {
    const { Title } = Typography;
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    const { products, setProducts } = useContext(AuthContext)
    const [categories, setCategories] = useState([])
    const [isEditingPdt, setIsEditingPdt] = useState(false)
    const [editProduct, setEditProduct] = useState(null)

    const columns = [
        {
            title: (<p className="table-titles">Name</p>),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: (<p className="table-titles">Available</p>),
            dataIndex: 'units_available',
            key: 'units_available',
            sorter: (a, b) => a.units_available - b.units_available,
        },
        {
            title: (<p className="table-titles">Action</p>),
            key: 'action',
            fixed: 'right',
            render: (record) => (
                <div className="sales-btn">
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickProduct(record)
                        }} style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>Edit</p>
                    </Button>
                </div>
            ),
        }
    ];
    const web_columns = [
        {
            title: (<p className="table-titles">Name</p>),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (<p className="table-titles">Price</p>),
            dataIndex: 'price',
            key: 'price',
            sorter: (a, b) => a.price - b.price
        },
        {
            title: (<p className="table-titles">Units Available</p>),
            dataIndex: 'units_available',
            key: 'units_available',
            sorter: (a, b) => a.units_available - b.units_available,
        },
        {
            title: (<p className="table-titles">Action</p>),
            key: 'action',
            render: (record) => (
                <div className="sales-btn">
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickProduct(record)
                        }} style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>Edit</p>
                    </Button>
                </div>
            ),
        }
    ];

    const onClickProduct = (record) => {
        setIsEditingPdt(true)
        setEditProduct({ ...record })
    }

    const resetEditing = () => {
        setIsEditingPdt(false)
        setEditProduct(null)
    }
    const PatchProductData = async (data) => {
        try {
            await axios.patch(`${PRODUCTS_URL(business_id)}${data?.product_id}`,
                JSON.stringify({ name: data?.name, price: data?.price, units_available: data?.units_available }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // }
                })
            fetchProductData()
        } catch (error) {
            console.log(error)
        }
    }
    const fetchProductData = useCallback(async () => {
        try {
            const response = await axios.get(PRODUCTS_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setProducts(response.data.reverse())
            console.log(response.data, 'product');

        } catch (error) {
            console.log(error)
        }
    }, [token, business_id, setProducts])
    const fetchCategoryData = useCallback(async () => {
        try {
            const response = await axios.get(CATEGORY_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setCategories(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id])
    useEffect(() => {
        fetchProductData().catch(console.error)
        fetchCategoryData().catch(console.error)
    }, [fetchCategoryData, fetchProductData])

    if (!token) {
        return <Redirect to="/" />
    }
    if (!business_id) {
        return <Redirect to="/businesses" />
    }

    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                {products.length === 0 &&
                    <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                        <Row align="middle" gutter={[24, 0]} span={24}>
                            <Col span={14}>
                                <h6><b>You do not have any Products</b></h6>
                            </Col>
                            <Col span={10}>
                                <div className="log-btn">
                                    <Link to="/add-product">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                background: "#45BC5B",
                                                borderColor: "#45BC5B"
                                            }}
                                        ><p style={{ color: '#fff' }}>Add Product</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Card>}
                {products.length > 0 &&
                    <>
                        <Col
                            className=""
                            style={{ padding: 12 }}
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}
                            md={{ span: 24 }}
                        >
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]} span={24}>
                                        <Col span={7}>
                                            <Title level={3}>
                                                {products.length}
                                            </Title>
                                            <p className="card-p">products</p>
                                        </Col>
                                        <Col span={7}>
                                            <Title level={3}>
                                                {categories.length}
                                            </Title>
                                            <p className="card-p">Categories</p>
                                        </Col>
                                        <Col span={10}>
                                            <div className="log-btn">
                                                <Link to="/add-product">
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        style={{
                                                            background: "#45BC5B",
                                                            borderColor: "#45BC5B"
                                                        }}
                                                    ><p style={{ color: '#fff' }}>Add Product</p>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col span={24} style={{ padding: 12 }}>
                            <Card bordered={false} className="criclebox ">
                                <Table dataSource={products.reverse()} columns={columns}
                                    pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['10', '15'] }} />
                            </Card>
                        </Col>
                    </>}
                <Footer />
            </Row>

            <Row className="dash-web-layout">
                <Col span={24}>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={20}>
                                <Title level={3}>Products Overview</Title>
                            </Col>
                            <Col span={4}>
                                <div className="log-btn">
                                    <Link to="/add-product">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                marginLeft: "auto",
                                                background: "#ffff",
                                                borderColor: "#1DAFED"
                                            }}
                                        ><p style={{ color: '#1DAFED' }}>Add Product</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Divider dashed="true" />
                {products.length === 0 &&
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={24} lg={24} className="mb-24 " >
                            <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={12}>
                                            <Descriptions >
                                                <Descriptions.Item>You do not have any Products recorded.<br></br>
                                                    Get started to experience automatic sychronization for your business
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Col span={12}>
                                            <Col span={24} md={16} lg={16} className="mb-24 " style={{
                                                marginBottom: "-150px",
                                                left: "100px",
                                                position: "relative"
                                            }}>
                                                <Card bordered={false} className="criclebox ">
                                                    <div className="number">
                                                        <Row align="middle" gutter={[24, 0]}>
                                                            <Col span={24}>
                                                                <Title level={5}>
                                                                    Products
                                                                </Title><br></br>
                                                            </Col>
                                                            <Col span={24}>
                                                                <div className="number">
                                                                    <Row align="middle" gutter={[24, 0]}>
                                                                        <Col span={6} className="sales-record-amount">
                                                                            <Col span={6} style>
                                                                                <AiOutlineBarChart size={30} />
                                                                            </Col>
                                                                        </Col>
                                                                        <Col span={18}>
                                                                            <h6>Add products that are related to your business.</h6>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <div>
                                                                <br></br>
                                                            </div>
                                                            <Col xs={24} md={24} className="d-flex">
                                                                <div className="log-btn">
                                                                    <Link to="/add-product">
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            style={{
                                                                                background: "#1DAFED",
                                                                                borderColor: "#1DAFED"
                                                                            }}
                                                                        ><p style={{ color: '#fff' }}>Add Product</p>
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>

                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                }
                {products.length > 0 &&
                    <Col span={24}>
                        <Card bordered={false} className="criclebox" >
                            <Table dataSource={products} columns={web_columns} />
                        </Card>
                    </Col>
                }
            </Row >

            <Modal title="Edit Product" okText="Save" visible={isEditingPdt} onCancel={() => { resetEditing() }}
                onOk={() => {
                    setProducts((pre) => {
                        return pre.map((record) => {
                            if (record.product_id === editProduct.product_id) {
                                return PatchProductData(editProduct)
                            } else {
                                return record
                            }
                        })
                    })
                    resetEditing()
                }}>
                <Form.Item label="Product Name" className="username">
                    <Input value={editProduct?.name} onChange={(e) => {
                        setEditProduct(pre => {
                            return { ...pre, name: e.target.value }
                        })
                    }} />
                </Form.Item>
                <Form.Item label="Product Price" className="username">
                    <Input value={editProduct?.price} onChange={(e) => {
                        setEditProduct(pre => {
                            return { ...pre, price: e.target.value }
                        })
                    }} />
                </Form.Item>
                <Form.Item label="Units Available" className="username">
                    <Input value={editProduct?.units_available} onChange={(e) => {
                        setEditProduct(pre => {
                            return { ...pre, units_available: e.target.value }
                        })
                    }} />
                </Form.Item>
            </Modal>
        </div >
    )
};

export default Products;
