import { Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";

const CashAmountInput = ({ errMsg, errRef }) => {
  const [amount_paid, setAmountPaid] = useState("");
  const [total, setTotal] = useState(localStorage.getItem("total") || "");
  let init_change = 0;
  const [change, setChange] = useState(init_change);


  useEffect(() => {
    localStorage.setItem("total", total);
  }, [total]);


  const onChangeAmount = (e) => {
    const value = e.target.value;
    const total = localStorage.getItem("total");
    const new_change = parseFloat(value) - parseFloat(total);
    setAmountPaid(value);
    if (new_change > 0) {
      localStorage.setItem("amount_paid", value);
      setChange(new_change);
    } else {
      localStorage.setItem("amount_paid", 0);
      setChange(0);
    }
  };

  const onChangeTotal = (e) => {
    const value = e.target.value;
    setTotal(value);
    localStorage.setItem("total", value);
  };
  return (
    <Row>
      <Col span={20} className="amount-input">
        <Form>
          <span className="input-placeholder">
            Enter amount received<span style={{ color: "red" }}>*</span>
          </span>
          <p ref={errRef} className="Error-p">
            {errMsg}
          </p>
          <Form.Item
            className="username"
            name="email"
            id="email"
            onChange={onChangeAmount}
            value={amount_paid}
            rules={[
              {
                required: false,
                message: "Please input Amount!",
              },
            ]}
          >
            <Input
              style={{
                backgroundColor: "#ffffff",
                height: 60,
                border: "none",
                fontFamily: "inherit",
              }}
            />
          </Form.Item>
        </Form>
        <div
          style={{
            height: 1,
            backgroundColor: "#e6e6e6",
            float: "right",
            width: "100%",
            marginTop: 5,
          }}
        ></div>
        <br />
        <Form>
          <span className="input-placeholder">Change</span>

          <Form.Item>
            <Input
              readOnly
              style={{ height: 60, border: "none", fontFamily: "inherit" }}
              value={change}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default CashAmountInput;
