import "antd/dist/antd.css";
import React from "react";
import {
  Route, Switch
} from 'react-router-dom';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AddSale from "./components/AddSale";
import Main from "./components/layout/Main";
import Payment from "./pages/Payment";
import PaymentCheckCart from "./pages/paymentcheckcart";

import AddBusiness from "./components/AddBusiness";
import AddCategory from "./components/AddCategory";
import AddCustomer from "./components/AddCustomer";
import AddExpense from "./components/AddExpense";
import AddInventory from "./components/AddInventory";
import AddMoneyAccount from "./components/AddMoneyAccount";
import AddProduct from "./components/AddProduct";
import AddProductDelivery from "./components/AddProductDelivery";
import AddService from "./components/AddService";
import AddSupplier from "./components/AddSupplier";
import SuccessfulPayment from "./components/SuccessfulPayment";
import { CartProvider } from './context/CartProvider';
import Businesses from './pages/Businesses';
import Customer from './pages/Customer';
import Dashboard from './pages/Dashboard';
import Discount from './pages/Discount';
import Expense from './pages/Expense';
import Insights from './pages/Insights';
import Inventory from './pages/Inventory';
import MoneyAccounts from './pages/MoneyAccounts';
import Products from './pages/Products';
import Sales from './pages/Sales';
import Services from './pages/Services';
import Settings from './pages/Settings';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Suppliers from './pages/Suppliers';
import Withdrawal from './pages/Withdrawal';

function App() {
  return (
    <>
      <Switch >
        <Route path="/" exact component={SignIn} />
        <Route path="/sign-up" exact component={SignUp} />
        <CartProvider>
          <Main>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route path="/add-business" component={AddBusiness} />
            <Route path="/add-product" component={AddProduct} />
            <Route path="/add-service" component={AddService} />
            <Route path="/add-customer" component={AddCustomer} />
            <Route path="/add-category" component={AddCategory} />
            <Route path="/add-expense" component={AddExpense} />
            <Route path="/add-account" component={AddMoneyAccount} />
            <Route path="/add-invetory" component={AddInventory} />
            <Route path="/add-supplier" component={AddSupplier} />
            <Route path="/add-product-delivery" component={AddProductDelivery} />
            <Route path="/add-sale" component={AddSale} />
            <Route exact path="/sales" component={Sales} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/customer" component={Customer} />
            <Route exact path="/inventory" component={Inventory} />
            <Route exact path="/businesses" component={Businesses} />
            <Route exact path="/accounts" component={MoneyAccounts} />
            <Route exact path="/suppliers" component={Suppliers} />
            <Route exact path="/discount" component={Discount} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/expense" component={Expense} />
            <Route exact path="/insights" component={Insights} />
            <Route exact path="/Payment" component={Payment} />
            <Route exact path="/paymentcheckcart" component={PaymentCheckCart} />
            <Route exact path="/withdrawal" component={Withdrawal} />

            <Route path="/completed" component={SuccessfulPayment} />
          </Main>
        </CartProvider>
      </Switch >
    </>
  );
}

export default App;
