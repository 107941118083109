import {
    Avatar, Button, Card, Col, Form,
    Input, Row, Space, Typography
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { RiNumber1 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from '../api/axios';
const SUPPLIER_URL = (business_id) => `business/${business_id}/supplier/`;

const { Title } = Typography;

const AddSupplier = () => {
    const errRef = useRef(null);
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")


    const [phone_no, setPhoneNo] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        setErrMsg('');
    }, [name, phone_no, email])

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    }

    const onChangeName = (e) => {
        const name = e.target.value;
        setName(name);
    };
    const onChangePhoneNo = (e) => {
        const phone_no = e.target.value;
        setPhoneNo(phone_no);
    };

    const handleSubmit = async () => {
        console.warn(name, phone_no, email)

        try {
            await axios.post(SUPPLIER_URL(business_id),
                JSON.stringify({ name, phone_no, email }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            setSuccess(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add Supplier');
            }
            errRef.current.focus();
        }
    }
    if (success) {
        return <Redirect to="/suppliers" />
    }
    return (
        <>
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                <Col xs={24} md={12} className="col-info">
                    <Card bordered={false} className="criclebox ">
                        <Title className="font-regular text-muted" level={4}>
                            Add Supplier Details here
                        </Title>
                        <p ref={errRef} className='Error-p'>
                            {errMsg}
                        </p>
                        <Form
                            onFinish={handleSubmit}
                            layout="vertical"
                            className="row-col"
                        >
                            <Form.Item
                                label={<>Supplier Name<span style={{ color: "red" }}>*</span></>}
                                className="username"
                                name="name"
                                onChange={onChangeName}
                                value={name}
                                rules={[{ required: true, message: 'Please input supplier name' }]}
                            >
                                <Input placeholder="Supplier Name" />
                            </Form.Item>
                            <Form.Item
                                className="username"
                                label={<>Supplier Email<span style={{ color: "red" }}>*</span></>}
                                name="email"
                                id="email"
                                onChange={onChangeEmail}
                                value={email}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Supplier Email!",
                                    }, { type: "email" }
                                ]}
                            >
                                <Input placeholder="Supplier Email" />
                            </Form.Item>

                            <Form.Item
                                className="username"
                                label={<>Supplier Phone Number<span style={{ color: "red" }}>*</span></>}
                                name="phone_no"
                                id="phone_no"
                                onChange={onChangePhoneNo}
                                value={phone_no}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Supplier Phone Number!",
                                    },
                                ]}
                            >
                                <Input placeholder="Supplier Phone Number" />
                            </Form.Item>
                            <Form.Item>
                                <div className="header-col sign-in-btn">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%", background: "#45BC5B" }}
                                        shape="round"
                                    >
                                        ADD
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <Row className="dash-web-layout">
                <Space direction="vertical" size='large'>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={24}>
                                <Title level={3}>Add Supplier.</Title>
                            </Col>
                        </Row>
                    </div>

                    <Row gutter={[24, 0]}>
                        <Col span={24} md={24} lg={24} className="mb-24 ">
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={24}>
                                            <Avatar shape="circle" icon={<RiNumber1 />} />
                                            <Title level={4}>Enter Details</Title>
                                        </Col>
                                        <Col span={24}>
                                            <p ref={errRef} className='Error-p'>
                                                {errMsg}
                                            </p>
                                            <Form
                                                onFinish={handleSubmit}
                                                layout="vertical"
                                                className="row-col"
                                            >
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                className="username"
                                                                label={<>Supplier Name<span style={{ color: "red" }}>*</span></>}
                                                                name="name"
                                                                id="name"
                                                                onChange={onChangeName}
                                                                value={name}
                                                                rules={[{ required: true, message: 'Please input supplier name' }]}
                                                            >
                                                                <Input placeholder="Supplier Name" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                className="username"
                                                                label={<>Supplier Email<span style={{ color: "red" }}>*</span></>}
                                                                name="email"
                                                                id="email"
                                                                onChange={onChangeEmail}
                                                                value={email}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input Supplier Email!",
                                                                    }, { type: "email" }
                                                                ]}
                                                            >
                                                                <Input placeholder="Supplier Email" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={10}>
                                                            <Form.Item
                                                                className="username"
                                                                label={<>Supplier Phone Number<span style={{ color: "red" }}>*</span></>}
                                                                name="phone_no"
                                                                id="phone_no"
                                                                onChange={onChangePhoneNo}
                                                                value={phone_no}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input Supplier Phone Number!",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Supplier Phone Number" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={10} offset={4}>
                                                            <Form.Item>
                                                                <div className="header-col sign-in-btn">
                                                                    <Button
                                                                        type="primary"
                                                                        htmlType="submit"
                                                                        style={{ width: "100%", background: "#45BC5B", borderColor: "#45BC5B" }}
                                                                        shape="round"
                                                                    >
                                                                        <p style={{ color: "#ffff" }}>ADD</p>
                                                                    </Button>
                                                                </div>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Row></>

    )
};

export default AddSupplier;
