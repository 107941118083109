import React, { useState, useContext } from 'react';
import AuthContext from "../context/AuthProvider";
import {
    Row,
    Col,
    Card,
    Button,
    Tag,
} from "antd";

const SingleProduct = ({ c, setCartDetail, cartdetail, setSearchTerm }) => {
    const [count] = useState("1")
    const [countErr] = useState("")
    const { cart, setCart } = useContext(AuthContext);

    const handleClick = () => {
        setCart([...cart, { "product_id": c.product_id, "quantity": count }])
        setCartDetail([...cartdetail, { "product_id": c.product_id, "quantity": count, "name": c.name, "price": c.price, "units_available": c.units_available }])
        setSearchTerm("")
    };

    return (
        <Card bordered={true} className="criclebox" style={{cursor:"pointer"}}>
            <div className="number">
                {/* <Row align="middle" gutter={[24, 0]} key={c.id} style={{ marginTop: "-15px", marginBottom: "-15px" }}> */}
                <Row onClick={handleClick} style={{ paddingRight: 5, paddingLeft: 5, cursor: "pointer" }}>


                    <p>{countErr}</p>
                    <Col span={4}>
                        <span style={{ backgroundColor: "#d9e8ff", color: "#000", paddingRight: 8, paddingLeft: 8,paddingTop:2,paddingBottom:2, borderRadius: 50 }}>
                            {c.units_available}

                        </span>
                    </Col>
                    <Col span={12} className="sales-record-amount" style={{letterSpacing:0.5,fontSize:15,fontWeight:"bold"}}>
                        {c.name}
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                        <span className="span-small">KES {c.price}.00</span>
                    </Col>

                    {/* <p>{countErr}</p>
                    <Col span={12}>
                        <div className="sales-btn">
                            <Tag color={c.units_available > 5 ? "blue" : "red"} shape="circle">{c.units_available}</Tag>
                        </div>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <span className="span-large">KES {c.price}.00</span>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <p style={{ marginBottom: "2px" }}> {c.name} </p>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <div className="sales-btn">
                            <Button
                                onClick={handleClick}
                                type="primary"
                                shape="round"
                                style={{
                                    background: "#f4fbfe",
                                    borderColor: "#f4fbfe"
                                }}
                                disabled={c.units_available === 0 ?true :false}
                            ><p style={{
                                color: '#1DAFED',
                            }}>Add <span style={{marginLeft:"1rem"}}>+ 1</span></p> 
                            </Button>
                        </div>
                    </Col> */}
                </Row>
            </div>
        </Card>
    )
};

export default SingleProduct;
