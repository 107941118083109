import {
  Avatar,
  Button, Card, Col, Dropdown, Menu, Row, Space, Tag, Typography
} from "antd";
import { useContext, useEffect, useState } from "react";
import {
  AiOutlineArrowRight, AiOutlineBank, AiOutlineLineChart, AiOutlineUser
} from "react-icons/ai";
import { BsCashCoin, BsStars } from "react-icons/bs";
import { FaRegLaugh, FaRegSadTear } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link, NavLink, Redirect } from "react-router-dom";
import axios from "../api/axios";
import TotalsChart from "../components/charts/TotalsChart";
import Footer from "../components/layout/Footer/FooterMobile";
import AuthContext from "../context/AuthProvider";
const USER_URL = "user/";
const GET_BUSINESS_URL = "business/";
const GET_PRODUCTS_URL = (business_id) => `business/${business_id}/product/`;
const GET_CATEGORIES_URL = (business_id) => `business/${business_id}/category/`;
const GET_DISCOUNT_URL = (business_id) => `/business/${business_id}/discount/amount/`;
const GET_DISCOUNTP_URL = (business_id) => `/business/${business_id}/discount/percent/`;
const GET_SALES_DATA_URL = (business_id) => `/business/${business_id}/data/sales/`;
const GET_SUMMARY_DATA_URL = (business_id) => `/business/${business_id}/data/summary/`;
const GET_TRANSACTIONS_URL = (business_id) => `/business/${business_id}/transaction/`;
const GET_SERVICES_URL = (business_id) => `/business/${business_id}/service/`;
const GET_CUSTOMER_URL = (business_id) => `/business/${business_id}/customer/`;

function Dashboard() {
  const { Title } = Typography;
  const { auth } = useContext(AuthContext);
  const {
    products,
    setProducts,
    setServices,
    setCustomers,
    setSalesData,
    recent_sales,
    setRecentSales,
    business,
    setBusiness,
  } = useContext(AuthContext);

  const access_token = window.localStorage.getItem("access_token");
  const auth_header = {
    "Authorization": access_token,
    "Content-Type": "application/json",
    "Accept": "application/json",
  };
  const business_id = window.localStorage.getItem("business_id");

  const [responseData, setResponseData] = useState("");
  const [businesses, setBusinesses] = useState("");
  const [categories, setCategories] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [discountP, setDiscountP] = useState([]);
  const discount_num = discount.length + discountP.length;
  const [period, setPeriod] = useState("7days");
  const [summary, setSummary] = useState();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const menu = (
    <Menu>
      <Menu.Item onClick={() => getPeriodSummary("1day")}>
        <p>1 Day</p>
      </Menu.Item>
      <Menu.Item onClick={() => getPeriodSummary("7days")}>
        <p>7 Days</p>
      </Menu.Item>
      <Menu.Item onClick={() => getPeriodSummary("30days")}>
        <p>1 Month</p>
      </Menu.Item>
    </Menu>
  );
  const getPeriodSummary = (key) => {
    setPeriod(key);
    fetchSummaryData(business_id);
  };
  const fetchTransData = async (business_id) => {
    const transaction_data = window.localStorage.getItem("transaction_data") ? JSON.parse(window.localStorage.getItem("transaction_data")) : null;
    if (transaction_data) {
      setRecentSales(transaction_data);
      fetchSummaryData(business_id);
      return transaction_data;
    } else {
      try {
        const response = await axios.get(GET_TRANSACTIONS_URL(business_id), {
          headers: auth_header,
        });
        const filteredData = response?.data;
        setRecentSales(filteredData);
        fetchSummaryData(business_id);
        window.localStorage.setItem("transaction_data", JSON.stringify(filteredData));
      } catch (error) {
        console.log(error);
      }
    }
  };
  const fetchSummaryData = async (business_id) => {
    try {
      const response = await axios.get(GET_SUMMARY_DATA_URL(business_id), {
        headers: auth_header
      });
      setSummary(response?.data);
    } catch {
      console.log("Error");
    }
  };
  const fetchSalesData = async (business_id) => {
    try {
      const response = await axios.get(GET_SALES_DATA_URL(business_id), {
        headers: auth_header,
      });
      setSalesData(response?.data);
    } catch {
      console.log("Error");
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(USER_URL, {
        headers: auth_header,
      });
      setResponseData(response.data);
    } catch {
      console.log("Error");
    }
  };
  const fetchBusinesses = async () => {
    try {
      const response = await axios.get(GET_BUSINESS_URL, {
        headers: auth_header,
        withCredentials: true,
      });
      setBusinesses(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchProductData = async (business_id) => {
    try {
      const response = await axios.get(GET_PRODUCTS_URL(business_id), {
        headers: auth_header,
      });
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategoryData = async (business_id) => {
    try {
      const response = await axios.get(GET_CATEGORIES_URL(business_id), {
        headers: auth_header,
      });
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDiscountData = async (business_id) => {
    try {
      const response = await axios.get(GET_DISCOUNT_URL(business_id), {
        headers: auth_header,
      });
      setDiscount(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDiscountPData = async (business_id) => {
    try {
      const response = await axios.get(GET_DISCOUNTP_URL(business_id), {
        headers: auth_header,
      });
      setDiscountP(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchServiceData = async (business_id) => {
    try {
      const response = await axios.get(GET_SERVICES_URL(business_id), {
        headers: auth_header,
      });
      setServices(response.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCustomerData = async (business_id) => {
    try {
      const response = await axios.get(GET_CUSTOMER_URL(business_id), {
        headers: auth_header,
      });
      setCustomers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (business_id) {
      fetchSummaryData(business_id).catch(console.error);
      fetchSalesData(business_id).catch(console.error);
      fetchData(business_id).catch(console.error);
      fetchBusinesses(business_id).catch(console.error);
      fetchServiceData(business_id).catch(console.error);
      fetchCustomerData(business_id).catch(console.error);
      fetchCategoryData(business_id).catch(console.error);
      fetchDiscountData(business_id).catch(console.error);
      fetchDiscountPData(business_id).catch(console.error);
      fetchProductData(business_id).catch(console.error);
      fetchTransData(business_id).catch(console.error);
    }
  }, [business_id]);

  if (!access_token) {
    return <Redirect to="/" />;
  }
  if (!business_id) {
    return <Redirect to="/businesses" />;
  }
  return (
    <div className="layout-content">
      <Row gutter={[24, 0]} className="dash-mobile-layout">
        <Col xs={24} sm={24} md={24} className="col-info">
          <Avatar.Group>
            <Avatar size={45} shape="circle" icon={<AiOutlineUser />} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div className="avatar-info">
              <h4 className="font-semibold m-0">
                {responseData.first_name} &nbsp;{responseData.last_name}
              </h4>
            </div>
          </Avatar.Group>
          {businesses.length === 0 && (
            <Card
              bordered={false}
              className="criclebox "
              style={{ background: "#fef5f5" }}
            >
              <Row align="middle" gutter={[24, 0]} span={24}>
                <Col span={14}>
                  <h6>You do not have a business</h6>
                </Col>
                <Col span={10}>
                  <div className="log-btn">
                    <Link to="/add-business">
                      <Button
                        type="primary"
                        shape="round"
                        style={{
                          background: "#45BC5B",
                          borderColor: "#45BC5B",
                        }}
                      >
                        <p style={{ color: "#fff" }}>Add Business</p>
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
        {businesses.length > 0 && (
          <div>
            <Col
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={24}>
                      <span className="uppercase">
                        {new Date().toLocaleDateString("en-US", options)}
                      </span>
                    </Col>
                    <div>
                      <br></br>
                    </div>
                    <Col xs={24} md={24} className="d-flex">
                      <div className="log-btn">
                        <NavLink to="/add-sale">
                          <Button
                            type="primary"
                            shape="round"
                            style={{
                              background: "#45BC5B",
                              borderColor: "#45BC5B",
                            }}
                          >
                            <p style={{ color: "#fff" }}>Sell Now</p>
                          </Button>
                        </NavLink>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col span={24}>
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col span={24}>
                    <Card bordered={false} className="criclebox ">
                      <div className="number">
                        <Row align="middle" gutter={[12]}>
                          <Col span={8}>
                            <p
                              style={{ color: "#D89546" }}
                              className="uppercase"
                            >
                              <b>Sales</b>
                            </p>
                          </Col>
                          <Col span={12} offset={4}>
                            <div className="sales-btn">
                              <Dropdown overlay={menu} placement="bottomCenter">
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    background: "#dcf0f9",
                                    borderColor: "#dcf0f9",
                                  }}
                                >
                                  <p style={{ color: "#1DAFED" }}>
                                    {period} <MdOutlineKeyboardArrowDown />
                                  </p>
                                </Button>
                              </Dropdown>
                            </div>
                          </Col>
                        </Row>
                        <Row align="middle" gutter={[12]}>
                          <Col span={12}>
                            <h3>
                              {summary?.sales}
                              <span>.00</span>
                            </h3>
                          </Col>
                          <Col span={12}>
                            <span>{summary?.customers} Customers </span>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
        )}
        <Footer />
      </Row>
      <Row className="dash-web-layout">
        <Col span={24}>
          <Title level={3}> Dashboard Overview</Title>
        </Col>
        {recent_sales.length > 0 && (
          <>
            <Col span={16} className="mb-24 ">
              <Card bordered={false} className="criclebox ">
                <TotalsChart />
              </Card>
            </Col>
            <Col span={6} offset={1} className="mb-24 ">
              <Space direction="vertical" size={14}>
                <Card bordered={false} className="criclebox ">
                  <Space direction="vertical" size={30}>
                    <Title level={3} style={{ paddingTop: "25px" }}>
                      Insights <BsStars style={{ color: "#ffaa00" }} />
                    </Title>
                    <Row>
                      <Space direction="vertical" size={37}>
                        <Row>
                          <Col span={4}>
                            <FaRegLaugh
                              style={{
                                fontSize: 25,
                                color: "#faac17",
                                marginTop: "5px",
                              }}
                            />
                          </Col>
                          <Col span={20}>
                            <h6>
                              You hit hit your sales target each day this week.
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4}>
                            <FaRegLaugh
                              style={{
                                fontSize: 25,
                                color: "#faac17",
                                marginTop: "2px",
                              }}
                            />
                          </Col>
                          <Col span={20}>
                            <h6>Sales peaked on friday.</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4}>
                            <FaRegSadTear
                              style={{
                                fontSize: 25,
                                color: "#faac17",
                                marginTop: "5px",
                              }}
                            />
                          </Col>
                          <Col span={20}>
                            <h6>
                              There has been no sale on Sunday mornings for the
                              past 3 weeks.
                            </h6>
                          </Col>
                        </Row>
                        <Space />
                      </Space>
                    </Row>
                    {/* <Col span={24} xs={24} md={24}>
                                        <List
                                            className="transactions-list ant-newest"
                                            itemLayout="horizontal"
                                            dataSource={newest}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Avatar size="large" style={{ backgroundColor: "#ffff" }}>
                                                                {item.avatar}
                                                            </Avatar>
                                                        }
                                                        title={item.description}
                                                    // description={item.description}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                        </Col> */}
                    <Col
                      xs={24}
                      md={24}
                      className="d-flex"
                      style={{ paddingBottom: "25px" }}
                    >
                      <div className="log-btn">
                        <Link to="/insights">
                          <Button
                            type="primary"
                            shape="round"
                            style={{
                              background: "#dcf0f9",
                              borderColor: "#dcf0f9",
                            }}
                          >
                            <p style={{ color: "#1DAFED" }}>
                              ALL{" "}
                              <AiOutlineArrowRight
                                style={{ marginTop: "5px" }}
                              />
                            </p>
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Space>
                </Card>
              </Space>
            </Col>
          </>
        )}

        <Col span={8} md={8} lg={8} className="mb-24 ">
          <Card bordered={false} className="criclebox ">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={24}>
                  <Title level={5}>Money Account</Title>
                  <br></br>
                </Col>
                <Col span={24}>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={6} className="sales-record-amount">
                        <Avatar
                          style={{
                            background: "#FFFFFF",
                            borderColor: "#1DAFED",
                          }}
                          size={50}
                          icon={<AiOutlineBank size={30} />}
                        />
                      </Col>
                      <Col span={18}>
                        <h6>Sync with Mpesa and local banks</h6>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={24}
                  className="d-flex"
                  style={{ paddingBottom: "18px" }}
                >
                  <div className="log-btn">
                    <Link to="/accounts">
                      <Button
                        type="primary"
                        shape="round"
                        style={{
                          background: "#1DAFED",
                          borderColor: "#1DAFED",
                        }}
                      >
                        <p style={{ color: "#fff" }}>Connect Account</p>
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={7} md={7} lg={7} offset={1} className="mb-24 ">
          <Card bordered={false} className="criclebox ">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={24} style={{ paddingBottom: "48px" }}>
                  <Title level={3}>
                    <span> KES. </span>
                    {summary?.sales}
                  </Title>
                </Col>
                <Col span={12}>
                  <Title level={3} style={{ color: "#1DAFED" }}>
                    {products.length}
                    <p className="card-p" style={{ color: "#D89546" }}>
                      products
                    </p>
                  </Title>
                </Col>
                <Col span={12}>
                  <Title level={3} style={{ color: "#1DAFED" }}>
                    {categories.length}{" "}
                    <p className="card-p" style={{ color: "#D89546" }}>
                      categories
                    </p>
                  </Title>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={6} md={6} lg={6} offset={1} className="mb-24 ">
          {/* <Space direction="vertical"> */}
          <Link to="/discount">
            <Card>
              <Row align="middle" gutter={[24, 0]}>
                <Col span={6}>
                  <BsCashCoin color="#d63aab" size={12} />
                </Col>
                <Col span={10} offset={2}>
                  <p className="span-large" style={{ marginBottom: "2px" }}>
                    <b> Discount</b>
                  </p>
                </Col>
                <Col span={4}>
                  <div className="sales-btn">
                    <Tag color="#ffbb36" shape="circle">
                      <span className="discount-p">{discount_num}</span>
                    </Tag>
                  </div>
                </Col>
              </Row>
            </Card>
          </Link>
          <br />
          <Card>
            <Row>
              <Col span={24}>
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={6} className="sales-record-amount">
                      <Col span={6} style>
                        <AiOutlineLineChart size={30} />
                      </Col>
                    </Col>
                    <Col span={18}>
                      <h6>Add a sale.</h6>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={24} md={24} className="d-flex">
                <div className="log-btn">
                  <Link to="/add-sale">
                    <Button
                      type="primary"
                      shape="round"
                      style={{
                        background: "#1DAFED",
                        borderColor: "#1DAFED",
                      }}
                    >
                      <p style={{ color: "#fff" }}>Sell Now</p>
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Card>
          {/* </Space> */}
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
