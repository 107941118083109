import React, { useState, useContext } from 'react';
import AuthContext from "../context/AuthProvider";
import {
    Row,
    Col,
    Card,
    Button,
    Tag,
} from "antd";

const SingleService = ({ c, setServiceCartDetail, serviceCartdetail, setSearchTerm }) => {
    const [count] = useState("1")
    const [countErr] = useState("")
    const { service_cart, setServiceCart } = useContext(AuthContext);

    const handleClick = () => {
        setServiceCart([...service_cart, { "service_id": c.service_id, "quantity": count }])
        setServiceCartDetail([...serviceCartdetail, { "service_id": c.service_id, "quantity": count, "name": c.name, "price": c.price }])
        setSearchTerm("")
    };

    return (
        <Card bordered={true} className="criclebox">
            <div className="number">


                <Row>
                    <Col span={12}>
                        <div className="sales-btn">
                            <Tag color="blue" shape="circle">1</Tag>
                        </div>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <p style={{ marginBottom: "2px" }}> {c.name} </p>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <span className="span-large">KES {c.price}.00</span>
                    </Col>
                </Row>
                {/* <Row align="middle" gutter={[24, 0]} key={c.id}  style={{marginTop:"-15px", marginBottom:"-15px" }}>
                    <p>{countErr}</p>
                    <Col span={12}>
                        <div className="sales-btn">
                            <Tag color="blue" shape="circle">1</Tag>
                        </div>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <span className="span-large">KES {c.price}.00</span>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <p style={{ marginBottom: "2px" }}> {c.name} </p>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <div className="sales-btn">
                            <Button
                                onClick={handleClick}
                                type="primary"
                                shape="round"
                                style={{
                                    background: "#f4fbfe",
                                    borderColor: "#f4fbfe"
                                }}
                            ><p style={{
                                color: '#1DAFED',
                            }}>Add</p>
                            </Button>
                        </div>
                    </Col>
                </Row> */}
            </div>
        </Card>
    )
};

export default SingleService;
