import React from 'react'
import {
    Button,
    Row,
    Col,
    Card,
    Typography,
    Space,
    Descriptions
} from "antd";
import { AiOutlineBank } from "react-icons/ai"
import { Link } from "react-router-dom"
const { Title } = Typography;

const Inventory = () => {
    return (
        <Row className="dash-web-layout">
            <Space direction="vertical" size='large'>
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col span={20}>
                            <Title level={3}>Inventory Overview</Title>
                        </Col>
                        <Col span={4}>
                            <div className="log-btn">
                                <Link to="/add-invetory">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        style={{
                                            marginLeft: "auto",
                                            background: "#ffff",
                                            borderColor: "#1DAFED"
                                        }}
                                    ><p style={{ color: '#1DAFED' }}>Add Inventory</p>
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row gutter={[24, 0]}>
                    <Col span={24} md={24} lg={24} className="mb-24 ">
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={12}>
                                        <Descriptions >
                                            <Descriptions.Item>You haven't added my products or supplies yet.
                                                get states to enable sales.
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col span={12}>
                                        <Col span={24} md={16} lg={16} className="mb-24 " style={{
                                            marginBottom: "-150px",
                                            left: "100px",
                                            position: "relative"
                                        }}>
                                            <Card bordered={false} className="criclebox ">
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={24}>
                                                            <Title level={5}>
                                                                Add Inventory
                                                            </Title><br></br>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div className="number">
                                                                <Row align="middle" gutter={[24, 0]}>
                                                                    <Col span={6} className="sales-record-amount">
                                                                        <Col span={6} style>
                                                                            <AiOutlineBank size={30} />
                                                                        </Col>
                                                                    </Col>
                                                                    <Col span={18}>
                                                                        <h6>Published Products become immediately available for sale.</h6>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <div>
                                                            <br></br>
                                                        </div>
                                                        <Col xs={24} md={24} className="d-flex">
                                                            <div className="log-btn">
                                                                <Link to="/add-invetory">
                                                                    <Button
                                                                        type="primary"
                                                                        shape="round"
                                                                        style={{
                                                                            background: "#1DAFED",
                                                                            borderColor: "#1DAFED"
                                                                        }}
                                                                    ><p style={{ color: '#fff' }}> Add Inventory</p>
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        </Col>

                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Space>
        </Row>
    )
}

export default Inventory