import { MinusOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from '../../api/axios';
const GET_SALES_DATA_URL = (business_id) => `/business/${business_id}/data/sales/`;

const TotalsChart = () => {
    const [totals, setTotals] = useState([])
    const { Title } = Typography
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id");

    const fetchSalesData = useCallback(async (business_id) => {
        try {
            const response = await axios.get(GET_SALES_DATA_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });
            setTotals(response?.data.data)
        }
        catch {
            console.log("Error");
        }
    }, [token, business_id, setTotals]);

    useEffect(() => {
        fetchSalesData(business_id).catch(console.error)
    }, [fetchSalesData])
    const lineChart = {
        series: [
            {
                name: "Total Sales",
                data: totals?.map((item) => item.total_cost),
                offsetY: 0,
            },
            {
                name: "Product Sales",
                data: totals?.map((item) => item.product_cost),
                offsetY: 0,
            },
            {
                name: "Service Sales",
                data: totals?.map((item) => item.service_cost),
                offsetY: 0,
            },
        ],

        options: {
            chart: {
                width: "100%",
                height: 350,
                type: "area",
                toolbar: {
                    show: false,
                },
            },

            legend: {
                show: false,
            },

            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },

            yaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                        colors: ["#8c8c8c"],
                    },
                },
            },

            xaxis: {
                labels: {
                    style: {
                        fontSize: "12px",
                        fontWeight: 600,
                    },
                },
                categories: totals?.map((item) => item.date),
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
    };
    return (
        <>
            <div className="linechart">
                <div>
                    <Title level={5} style={{ color: "#D89546" }}>All Time Sales</Title>
                </div>
                <div className="sales">
                    <ul>
                        <li>{<MinusOutlined />} Totals</li>
                        <li>{<MinusOutlined />} Services</li>
                        <li>{<MinusOutlined />} Products</li>
                    </ul>
                </div>
            </div>

            <ReactApexChart
                className="full-width"
                options={lineChart.options}
                series={lineChart.series}
                type="area"
                height={350}
                width={"100%"}
            />
        </>
    )
}

export default TotalsChart
