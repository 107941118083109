import { Avatar, Button, Card, Col, Menu, Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  AiFillDashboard, AiOutlineBarChart, AiOutlineCustomerService, AiOutlineDeliveredProcedure, AiOutlineDollarCircle, AiOutlineLineChart, AiOutlineLogout, AiOutlineSetting, AiOutlineUser, AiOutlineUsergroupAdd
} from 'react-icons/ai';
import { GiExpense } from "react-icons/gi";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import axios from '../../api/axios';
import AuthContext from "../../context/AuthProvider";
const USER_URL = 'user/'
const BUSINESS_URL = 'business/'

const Sidenav = ({ color }) => {
  const { SubMenu } = Menu;
  const { setProducts, setCustomers, business, setBusiness, setAuth } = useContext(AuthContext);
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [responseData, setResponseData] = useState('')
  const [loggedOut, setloggedOut] = useState(false)
  const logout = () => Modal.confirm({
    title: "Are you sure you want to log out?",
    okText: "Yes",
    okType: "danger",
    onOk: () => {
      window.localStorage.clear();
      setProducts([])
      setCustomers([])
      setBusiness({})
      setAuth([])
      setloggedOut(true)
    }
  })
  const [businesses, setBusinesses] = useState([])
  useEffect(() => {
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    const fetchBusinessData = async () => {
      try {
        const response = await axios.get(`${BUSINESS_URL}${business_id}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        })
        setBusiness(response?.data)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(USER_URL, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        })
        setResponseData(response.data)
      }
      catch {
        console.log("error")
      }
    }
    fetchData()
    fetchBusinessData()
  }, [setResponseData, setBusiness])
  useEffect(() => {
    const token = window.localStorage.getItem("Bearer");
    const fetchBusiness = async () => {
      try {
        const response = await axios.get(BUSINESS_URL, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          withCredentials: true
        })
        setBusinesses(response.data)
      }
      catch (err) {
        console.log(err)
      }
    }
    fetchBusiness()
  }, [])


  if (loggedOut) {
    return <Redirect to="/" />
  }
  return (
    <>
      <div className="brand">
        <Col span={24} md={12} className="col-info">
          <Avatar.Group>
            <Avatar size={55} shape="circle" icon={<AiOutlineUser />} />
            <div className="avatar-info">
              <h4 className="font-semibold m-0">&nbsp;{responseData.first_name} &nbsp;{responseData.last_name}</h4>
            </div>
          </Avatar.Group>
        </Col>
      </div>
      <hr />
      <Menu theme="dark" mode="inline" className="mobile-nav">
        <div className="site-card-border-less-wrapper">
          <Card style={{ background: "#1DAFED08" }}>
            <h4 className="font-semibold m-0" >{business.name}</h4>
            <p>{business.city} {business.country}</p>
            {businesses.length > 1 &&
              <div className="log-btn">
                <NavLink to="/businesses">
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      background: "#45BC5B",
                      borderColor: "#45BC5B"
                    }}
                  ><p style={{ color: '#fff' }}>Select Business</p>
                  </Button>
                </NavLink>
              </div>
            }
          </Card>
        </div>,
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <AiFillDashboard />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/sales">
            <span
              className="icon"
              style={{
                background: page === "sales" ? color : "",
              }}
            >
              <AiOutlineLineChart />
            </span>
            <span className="label">Sales</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/products">
            <span
              className="icon"
              style={{
                background: page === "products" ? color : "",
              }}
            >
              <AiOutlineBarChart />
            </span>
            <span className="label">Products</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/services">
            <span
              className="icon"
              style={{
                background: page === "services" ? color : "",
              }}
            >
              <AiOutlineCustomerService />
            </span>
            <span className="label">Services</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/customer">
            <span
              className="icon"
              style={{
                background: page === "customer" ? color : "",
              }}
            >
              <AiOutlineUsergroupAdd />
            </span>
            <span className="label">Customers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/suppliers">
            <span
              className="icon"
              style={{
                background: page === "suppliers" ? color : "",
              }}
            >
              <AiOutlineDeliveredProcedure />
            </span>
            <span className="label">Suppliers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="/expense">
            <span
              className="icon"
              style={{
                background: page === "expense" ? color : "",
              }}
            >
              <AiOutlineDeliveredProcedure />
            </span>
            <span className="label">Expenses</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/withdrawal">
            <span
              className="icon"
              style={{
                background: page === "withdrawal" ? color : "",
              }}
            >
              <AiOutlineSetting />
            </span>
            <span className="label">Withdrawal</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="93">
          <NavLink to="/expense">
            <span
              className="icon"
              style={{
                background: page === "expense" ? color : "",
              }}
            >
              <GiExpense />
            </span>
            <span className="label">Expenses</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="6" onClick={logout}>
          <Link to="#">
            <span
              className="icon"
              style={{
                background: page === "logout" ? color : "",
              }}
            >
              <AiOutlineLogout />
            </span>
            <span className="label">Logout</span>
          </Link>
        </Menu.Item>
      </Menu>
      <Menu theme="light" mode="inline" className="web-nav">
        <div className="site-card-border-less-wrapper">
          <Card style={{ background: "#1DAFED08" }}>
            <h4 className="font-semibold m-0" >{business.name}</h4>
            <p>{business.city} {business.country}</p>
            {businesses.length > 1 &&
              <div className="log-btn">
                <NavLink to="/businesses">
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      background: "#1DAFED",
                      borderColor: "#1DAFED"
                    }}
                  ><p style={{ color: '#fff' }}>Select Business</p>
                  </Button>
                </NavLink>
              </div>
            }
          </Card>
        </div>,
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <AiFillDashboard />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/sales">
            <span
              className="icon"
              style={{
                background: page === "sales" ? color : "",
              }}
            >
              <AiOutlineLineChart />
            </span>
            <span className="label">Sales</span>
          </NavLink>
        </Menu.Item>
        <SubMenu key="g1"
          icon={<span className="icon" style={{ marginLeft: "-10px" }} ><AiOutlineBarChart /></span>}
          title={<span style={{ marginLeft: "2px" }} className="label">Inventory</span>} >
          <Menu.Item key="13" style={{ marginLeft: "30px" }}>
            <NavLink to="/products">
              <span
                className="icon"
                style={{
                  background: page === "products" ? color : "",
                }}
              >
                <AiOutlineBarChart />
              </span>
              <span className="label">Products</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="14" style={{ marginLeft: "30px" }}>
            <NavLink to="/services">
              <span
                className="icon"
                style={{
                  background: page === "services" ? color : "",
                }}
              >
                <AiOutlineCustomerService />
              </span>
              <span className="label">Services</span>
            </NavLink>
          </Menu.Item>
        </SubMenu >
        <Menu.Item key="4">
          <NavLink to="/customer">
            <span
              className="icon"
              style={{
                background: page === "customer" ? color : "",
              }}
            >
              <AiOutlineUsergroupAdd />
            </span>
            <span className="label">Customers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/accounts">
            <span
              className="icon"
              style={{
                background: page === "accounts" ? color : "",
              }}
            >
              <AiOutlineDollarCircle />
            </span>
            <span className="label">Money Accounts</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="18">
          <NavLink to="/suppliers">
            <span
              className="icon"
              style={{
                background: page === "suppliers" ? color : "",
              }}
            >
              <AiOutlineDeliveredProcedure />
            </span>
            <span className="label">Suppliers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="/expense">
            <span
              className="icon"
              style={{
                background: page === "expense" ? color : "",
              }}
            >
              <GiExpense />
            </span>
            <span className="label">Expenses</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/settings">
            <span
              className="icon"
              style={{
                background: page === "settings" ? color : "",
              }}
            >
              <AiOutlineSetting />
            </span>
            <span className="label">Settings</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/withdrawal">
            <span
              className="icon"
              style={{
                background: page === "withdrawal" ? color : "",
              }}
            >
              <AiOutlineSetting />
            </span>
            <span className="label">Withdrawal</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="60" onClick={logout}>
          <Link to="#">
            <span
              className="icon"
              style={{
                background: page === "logout" ? color : "",
              }}
            >
              <AiOutlineLogout />
            </span>
            <span className="label">Logout</span>
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
