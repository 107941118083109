import {
  Button, Card, Col, message, Modal, Row, Table, Typography
} from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from '../api/axios'
import NoExpense from '../components/data cards/mobile/expense/NoExpense'
import NoExpenseCard from '../components/data cards/web/expense/NoExpenseCard'
import EditExpense from '../components/forms/EditExpense'
import Footer from '../components/layout/Footer/FooterMobile'
import AuthContext from "../context/AuthProvider"
const EXPENSE_URL = (business_id) => `/business/${business_id}/expense/`;
const { Title } = Typography


const Expense = () => {
  const token = window.localStorage.getItem("Bearer");
  const business_id = window.localStorage.getItem("business_id")
  const { expenseData, SetExpenseData } = useContext(AuthContext)
  const [isEditing, setIsEditing] = useState(false)
  const [editExpense, setEditExpense] = useState(null)


  const onClickExpense = (record) => {
    setIsEditing(true)
    setEditExpense({ ...record })
  }
  const resetEditing = () => {
    setIsEditing(false)
    setEditExpense(null)
  }

  const columns = [
    {
      title: (<p className="table-titles">Description</p>),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: (<p className="table-titles">Expense Type</p>),
      dataIndex: 'expense_type',
      key: 'expense_type ',
    },
    {
      title: (<p className="table-titles">Expense Amount</p>),
      dataIndex: 'expense_amount',
      key: 'expense_amount ',
    },
    {
      title: (<p className="table-titles">Expense Date</p>),
      dataIndex: 'expense_date',
      key: 'expense_date ',
    },
    {
      title: (<p className="table-titles">Action</p>),
      key: 'action',
      render: (record) => (
        <div className="sales-btn">
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              onClickExpense(record)
            }}
            style={{
              background: "#dcf0f9",
              borderColor: "#dcf0f9"
            }}
          ><p style={{ color: '#1DAFED' }}>Edit</p>
          </Button>
        </div>
      ),
    }

  ]
  const mobile_columns = [
    {
      title: (<p className="table-titles">Description</p>),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: (<p className="table-titles">Action</p>),
      key: 'action',
      render: (record) => (
        <div className="sales-btn">
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              onClickExpense(record)
            }}
            style={{
              background: "#dcf0f9",
              borderColor: "#dcf0f9"
            }}
          ><p style={{ color: '#1DAFED' }}>Edit</p>
          </Button>
        </div>
      ),
    }
  ]

  const fetchExpenseData = useCallback(async () => {
    try {
      const response = await axios.get(EXPENSE_URL(business_id), {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        // params: {
        //   "business_id": business_id
        // }
      })
      SetExpenseData(response.data.reverse())
      console.log(response, 'expense');
    } catch (error) {
      console.log(error)
    }
  }, [token, business_id, SetExpenseData])

  const patchExpenseData = async (data) => {
    try {
      await axios.patch(`${EXPENSE_URL(business_id)}${data?.expense_id}`,
        JSON.stringify({
          expense_amount: data?.expense_amount, expense_date: data?.expense_date,
          expense_type: data?.expense_type, description: data?.description
        }),
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          // params: {
          //   "business_id": business_id
          // }
        })
      message.success('You have successfully updated the Expense', 3)
      fetchExpenseData()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchExpenseData().catch(console.error())
  }, [fetchExpenseData])

  if (!token) {
    return <Redirect to="/" />
  }
  if (!business_id) {
    return <Redirect to="/businesses" />
  }
  return (
    <div className="layout-content">
      <Row gutter={[24, 0]} className="dash-mobile-layout">
        {expenseData.length === 0 && <NoExpense />}
        {expenseData.length > 0 &&
          <>
            <Col className="" style={{ padding: 12 }} xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 24 }}>
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={7}>
                      <Title level={3} style={{ color: "#1DAFED" }}>
                        {expenseData.length}
                      </Title>
                      <p className="mobile-card-p">Expenses</p>
                    </Col>
                    <Col span={7}>
                      <Title level={3} style={{ color: "#1DAFED" }}>
                        0
                      </Title>
                      <p className="mobile-card-p">Categories</p>
                    </Col>
                    <Col span={10}>
                      <div className="log-btn">
                        <Link to="/add-expense">
                          <Button
                            type="primary"
                            shape="round"
                            style={{
                              background: "#45BC5B",
                              borderColor: "#45BC5B"
                            }}
                          ><p style={{ color: '#fff' }}>Add Expense</p>
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col span={24}>
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col span={12}>
                      <h2>Expenses</h2>
                    </Col>
                  </Row>
                </div>
                <Card bordered={false} className="criclebox " >
                  <Table dataSource={expenseData} columns={mobile_columns}
                  />
                </Card>
              </Card>
            </Col>
          </>
        }
        <Footer />
      </Row>

      <Row className="dash-web-layout">
        <Col span={24}>
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={18}>
                <Title level={3}>Expense Overview</Title>
              </Col>
              <Col span={4} offset={1}>
                <div className="log-btn">
                  <Link to="/add-expense">
                    <Button
                      type="primary"
                      shape="round"
                      style={{
                        marginLeft: "100px",
                        background: "#ffff",
                        borderColor: "#1DAFED"
                      }}
                    ><p style={{ color: '#1DAFED' }}>Add Expense</p>
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={24} >
          <Card bordered={false} className="criclebox ">
            {expenseData.length === 0 &&
              <NoExpenseCard />
            }
            {expenseData.length > 0 &&
              < Table columns={columns} dataSource={expenseData} />
            }
          </Card>
        </Col>

        <Modal title="Edit Expense" okText="Save" visible={isEditing} onCancel={() => { resetEditing() }}
          onOk={() => {
            SetExpenseData((pre) => {
              return pre.map((record) => {
                if (record.expense_id === editExpense.expense_id) {
                  return patchExpenseData(editExpense)
                } else {
                  return record
                }
              })
            })
            resetEditing()
          }}>
          <EditExpense setEditExpense={setEditExpense} editExpense={editExpense} />
        </Modal>
      </Row>
    </div>

  )
}

export default Expense
