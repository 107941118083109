import {
  Button, Card, Col, DatePicker, Descriptions, Divider, Form,
  Input, Modal, Row, Space, Table, Tag, Typography
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { FaRegLaugh } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import axios from "../api/axios";
import CustomerBar from "../components/charts/CustomerBar";
import Footer from "../components/layout/Footer/FooterMobile";
import AuthContext from "../context/AuthProvider";
const CUSTOMER_URL = (business_id) => `/business/${business_id}/customer/`;

const Customer = () => {
  const { Title } = Typography;
  const token = window.localStorage.getItem("Bearer");
  const business_id = window.localStorage.getItem("business_id");
  const { customers, setCustomers } = useContext(AuthContext);
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);
  const fetchCustomerData = useCallback(async () => {
    if (business_id) {
      try {
        const response = await axios.get(CUSTOMER_URL(business_id), {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // params: {
          //     "business_id": business_id
          // }
        });
        setCustomers(response?.data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [token, business_id, setCustomers]);
  const patchCustomerData = async (data) => {
    try {
      await axios.patch(
        `${CUSTOMER_URL(business_id)}${data?.customer_id}`,
        JSON.stringify({
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          phone_no: data?.phone_no,
          gender: data?.gender,
          date_of_birth: data?.date_of_birth,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // params: {
          //     "business_id": business_id
          // }
        }
      );
      fetchCustomerData(business_id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCustomerData(business_id).catch(console.error);
  }, [fetchCustomerData]);

  const onClickCustomer = (record) => {
    setIsEditingCustomer(true);
    setEditCustomer({ ...record });
  };

  const resetEditing = () => {
    setIsEditingCustomer(false);
    setEditCustomer(null);
  };
  const columns = [
    {
      title: <p className="table-titles">First Name</p>,
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: <p className="table-titles">Phone Number</p>,
      dataIndex: "phone_no",
      key: "phone_no",
    },
    {
      title: <p className="table-titles">Action</p>,
      key: "action",
      render: (record) => (
        <div className="sales-btn">
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              onClickCustomer(record);
            }}
            style={{
              background: "#dcf0f9",
              borderColor: "#dcf0f9",
            }}
          >
            <p style={{ color: "#1DAFED" }}>Edit</p>
          </Button>
        </div>
      ),
    },
  ];

  const web_columns = [
    {
      title: <p className="table-titles">First Name</p>,
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: <p className="table-titles">Last Name</p>,
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: <p className="table-titles">Email</p>,
      dataIndex: "email",
      key: "email",
    },
    {
      title: <p className="table-titles">Phone Number</p>,
      dataIndex: "phone_no",
      key: "phone_no",
    },
    {
      title: <p className="table-titles">Gender</p>,
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: <p className="table-titles">Date of Birth</p>,
      dataIndex: "date_of_birth",
      key: "date_of_birth",
    },
    {
      title: <p className="table-titles">Action</p>,
      key: "action",
      render: (record) => (
        <div className="sales-btn">
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              onClickCustomer(record);
            }}
            style={{
              background: "#dcf0f9",
              borderColor: "#dcf0f9",
            }}
          >
            <p style={{ color: "#1DAFED" }}>Edit</p>
          </Button>
        </div>
      ),
    },
  ];
  if (!token) {
    return <Redirect to="/" />;
  }
  if (!business_id) {
    return <Redirect to="/businesses" />;
  }
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]} className="dash-mobile-layout">
          {customers.length === 0 && (
            <Card
              bordered={false}
              className="criclebox "
              style={{ background: "#fef5f5" }}
            >
              <Row align="middle" gutter={[24, 0]} span={24}>
                <Col span={14}>
                  <h6>
                    <b>You do not have any Customers</b>
                  </h6>
                </Col>
                <Col span={10}>
                  <div className="log-btn">
                    <Link to="/add-customer">
                      <Button
                        type="primary"
                        shape="round"
                        style={{
                          background: "#45BC5B",
                          borderColor: "#45BC5B",
                        }}
                      >
                        <p style={{ color: "#fff" }}>Add Customer</p>
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
          )}
          {customers.length > 0 && (
            <Row>
              <Col
                style={{ padding: 12 }}
                xs={{ span: 24 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
              >
                <Card bordered={false} className="criclebox ">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]} span={24}>
                      <Col span={12}>
                        <Title level={3}>{customers.length}</Title>
                        <p className="card-p">Customers</p>
                      </Col>
                      <Col span={12}>
                        <div className="log-btn">
                          <Link to="/add-customer">
                            <Button
                              type="primary"
                              shape="round"
                              style={{
                                background: "#45BC5B",
                                borderColor: "#45BC5B",
                              }}
                            >
                              <p style={{ color: "#fff" }}>Add Customer</p>
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col span={22} offset={1}>
                <Card bordered={false} className="criclebox ">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={24}>
                        <h2>Customer list</h2>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Table dataSource={customers} columns={columns} />;
              </Col>
            </Row>
          )}
          <Footer />
        </Row>

        <Row className="dash-web-layout">
          <Row>
            <Col span={24}>
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col span={20}>
                    <Title level={3}>Customer Overview</Title>
                  </Col>
                  <Col span={4} style={{ paddingTop: "10px" }}>
                    <div className="log-btn">
                      <Link to="/add-customer">
                        <Button
                          type="primary"
                          shape="round"
                          style={{
                            marginLeft: "auto",
                            background: "#ffff",
                            borderColor: "#1DAFED",
                          }}
                        >
                          <p style={{ color: "#1DAFED" }}>Add Customer</p>
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Divider />
            {customers.length === 0 && (
              <Row gutter={[24, 0]}>
                <Col span={24} md={24} lg={24} className="mb-24 ">
                  <Card
                    bordered={false}
                    className="criclebox "
                    style={{ background: "#fef5f5" }}
                  >
                    <div className="number">
                      <Row align="middle" gutter={[24, 0]}>
                        <Col span={12}>
                          <Descriptions>
                            <Descriptions.Item>
                              You do not have any Customers recorded.<br></br>
                              Get started to experience automatic sychronization
                              for your business
                            </Descriptions.Item>
                          </Descriptions>
                        </Col>
                        <Col span={12}>
                          <Col
                            span={24}
                            md={16}
                            lg={16}
                            className="mb-24 "
                            style={{
                              marginBottom: "-150px",
                              left: "100px",
                              position: "relative",
                            }}
                          >
                            <Card bordered={false} className="criclebox ">
                              <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                  <Col span={24}>
                                    <Title level={5}>Customers</Title>
                                    <br></br>
                                  </Col>
                                  <Col span={24}>
                                    <div className="number">
                                      <Row align="middle" gutter={[24, 0]}>
                                        <Col
                                          span={6}
                                          className="sales-record-amount"
                                        >
                                          <Col span={6} style>
                                            <AiOutlineUsergroupAdd size={30} />
                                          </Col>
                                        </Col>
                                        <Col span={18}>
                                          <h6>
                                            Add Customers to your business.
                                          </h6>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <div>
                                    <br></br>
                                  </div>
                                  <Col xs={24} md={24} className="d-flex">
                                    <div className="log-btn">
                                      <Link to="/add-customer">
                                        <Button
                                          type="primary"
                                          shape="round"
                                          style={{
                                            background: "#1DAFED",
                                            borderColor: "#1DAFED",
                                          }}
                                        >
                                          <p style={{ color: "#fff" }}>
                                            Add Customer
                                          </p>
                                        </Button>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
            {customers.length > 0 && (
              <>
                <Col span={16} className="mb-24 ">
                  <Card bordered={false} className="criclebox ">
                    <CustomerBar />
                  </Card>
                </Col>
                <Col span={7} offset={1} className="mb-24 ">
                  <Space direction="vertical" size={22}>
                    <Card bordered={false} className="criclebox ">
                      <Space direction="vertical" size={12}>
                        <Title level={3}>
                          Insights <BsStars style={{ color: "#ffaa00" }} />
                        </Title>
                        <Row>
                          <Space direction="vertical" size={20}>
                            <Row>
                              <Col span={4}>
                                <FaRegLaugh
                                  style={{
                                    fontSize: 25,
                                    color: "#faac17",
                                    marginTop: "5px",
                                  }}
                                />
                              </Col>
                              <Col span={20}>
                                <h6>
                                  80% of your sales are from 20% of your
                                  customers.
                                </h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={4}>
                                <FaRegLaugh
                                  style={{
                                    fontSize: 25,
                                    color: "#faac17",
                                    marginTop: "2px",
                                  }}
                                />
                              </Col>
                              <Col span={20}>
                                <h6>60% of customers are male</h6>
                              </Col>
                            </Row>
                          </Space>
                        </Row>
                        <Col xs={24} md={24} className="d-flex">
                          <div className="log-btn">
                            <Link to="/insights">
                              <Button
                                type="primary"
                                shape="round"
                                style={{
                                  background: "#dcf0f9",
                                  borderColor: "#dcf0f9",
                                }}
                              >
                                <p style={{ color: "#1DAFED" }}>
                                  ALL{" "}
                                  <AiOutlineArrowRight
                                    style={{ marginTop: "5px" }}
                                  />
                                </p>
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Space>
                    </Card>
                    <Card>
                      <Row align="middle" gutter={[24, 0]}>
                        <Col span={16} offset={2}>
                          <p
                            className="span-large"
                            style={{ marginBottom: "2px" }}
                          >
                            <b> Anonymous Customers</b>
                          </p>
                        </Col>
                        <Col span={6}>
                          <div className="sales-btn">
                            <Tag color="#bfbfbf" shape="circle">
                              <span className="discount-p">24</span>
                            </Tag>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Space>
                </Col>
                <Col span={24} md={24} lg={24} xl={24} className="mb-24 ">
                  <Card bordered={false} className="criclebox ">
                    <Table dataSource={customers} columns={web_columns} />
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Row>

        <Modal
          title="Edit Customer Information"
          okText="Save"
          visible={isEditingCustomer}
          onCancel={() => {
            resetEditing();
          }}
          onOk={() => {
            setCustomers((pre) => {
              return pre.map((record) => {
                if (record?.customer_id === editCustomer?.customer_id) {
                  return patchCustomerData(editCustomer);
                } else {
                  return record;
                }
              });
            });
            resetEditing();
          }}
        >
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={10}>
                <Form.Item className="username" label="First Name">
                  <Input
                    value={editCustomer?.first_name}
                    onChange={(e) => {
                      setEditCustomer((pre) => {
                        return { ...pre, first_name: e.target.value };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Form.Item className="username" label="Last Name">
                  <Input
                    value={editCustomer?.last_name}
                    onChange={(e) => {
                      setEditCustomer((pre) => {
                        return { ...pre, last_name: e.target.value };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item className="username" label="Email">
                  <Input
                    value={editCustomer?.email}
                    onChange={(e) => {
                      setEditCustomer((pre) => {
                        return { ...pre, email: e.target.value };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Form.Item className="username" label="Phone Number">
                  <Input
                    value={editCustomer?.phone_no}
                    onChange={(e) => {
                      setEditCustomer((pre) => {
                        return { ...pre, phone_no: e.target.value };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item className="username" label="Gender">
                  <Input
                    value={editCustomer?.gender}
                    onChange={(e) => {
                      setEditCustomer((pre) => {
                        return { ...pre, gender: e.target.value };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Form.Item className="username" label="Date of Birth">
                  <DatePicker
                    onChange={(date, dateString) => {
                      setEditCustomer((pre) => {
                        return { ...pre, date_of_birth: dateString };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Customer;
