import {
  Breadcrumb,
  Button, Col, Menu, Modal, Row
} from 'antd';
import { useContext, useEffect, useState } from "react";
import {
  AiFillBank, AiOutlineLogout, AiOutlineSetting
} from 'react-icons/ai';
import { NavLink, Redirect } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
// import headerlogo from "../assets/images/reja-logo.jpg";
import headerlogo from "../../assets/images/logo.png";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

const Header = ({
  subName,
  onPress,
}) => {
  const { setProducts, setCustomers, setBusiness } = useContext(AuthContext)
  const [loggedOut, setloggedOut] = useState(false)
  const logout = () =>
    Modal.confirm({
      title: "Are you sure you want to log out?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        window.localStorage.clear();
        setProducts([])
        setCustomers([])
        setBusiness({})
        setloggedOut(true)
      }
    })

  useEffect(() => window.scrollTo(0, 0));
  if (loggedOut) {
    return <Redirect to="/" />
  }


  return (
    <>
      <Row gutter={[24, 0]} className="small-header">
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/"><img src={headerlogo} alt="Reja Logo" style={{ maxWidth: "3rem" }} /></NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={24} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          {/* <Badge size="small" count={4}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                href="#pablo"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {bell}
              </a>
            </Dropdown>
          </Badge>
          <Link to="/messages" className="btn-sign-in">
            <AiFillMail />
          </Link> */}

        </Col>
      </Row>
      <Row gutter={[24, 0]} className="large-header">
        <Col
          span={24}
          xl={{ span: 8, offset: 16 }}
          md={{ span: 18, offset: 12 }}
          // lg={{ span: 9, offset: 15 }}
          xxl={{ span: 7, offset: 17 }}
        >
          <Menu mode="horizontal">
            <Menu.Item key="accounts" icon={<AiFillBank />}>
              <NavLink to='/accounts' activeClassName="header-active">
                Accounts
              </NavLink>
            </Menu.Item>
            <Menu.Item key="settings" icon={<AiOutlineSetting />}>
              <NavLink to='/settings' activeClassName="header-active">
                Settings
              </NavLink>
            </Menu.Item>
            <Menu.Item key="logout" onClick={logout} icon={<AiOutlineLogout />}>
              Logout
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
    </>
  );
}

export default Header;
