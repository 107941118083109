import { Row, Col } from "antd"
import {
    AiFillHome,
    AiOutlineUsergroupAdd,
    AiOutlineLineChart,
    AiOutlineDeliveredProcedure
} from 'react-icons/ai';
import { NavLink } from "react-router-dom";
const Footer = () => {
    // const [showText, setShowText] = useState(false)

    // const handleClick = () => {
    //     setShowText(true)
    // }
    // if (showText) {
    //     return <Redirect to="/dashboard" />
    // }


    return (

        <Row className="mobile-footer ">

            <Col className='footer_col' span={6} >
                <NavLink to='/dashboard'>
                    <Col span={12} offset={6}><AiFillHome size={25} className='footer_logo' /></Col>
                    <Col span={12} offset={6}><p className='footer_text'>Dashboard</p></Col>
                </NavLink>
            </Col>
            <Col className='footer_col' span={6} >
                <NavLink to='/sales'>
                    <Col span={12} offset={6}><AiOutlineLineChart size={25} className='footer_logo' /></Col>
                    <Col span={12} offset={6}><p className='footer_text'>Sales</p></Col>
                </NavLink>
            </Col>
            <Col className='footer_col' span={6} >
                <NavLink to='/customer'>
                    <Col span={12} offset={6}><AiOutlineUsergroupAdd size={25} className='footer_logo' /></Col>
                    <Col span={12} offset={6}><p className='footer_text'>Customers</p></Col>
                </NavLink>
            </Col>
            <Col className='footer_col' span={6} >
                <NavLink to='/suppliers'>
                    <Col span={12} offset={6}><AiOutlineDeliveredProcedure size={25} className='footer_logo' /></Col>
                    <Col span={12} offset={6}><p className='footer_text'>Suppliers</p></Col>
                </NavLink>
            </Col>

        </Row>
    )

}

export default Footer;
