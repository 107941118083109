import {
    Row,
    Col,
    Card,
    Button,
} from 'antd'
import { Link } from 'react-router-dom'

const NoExpense = () => {
    return (
        <Card bordered={false} className="criclebox " style={{ background: "#fce8e8" }}>
            <Row align="middle" gutter={[24, 0]} span={24}>
                <Col span={14}>
                    <h6><b>You do not have any Expenses</b></h6>
                </Col>
                <Col span={10}>
                    <div className="log-btn">
                        <Link to="/add-expense">
                            <Button
                                type="primary"
                                shape="round"
                                style={{
                                    background: "#45BC5B",
                                    borderColor: "#45BC5B"
                                }}
                            ><p style={{ color: '#fff' }}>Add Expense</p>
                            </Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default NoExpense