import {
    Avatar, Button,
    Card, Col, Collapse, Descriptions,
    List, Result, Row, Space,
    Typography
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiNumber1 } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import PaymentRow from './PaymentRow';
const GET_CUSTOMER_URL = (business_id) => `/business/${business_id}/customer/`;
const SUMMARY_DATA_URL = (business_id) => `/business/${business_id}/data/summary/`;
const TRANSACTIONS_URL = (business_id) => `/business/${business_id}/transaction/`;

const SuccessfulPayment = () => {
    const { Panel } = Collapse
    const { Title } = Typography;
    const { setTransaction, transaction, setCart, complete_transaction, setServiceCart, setRecentSales, setSummary } = useContext(AuthContext);
    const [prodData] = useState(complete_transaction?.payment)
    const [transData] = useState(complete_transaction?.transaction)
    const [customerData, setCutomerData] = useState([])
    const [receipt] = useState(transaction.products)
    const [service_receipt] = useState(transaction.services)

    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id");
    const access_token = window.localStorage.getItem("access_token");
    const auth_header = {
        "Authorization": access_token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };
    const fetchSummaryData = async (business_id) => {
        try {
            const response = await axios.get(SUMMARY_DATA_URL(business_id), {
                headers: auth_header
            });
            setSummary(response?.data);
        } catch {
            console.log("Error");
        }
    };
    const fetchTransData = async (business_id) => {
        try {
            const response = await axios.get(TRANSACTIONS_URL(business_id), {
                headers: auth_header,
            });
            const filteredData = response?.data;
            fetchSummaryData(business_id);
            setRecentSales(filteredData);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (business_id) {

            const customer_id = transData?.customer_id
            const fetchCutomerData = async () => {
                try {
                    const response = await axios.get(`${GET_CUSTOMER_URL(business_id)}${customer_id}`, {
                        headers: auth_header,
                    })
                    setCutomerData(response?.data)
                } catch (error) {
                    console.log(error)
                }
            }
            fetchCutomerData();
            fetchTransData(business_id);
        }
    }, [transData?.customer_id, business_id])

    const resetData = (e) => {
        window.localStorage.removeItem("transaction_id");
        window.localStorage.removeItem("customer_id");
        window.localStorage.removeItem("amount_paid");
        window.localStorage.removeItem("total");
        setTransaction([]);
        setCart([]);
        setServiceCart([]);
    };
    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                <Col span={24}>
                    <Collapse accordion expandIconPosition="right" style={{ color: '#F0F0F0' }}>
                        <Panel header={`${customerData.first_name} ${customerData.last_name}`} key="1">
                            <Descriptions bordered column={{ xxl: 4, xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
                                <Descriptions.Item >{customerData.first_name} {customerData.last_name} </Descriptions.Item>
                                <Descriptions.Item >{customerData.phone_no}</Descriptions.Item>
                            </Descriptions>
                            <Card
                                bordered={false}
                                bodyStyle={{ paddingTop: 0 }}
                                className="header-solid h-full  ant-list-yes"
                                title={<h6 className="font-semibold m-0">Sale Info</h6>}
                                extra={
                                    <p className="card-header-date">
                                        <span>{transData?.transaction_date}</span>
                                    </p>
                                }
                            >
                                {receipt.length !== 0 &&
                                    <List
                                        className="transactions-list ant-newest"
                                        itemLayout="horizontal"
                                        dataSource={receipt}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar size="small" style={{ color: '#e02d1e' }}>
                                                            <AiOutlinePlus />
                                                        </Avatar>
                                                    }
                                                    title={item.product.name}
                                                    description={<span >{item.product.price} x {item.details.quantity}</span >}
                                                />
                                                <div className="amount">
                                                    <span className='receipt-cost'>KES  {item.details.cost}</span>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                }
                                {service_receipt.length !== 0 &&
                                    <List
                                        className="transactions-list ant-newest"
                                        itemLayout="horizontal"
                                        dataSource={service_receipt}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar size="small" style={{ color: '#e02d1e' }}>
                                                            <AiOutlinePlus />
                                                        </Avatar>
                                                    }
                                                    title={item.service.name}
                                                    description={<span >{item.service.price} x {item.details.quantity}</span >}
                                                />
                                                <div className="amount">
                                                    <span className='receipt-cost'>KES  {item.details.cost}</span>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                }
                            </Card>
                        </Panel>
                    </Collapse>
                    <>
                        <br></br>
                    </>
                    <PaymentRow />

                    <>
                        <br></br>
                    </>
                    {complete_transaction &&
                        <Row>
                            <Col span={22} offset={1}>
                                <Card bordered={false} className="criclebox ">
                                    <Result
                                        status="success"
                                        title="Success!"
                                        subTitle="You have succesfully recorded a Sale! "
                                        extra={[
                                            <p>Change:  KES <span>{prodData?.change}</span></p>
                                        ]}
                                    />,
                                </Card>
                            </Col>
                        </Row>
                    }
                    <>
                        <br></br>
                    </>
                    <Row>
                        <Col span={24}>
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={12}>
                                    </Col>
                                    <Col span={12}>
                                        <Col span={24}>
                                            <div className="header-col payment-button">
                                                <NavLink to="/sales">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        style={{
                                                            width: "100%",
                                                            background: "#45BC5B",
                                                            borderColor: "#45BC5B"
                                                        }}
                                                        shape="round"
                                                        onClick={resetData}
                                                    >
                                                        <p style={{
                                                            color: '#fff',
                                                            alignContent: "center",
                                                            marginTop: "5px",

                                                        }}>OK</p>
                                                    </Button>
                                                </NavLink>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="dash-web-layout">
                <Space direction="vertical" size='large'>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={24}>
                                <Title level={3}>Cash Payment</Title>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={20} lg={20} className="mb-24 ">
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={20}>
                                            <Avatar shape="circle" icon={<RiNumber1 />} />
                                            <Title level={4}>Transaction Details</Title>
                                        </Col>
                                        <Col span={20}>
                                            <Collapse accordion expandIconPosition="right" style={{ color: '#F0F0F0' }}>
                                                <Panel header={`${customerData.first_name} ${customerData.last_name}`} key="1">
                                                    <Descriptions bordered column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                                                        <Descriptions.Item >{customerData.first_name} {customerData.last_name} </Descriptions.Item>
                                                        <Descriptions.Item >{customerData.phone_no}</Descriptions.Item>
                                                    </Descriptions>
                                                    <Card
                                                        bordered={false}
                                                        bodyStyle={{ paddingTop: 0 }}
                                                        className="header-solid h-full  ant-list-yes"
                                                        title={<h6 className="font-semibold m-0">Sale Info</h6>}
                                                        extra={
                                                            <p className="card-header-date">
                                                                <span>{transData?.transaction_date}</span>
                                                            </p>
                                                        }
                                                    >
                                                        {receipt.length !== 0 &&
                                                            <List
                                                                className="transactions-list ant-newest"
                                                                itemLayout="horizontal"
                                                                dataSource={receipt}
                                                                renderItem={(item) => (
                                                                    <List.Item>
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <Avatar size="small" style={{ color: '#e02d1e' }}>
                                                                                    <AiOutlinePlus />
                                                                                </Avatar>
                                                                            }
                                                                            title={item.product.name}
                                                                            description={<span >{item.product.price} x {item.details.quantity}</span >}
                                                                        />
                                                                        <div className="amount">
                                                                            <span className='receipt-cost'>KES  {item.details.cost}</span>
                                                                        </div>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        }
                                                        {service_receipt.length !== 0 &&
                                                            <List
                                                                className="transactions-list ant-newest"
                                                                itemLayout="horizontal"
                                                                dataSource={service_receipt}
                                                                renderItem={(item) => (
                                                                    <List.Item>
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <Avatar size="small" style={{ color: '#e02d1e' }}>
                                                                                    <AiOutlinePlus />
                                                                                </Avatar>
                                                                            }
                                                                            title={item.service.name}
                                                                            description={<span >{item.service.price} x {item.details.quantity}</span >}
                                                                        />
                                                                        <div className="amount">
                                                                            <span className='receipt-cost'>KES  {item.details.cost}</span>
                                                                        </div>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        }
                                                    </Card>
                                                </Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={20}>
                                            <PaymentRow />
                                            <>
                                                <br></br>
                                            </>
                                            {complete_transaction &&
                                                <Row>
                                                    <Col span={24}>
                                                        <Card bordered={false} className="criclebox ">
                                                            <Result
                                                                status="success"
                                                                title="Success!"
                                                                subTitle="You have succesfully recorded a Sale! "
                                                                extra={[
                                                                    <p>Change:  KES <span>{prodData?.change}</span></p>
                                                                ]}
                                                            />,
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            }
                                            <>
                                                <br></br>
                                            </>
                                            <Row>
                                                <Col span={24}>
                                                    <div className="number">
                                                        <Row align="middle" gutter={[24, 0]}>
                                                            <Col span={12}>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Col span={24}>
                                                                    <div className="header-col payment-button">
                                                                        <NavLink to="/sales">
                                                                            <Button
                                                                                type="primary"
                                                                                htmlType="submit"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    background: "#45BC5B",
                                                                                    borderColor: "#45BC5B"
                                                                                }}
                                                                                shape="round"
                                                                                onClick={resetData}
                                                                            >
                                                                                <p style={{
                                                                                    color: '#fff',
                                                                                    alignContent: "center",
                                                                                    marginTop: "5px",

                                                                                }}>OK</p>
                                                                            </Button>
                                                                        </NavLink>
                                                                    </div>
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Row>
        </div>
    )
}

export default SuccessfulPayment
