import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from "../api/axios";
const WITHDRAWL_URL = (business_id) => `/business/${business_id}/withdrawal-email/`;

function Withdrawal() {
    const business_id = window.localStorage.getItem("business_id");
    const [withdrawalSent, setWithdrawalSent] = useState(false);
    const history = useHistory();
    const access_token = window.localStorage.getItem("access_token");
    const auth_header = {
        "Authorization": access_token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    const handleWithdrawal = async () => {
        try {
            const response = await axios.get(WITHDRAWL_URL(business_id), {
                headers: auth_header,
            });
            setWithdrawalSent(true);
            setTimeout(() => {
                history.push('/dashboard');
            }, 5000); // Redirect to dashboard after 5 seconds
        } catch (error) {
            console.log(error);
            console.log(error.response)
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                <button onClick={handleWithdrawal}>Withdrawal</button>
                {withdrawalSent && (
                    <p>
                        Withdrawal request sent. Please allow for 24 hours for the request to be processed.
                    </p>
                )}
            </header>
        </div>
    );
}

export default Withdrawal;
