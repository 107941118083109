import {
  Avatar, Button,
  Card, Col, DatePicker, Form, Input, message, Modal, Row, Select,
  Space, Typography
} from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEdit, AiOutlineSearch,
  AiOutlineShopping
} from "react-icons/ai";
import { RiNumber1, RiNumber2 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from "../api/axios";
import AuthContext from "../context/AuthProvider";
import SingleProduct from "./SingleProduct";
import SingleService from "./SingleService";
const ADD_TRANSACTION_URL = (business_id) => `/business/${business_id}/transaction/`;
const GET_CUSTOMERS_URL = (business_id) => `/business/${business_id}/customer/`;
const ADD_CUSTOMER_URL = (business_id) => `/business/${business_id}/customer/`;
const GET_PRODUCTS_URL = (business_id) => `business/${business_id}/product/`;
const GET_SERVICES_URL = (business_id) => `/business/${business_id}/service/`;

const { Option } = Select;

const AddSale = () => {
  const errRef = useRef(null);
  const { customers, setCustomers } = useContext(AuthContext);
  const { customer, setCustomer } = useContext(AuthContext);
  const { cart, setCart } = useContext(AuthContext);
  const { service_cart, setServiceCart } = useContext(AuthContext);
  const { transaction, setTransaction } = useContext(AuthContext);
  const { products, setProducts, services, setServices } = useContext(AuthContext);
  const token = window.localStorage.getItem("Bearer");
  const business_id = window.localStorage.getItem("business_id");
  const IsLoggedIn = window.localStorage.getItem("Bearer");
  const IsBusiness = window.localStorage.getItem("business_id");
  const { Title } = Typography;

  const [searchTerm, setSearchTerm] = useState("");
  const [IsEditing, setIsEditing] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [editRecordDetails, setEditRecordDetails] = useState(null);
  const [serviceCartdetail, setServiceCartDetail] = useState([]);
  const [cartdetail, setCartDetail] = useState([]);
  const [success, setSuccess] = useState(false);
  const [product_total, setProdutTotal] = useState(0);
  const [service_total, setServiceTotal] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("Anonymous");
  const [last_name, setLastName] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [is_disabled, setIsDisable] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeFirstName = (e) => {
    const first_name = e.target.value;
    setFirstName(first_name);
  };
  const onChangeLastName = (e) => {
    const last_name = e.target.value;
    setLastName(last_name);
  };
  const onChangeDateOfBirth = (date, dateString) => {
    const date_of_birth = dateString;
    setDateOfBirth(date_of_birth);
  };
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePhoneNo = (e) => {
    const phone_no = e.target.value;
    if (phone_no.startsWith("0")) {
      const phone_now = "254" + phone_no.replace("0", "");
      setPhoneNo(phone_now);
    } else if (phone_no.startsWith("7")) {
      const phone_now = "254" + phone_no;
      setPhoneNo(phone_now);
    } else if (phone_no.startsWith("1")) {
      const phone_now = "254" + phone_no;
      setPhoneNo(phone_now);
    } else {
      setPhoneNo(phone_no);
    }
  };
  const onChangeGender = (value) => {
    const gender = value;
    setGender(gender);
  };

  useEffect(() => {
    setProdutTotal(
      cartdetail.reduce(
        (acc, cartdetail) => acc + cartdetail.price * cartdetail.quantity,
        0
      )
    );
    setServiceTotal(
      serviceCartdetail.reduce(
        (acc, scartdetail) => acc + scartdetail.price * scartdetail.quantity,
        0
      )
    );
  }, [cartdetail, serviceCartdetail]);
  useEffect(() => {
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id");
    const fetchProductData = async (business_id) => {
      try {
        const response = await axios.get(GET_PRODUCTS_URL(business_id), {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // params: {
          //   business_id: business_id,
          // },
        });
        setProducts(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchServiceData = async (business_id) => {
      try {
        const response = await axios.get(GET_SERVICES_URL(business_id), {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // params: {
          //   business_id: business_id,
          // },
        });
        setServices(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProductData(business_id);
    fetchServiceData(business_id);
  }, [setProducts, setServices]);

  const handleTransaction = async () => {
    if (cart.length > 0 || service_cart.length > 0) {
      try {
        await axios
          .post(ADD_CUSTOMER_URL(business_id), JSON.stringify({ first_name }), {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
            withCredentials: true,
          })
          .then(async (response) => {
            const formData = {
              transaction: { customer_id: response?.data?.customer_id },
              transaction_details: { products: cart, services: service_cart },
            };
            const responses = await axios.post(
              ADD_TRANSACTION_URL(business_id),
              JSON.stringify(formData),
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "content-type": "application/json",
                },
                withCredentials: true,
                // params: {
                //   business_id: business_id,
                // },
              }
            );
            const transaction_id = responses?.data?.transaction.transaction_id;
            const customer_id = responses?.data?.transaction?.customer_id;
            window.localStorage.setItem("transaction_id", transaction_id);
            window.localStorage.setItem("customer_id", customer_id);
            setTransaction(responses?.data);
            setCustomer(customer_id);
            setSuccess(true);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      message.error("Please add product or service to proceed!");
    }
  };

  const onDeleteProduct = (item) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setCart(cart.filter((c) => c.product_id !== item.product_id));
        setCartDetail(
          cartdetail.filter((c) => c.product_id !== item.product_id)
        );
      },
    });
  };
  const onDeleteService = (item) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        setServiceCart(
          service_cart.filter((c) => c.service_id !== item.service_id)
        );
        setServiceCartDetail(
          serviceCartdetail.filter((c) => c.service_id !== item.service_id)
        );
      },
    });
  };

  const resetEditing = () => {
    setEditRecord(null);
    setEditRecordDetails(null);
    setIsEditing(false);
  };

  const onEditProduct = (item) => {
    setIsEditing(true);
    setEditRecord({ product_id: item.product_id, quantity: item.count });
    setEditRecordDetails({
      product_id: item.product_id,
      quantity: item.count,
      name: item.name,
      price: item.price,
    });
  };

  // const onEditProductAddd = (item) => {

  //   setCart((pre) => {
  //     return pre.map((record) => {
  //       if (
  //         record.product_id === item.product_id
  //       ) {
  //         return { product_id: item.product_id, quantity: parseInt(item.quantity) + 1 };
  //       } else {
  //         return record;
  //       }
  //     });
  //   });
  //   setCartDetail((pre) => {
  //     return pre.map((record) => {
  //       if (
  //         record.product_id ===
  //         item.product_id
  //       ) {
  //         return {
  //           product_id: item.product_id,
  //           quantity: parseInt(item.quantity) + 1,
  //           name: item.name,
  //           price: item.price,
  //           units_available: item.units_available
  //         };
  //       } else {
  //         return record;
  //       }
  //     });
  //   });
  //   console.log(cartdetail);
  // };
  const onEditProductAddd = (item, action) => {
    setCart((pre) => {
      return pre.map((record) => {
        if (record.product_id === item.product_id) {
          let newQuantity =
            action === "increment"
              ? parseInt(item.quantity) + 1
              : parseInt(item.quantity) - 1;
          newQuantity = Math.max(newQuantity, 0); // prevent negative quantity
          return { product_id: item.product_id, quantity: newQuantity };
        } else {
          return record;
        }
      });
    });
    setCartDetail((pre) => {
      return pre.map((record) => {
        if (record.product_id === item.product_id) {
          let newQuantity =
            action === "increment"
              ? parseInt(item.quantity) + 1
              : parseInt(item.quantity) - 1;
          newQuantity = Math.max(newQuantity, 0); // prevent negative quantity
          return {
            product_id: item.product_id,
            quantity: newQuantity,
            name: item.name,
            price: item.price,
            units_available: item.units_available,
          };
        } else {
          return record;
        }
      });
    });
  };
  const fetchCustomerData = useCallback(async () => {
    try {
      const response = await axios.get(GET_CUSTOMERS_URL(business_id), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [token, business_id, setCustomers]);
  useEffect(() => {
    if (business_id) {

      fetchCustomerData().catch(console.error);
    }
  }, [fetchCustomerData, business_id]);

  const handleCustomer = async (key) => {
    setCustomer(key);
  };
  const handleSubmit = async () => {
    try {
      await axios.post(
        ADD_CUSTOMER_URL(business_id),
        JSON.stringify({
          first_name,
          email,
          phone_no,
          last_name,
          gender,
          date_of_birth,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          withCredentials: true,
          // params: {
          //   business_id: business_id,
          // },
        }
      );
      fetchCustomerData(business_id);
      handleOk();
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg("Cant add customer");
      }
    }
  };
  if (success) {
    setCart([]);
    setServiceCart([]);
    return <Redirect to="/paymentcheckcart" />;
    // return <Redirect to="/Payment" />;
  }
  if (!IsLoggedIn) {
    return <Redirect to="/" />;
  }
  if (!IsBusiness) {
    return <Redirect to="/businesses" />;
  }

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]} className="dash-mobile-layout">
        <Col span={24}>
          {/* <Row span={24}>
                        <Col span={24}>
                            <Card>
                                <Col span={24}>
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col span={14}>
                                                <Select disabled={is_disabled}
                                                    showSearch
                                                    placeholder="Select a Customer"
                                                    optionFilterProp="children"
                                                    onChange={handleCustomer}
                                                    filterOption={(input, option) =>
                                                        option.value1.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                        option.value2.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                        option.value3.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                        option.value4.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {customers.map((c) => (

                                                        <Option key={c.customer_id} value1={c.phone_no} value2={c.email} value3={c.first_name} value4={c.last_name}>{c.first_name} {c.last_name}</Option>

                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col span={10}>
                                                <div className="header-col sign-in-btn">
                                                    <Button disabled={is_disabled}
                                                        type="primary"
                                                        shape="round"
                                                        style={{
                                                            background: "#45BC5B",
                                                            borderColor: "#45BC5B"
                                                        }}
                                                        onClick={showModal}
                                                    ><p style={{ color: '#fff' }}>Add Customer</p>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Modal okText="Exit" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                                                <Card className="criclebox ">
                                                    <Title className="font-regular text-muted" level={4}>
                                                        Add New Customer
                                                    </Title>
                                                    <p ref={errRef} className='Error-p'>
                                                        {errMsg}
                                                    </p>
                                                    <Form onFinish={handleSubmit}>
                                                        <div className="number">
                                                            <Row align="middle" gutter={[24, 0]}>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        className="username"
                                                                        label={<>First Name<span style={{ color: "red" }}>*</span></>}
                                                                        name="first_name"
                                                                        id="first_name"
                                                                        onChange={onChangeFirstName}
                                                                        value={first_name}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input First Name!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="First name" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={3}></Col>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        className="username"
                                                                        label={<>Last Name<span style={{ color: "red" }}>*</span></>}
                                                                        name="last_name"
                                                                        id="last_name"
                                                                        onChange={onChangeLastName}
                                                                        value={last_name}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input Last Name!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Last Name" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        className="username"
                                                                        label={<>Email<span style={{ color: "red" }}>*</span></>}
                                                                        name="email"
                                                                        id="email"
                                                                        onChange={onChangeEmail}
                                                                        value={email}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input Email!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Customer Email" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={3}></Col>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        className="username"
                                                                        label="Phone Number"
                                                                        name="phone_no"
                                                                        id="phone_no"
                                                                        onChange={onChangePhoneNo}
                                                                        value={phone_no}
                                                                    >
                                                                        <Input placeholder="Phone Number" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        className="username"
                                                                        label="Gender"
                                                                        name="gender"
                                                                        id="gender"
                                                                    >
                                                                        <Select
                                                                            placeholder="Select an Option"
                                                                            onChange={onChangeGender}
                                                                            allowClear
                                                                        >
                                                                            <Option value="Male">Male</Option>
                                                                            <Option value="Female">Female</Option>
                                                                            <Option value="Other">Other</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={3}></Col>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        className="username"
                                                                        label="Date of Birth"
                                                                        name="date_of_birth"
                                                                        id="date_of_birth"
                                                                        value={date_of_birth}
                                                                        style={{ width: 200 }}
                                                                    >
                                                                        <Space>
                                                                            <DatePicker onChange={onChangeDateOfBirth} />
                                                                        </Space>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Form.Item>
                                                            <div className="header-col sign-in-btn">
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    style={{ width: "100%", background: "#45BC5B" }}
                                                                    shape="round"
                                                                >
                                                                    ADD
                                                                </Button>
                                                            </div>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Modal>
                                        </Row>
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Row> */}

          <div>
            <br></br>
            <br></br>
          </div>
          <Input
            className="header-search"
            placeholder="Find and add products..."
            prefix={<AiOutlineSearch />}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          {products
            .filter((c) => {
              if (searchTerm === "") {
                return null;
              } else if (
                c.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return c;
              } else {
                return null;
              }
            })
            .slice(0, 2)
            .map((c) => (
              <SingleProduct
                c={c}
                key={c.id}
                cartdetail={cartdetail}
                setCartDetail={setCartDetail}
                setSearchTerm={setSearchTerm}
              />
            ))}
          {services
            .filter((c) => {
              if (searchTerm === "") {
                return null;
              } else if (
                c.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return c;
              } else {
                return null;
              }
            })
            .slice(0, 2)
            .map((c) => (
              <SingleService
                c={c}
                key={c.id}
                serviceCartdetail={serviceCartdetail}
                setServiceCartDetail={setServiceCartDetail}
                setSearchTerm={setSearchTerm}
              />
            ))}
          <div>
            <br></br>
            <br></br>
          </div>
          {(cartdetail.length > 0 || serviceCartdetail.length > 0) && (
            <>
              <Card className="product-list-card">
                <div className="number">
                  {cartdetail.length > 0 && (
                    <>
                      {cartdetail.map((item) => (
                        // <Row
                        //   align="middle"
                        //   gutter={[24, 0]}
                        //   key={item.product_id}
                        // >
                        <div
                          align="middle"
                          // gutter={[24, 0]}
                          style={{
                            marginBottom: 10,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          key={item.product_id}
                        >
                          <Row>
                            <Col span={12}>
                              <span
                                style={{
                                  backgroundColor: "#d9e8ff",
                                  color: "#3f5d8a",
                                  paddingRight: 8,
                                  paddingLeft: 8,
                                  borderRadius: 50,
                                  float: "left",
                                  fontSize: 14,
                                }}
                              >
                                {item.units_available}
                              </span>
                            </Col>
                            <Col span={12}>
                              <b
                                style={{
                                  color: "gray",
                                  float: "right",
                                  letterSpacing: 0.5,
                                }}
                              >
                                KES&nbsp;{item.price}
                              </b>
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col span={12}>
                              <div
                                style={{ fontSize: 20, float: "left" }}
                                className="sales-record-amount"
                              >
                                {item.name}
                              </div>
                            </Col>
                            <Col span={12} className="sales-record-amount">
                              <div
                                style={{ cursor: "pointer" }}
                                className="d-flex"
                              >
                                <div className="addcartBtnLeft">
                                  <div
                                    className="addcartBtnRightItem"
                                    onClick={() =>
                                      onEditProductAddd(item, "decrement")
                                    }
                                  >
                                    -
                                  </div>
                                </div>

                                <div
                                  className="addcartBtnLeft"
                                  onClick={() =>
                                    onEditProductAddd(item, "increment")
                                  }
                                >
                                  ADD
                                </div>
                                <div className="addcartBtnRight">
                                  <div
                                    className="addcartBtnRightItem"
                                    onClick={() =>
                                      onEditProductAddd(item, "increment")
                                    }
                                  >
                                    + {item.quantity}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div
                            style={{
                              backgroundColor: "#E6E6E6",
                              height: 0.2,
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          ></div>

                          {/* <Col span={8}>
                            <Title level={5}>{item.name}</Title>
                          </Col>
                          <Col span={10}>
                            <p>
                              {item.quantity} x <b>KES</b> {item.price}
                              <span>.00</span>
                            </p>
                          </Col> */}
                          {/* <Col span={3}>
                            <AiOutlineEdit
                              onClick={() => {
                                onEditProduct(item);
                              }}
                              style={{ color: "#45BC5B" }}
                            />
                          </Col> */}
                          {/* <Col span={3}>
                            <AiOutlineDelete
                              onClick={() => {
                                onDeleteProduct(item);
                              }}
                              style={{ color: "red" }}
                            />
                          </Col> */}

                          {/* <p>{countErr}</p>
                    <Col span={12}>
                        <div className="sales-btn">
                            <Tag color={item.units_available > 5 ? "blue" : "red"} shape="circle">{item.units_available}</Tag>
                        </div>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <span className="span-large">KES {item.price}.00</span>
                    </Col>
                    <Col span={12} className="sales-record-amount">
                        <p style={{ marginBottom: "2px" }}> {item.name} </p>
                    </Col> */}
                          {/* <Col span={12} className="sales-record-amount">
                            <div className="sales-btn">



                              <div style={{ cursor: "pointer" }} className="d-flex">

                                <div className="addcartBtnLeft" onClick={() => onEditProductAddd(item)}>ADD</div>
                                <div className="addcartBtnRight">+ {item.quantity} </div>

                              </div>
                              <Button
                                // onClick={handleClick}
                                type="primary"
                                shape="round"
                                style={{
                                  background: "#f4fbfe",
                                  borderColor: "#f4fbfe"
                                }}
                                disabled={item.units_available === 0 ? true : false}
                              >

                                <p style={{
                                  color: '#1DAFED',

                                }}>Add <span style={{ marginLeft: "1rem", borderRadius: 5, borderWidth: 3, borderColor: "#000" }}>+ 1</span></p>
                              </Button>
                            </div>
                          </Col> */}
                        </div>
                      ))}
                    </>
                  )}
                  {serviceCartdetail.length > 0 && (
                    <>
                      {serviceCartdetail.map((item) => (
                        <Row
                          align="middle"
                          gutter={[24, 0]}
                          key={item.service_id}
                        >
                          <Col span={8}>
                            <Title level={5}>{item.name}</Title>
                          </Col>
                          <Col span={10}>
                            <p>
                              {item.quantity} x <b>KES</b> {item.price}
                              <span>.00</span>
                            </p>
                          </Col>
                          <Col span={3}>
                            <AiOutlineEdit style={{ color: "gray" }} />
                          </Col>
                          <Col span={3}>
                            <AiOutlineDelete
                              onClick={() => {
                                onDeleteService(item);
                              }}
                              style={{ color: "red" }}
                            />
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </div>
              </Card>
            </>
          )}
          <div>
            <br></br>
            <br></br>
          </div>
          <Col span={24} className="header-col payment-button">
            <Button
              onClick={() => handleTransaction()}
              type="primary"
              htmlType="submit"
              style={{ width: "100%", background: "#1DAFED" }}
              shape="round"
            >
              Proceed to Payment
            </Button>
          </Col>
          <Row>
            <div>
              <br></br>
              <br></br>
              <br></br>
            </div>
            <Col span={22} offset={1}>
              <Row className="payment-row mobile-footer">
                <Col span={7}>
                  <Col span={24} className="sales-record-amount ">
                    <div
                      style={{
                        color: "gray",
                        fontWeight: "bolder",
                        fontSize: 15,
                        letterSpacing: 1,
                        marginTop: 2,
                      }}
                    >
                      <center>Totals</center>
                    </div>
                  </Col>
                </Col>
                <Col span={1}>
                  <div
                    style={{
                      height: "100%",
                      width: 0.8,
                      backgroundColor: "#dbd0d0",
                    }}
                  ></div>
                </Col>

                <Col span={7}>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={6}>
                        <AiOutlineShopping size={25} color="#45BC5B" />
                      </Col>
                      <Col span={18} className="sales-record-amount">
                        <div
                          style={{
                            color: "#45BC5B",
                            fontWeight: "bolder",
                            fontSize: 15,
                            letterSpacing: 1,
                          }}
                        >
                          {cartdetail.length + serviceCartdetail.length} ITEMS
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={1}>
                  <div
                    style={{
                      height: "100%",
                      width: 0.8,
                      backgroundColor: "#dbd0d0",
                      marginLeft: 10,
                    }}
                  ></div>
                </Col>
                <Col span={8}>
                  <Col span={24} className="sales-record-amount ">
                    <center>
                      <div
                        style={{
                          color: "#45BC5B",
                          fontWeight: "bolder",
                          fontSize: 15,
                          letterSpacing: 1,
                          marginTop: 3,
                        }}
                      >
                        {" "}
                        KES {product_total + service_total}.00
                      </div>
                    </center>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="dash-web-layout">
        <Space direction="vertical" size="large">
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={24}>
                <Title level={3}>Add a Sale</Title>
              </Col>
            </Row>
          </div>

          <Form layout="vertical" className="row-col">
            {/* <Row gutter={[24, 0]}><Col span={24} md={2} lg={2} className="mb-24 "></Col>
                            <Col span={20} md={20} lg={20} className="mb-24 ">
                                <Card bordered={false} className="criclebox ">
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col span={24}>
                                                <Avatar shape="circle" icon={<RiNumber1 />} />
                                                <Title level={4}>Select Customer</Title>
                                            </Col>
                                            <Col span={24}>
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={14}>
                                                            <Select
                                                                showSearch
                                                                width="200px"
                                                                placeholder="Select a Customer"
                                                                optionFilterProp="children"
                                                                onChange={handleCustomer}
                                                                filterOption={(input, option) =>
                                                                    option.value1.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option.value2.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option.value3.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option.value4.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                style={{ width: 200 }}
                                                            >
                                                                {customers.map((c) => (

                                                                    <Option key={c.customer_id} value1={c.phone_no} value2={c.email} value3={c.first_name} value4={c.last_name}>{c.first_name} {c.last_name}</Option>

                                                                ))}
                                                            </Select>
                                                        </Col>
                                                        <Col span={10}>
                                                            <div className="header-col sign-in-btn">
                                                                <Button
                                                                    type="primary"
                                                                    shape="round"
                                                                    onClick={showModal}
                                                                    style={{ background: "#45BC5B", borderColor: "#45BC5B" }}
                                                                ><p style={{ color: '#fff' }}>Add Customer</p>
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={24} md={2} lg={2} className="mb-24 "></Col>
                        </Row> */}

            <Row gutter={[24, 0]}>
              <Col span={24} md={2} lg={2} className="mb-24 "></Col>
              <Col span={24} md={20} lg={20} className="mb-24 ">
                <Card bordered={false} className="criclebox ">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={20}>
                        <Avatar shape="circle" icon={<RiNumber1 />} />
                        <Title level={4}>Select and Add Product</Title>
                      </Col>
                      <Col span={20}>
                        <Input
                          className="header-search"
                          placeholder="Find and add products..."
                          prefix={<AiOutlineSearch />}
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                        />
                        {products
                          .filter((c) => {
                            if (searchTerm === "") {
                              return null;
                            } else if (
                              c.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return c;
                            } else {
                              return null;
                            }
                          })
                          .slice(0, 3)
                          .map((c) => (
                            <SingleProduct
                              c={c}
                              key={c.id}
                              cartdetail={cartdetail}
                              setCartDetail={setCartDetail}
                              setSearchTerm={setSearchTerm}
                            />
                          ))}
                        {services
                          .filter((c) => {
                            if (searchTerm === "") {
                              return null;
                            } else if (
                              c.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return c;
                            } else {
                              return null;
                            }
                          })
                          .slice(0, 3)
                          .map((c) => (
                            <SingleService
                              c={c}
                              key={c.id}
                              serviceCartdetail={serviceCartdetail}
                              setServiceCartDetail={setServiceCartDetail}
                              setSearchTerm={setSearchTerm}
                            />
                          ))}
                      </Col>
                      <Col span={20}>
                        <div>
                          <br></br>
                          <br></br>
                        </div>
                        {(cartdetail.length > 0 ||
                          serviceCartdetail.length > 0) && (
                            <>
                              <Title level={5} style={{ color: "#" }}>
                                Selected Products/Services List
                              </Title>
                              <Card className="product-list-card">
                                <div className="number">
                                  {cartdetail.length > 0 && (
                                    <>
                                      {cartdetail.map((item) => (
                                        <Row
                                          align="middle"
                                          gutter={[24, 0]}
                                          key={item.product_id}
                                        >
                                          <Col span={8}>
                                            <Title level={5}>{item.name}</Title>
                                          </Col>
                                          <Col span={10}>
                                            <p>
                                              {item.quantity} x <b>KES</b>{" "}
                                              {item.price}
                                              <span>.00</span>
                                            </p>
                                          </Col>
                                          <Col span={3}>
                                            <AiOutlineEdit
                                              onClick={() => {
                                                onEditProduct(item);
                                              }}
                                              style={{ color: "#45BC5B" }}
                                            />
                                          </Col>
                                          <Col span={3}>
                                            <AiOutlineDelete
                                              onClick={() => {
                                                onDeleteProduct(item);
                                              }}
                                              style={{ color: "red" }}
                                            />
                                          </Col>
                                        </Row>
                                      ))}
                                    </>
                                  )}
                                  {serviceCartdetail.length > 0 && (
                                    <>
                                      {serviceCartdetail.map((item) => (
                                        <Row
                                          align="middle"
                                          gutter={[24, 0]}
                                          key={item.service_id}
                                        >
                                          <Col span={8}>
                                            <Title level={5}>{item.name}</Title>
                                          </Col>
                                          <Col span={10}>
                                            <p>
                                              {item.quantity} x <b>KES</b>{" "}
                                              {item.price}
                                              <span>.00</span>
                                            </p>
                                          </Col>
                                          <Col span={3}>
                                            <AiOutlineEdit
                                              style={{ color: "gray" }}
                                            />
                                          </Col>
                                          <Col span={3}>
                                            <AiOutlineDelete
                                              onClick={() => {
                                                onDeleteService(item);
                                              }}
                                              style={{ color: "red" }}
                                            />
                                          </Col>
                                        </Row>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </Card>
                            </>
                          )}
                        <Modal
                          title="Change the quantity"
                          okText="Save"
                          visible={IsEditing}
                          onCancel={() => {
                            resetEditing();
                          }}
                          onOk={() => {
                            console.log(editRecord);
                            console.log(editRecordDetails);
                            setCart((pre) => {
                              return pre.map((record) => {
                                if (
                                  record.product_id === editRecord.product_id
                                ) {
                                  return editRecord;
                                } else {
                                  return record;
                                }
                              });
                            });
                            setCartDetail((pre) => {
                              return pre.map((record) => {
                                if (
                                  record.product_id ===
                                  editRecordDetails.product_id
                                ) {
                                  return editRecordDetails;
                                } else {
                                  return record;
                                }
                              });
                            });
                            resetEditing();
                          }}
                        >
                          <Input
                            value={editRecord?.quantity}
                            onChange={(e) => {
                              setEditRecord((pre) => {
                                return {
                                  ...pre,
                                  quantity: Number(e.target.value),
                                };
                              });
                              setEditRecordDetails((pre) => {
                                return {
                                  ...pre,
                                  quantity: Number(e.target.value),
                                };
                              });
                            }}
                          />
                        </Modal>
                        <div>
                          <br></br>
                          <br></br>
                        </div>
                        <Row className="payment-row">
                          <Col span={8}>
                            <Col span={24} className="sales-record-amount ">
                              <h1>Totals</h1>
                            </Col>
                          </Col>
                          <Col span={8}>
                            <div className="number">
                              <Row align="middle" gutter={[24, 0]}>
                                <Col span={6}>
                                  <AiOutlineShopping
                                    size={30}
                                    color="#45BC5B"
                                  />
                                </Col>
                                <Col span={18} className="sales-record-amount ">
                                  <h2>
                                    {cartdetail.length +
                                      serviceCartdetail.length}{" "}
                                    items
                                  </h2>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col span={8}>
                            <Col span={24} className="sales-record-amount ">
                              <h2>kes {product_total + service_total}.00</h2>
                            </Col>
                          </Col>
                        </Row>
                        <>
                          <br></br>
                        </>
                        <Col span={18} offset={4}>
                          <div className="header-col payment-button">
                            <Button
                              onClick={() => handleTransaction()}
                              type="primary"
                              htmlType="submit"
                              style={{ width: "100%", background: "#1DAFED" }}
                              shape="round"
                            >
                              <p style={{ color: "#ffff" }}>
                                Proceed to Payment
                              </p>
                            </Button>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col span={24} md={2} lg={2} className="mb-24 "></Col>
            </Row>
          </Form>
        </Space>
      </Row>
    </div>
  );
};

export default AddSale;
