import {
    Button, Card, Col, Descriptions, Divider, Modal, Row, Space, Typography
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MdOutlineAddBusiness } from 'react-icons/md';
import { Link, Redirect } from "react-router-dom";
import axios from '../api/axios';
import Footer from '../components/layout/Footer/FooterMobile';
import AuthContext from "../context/AuthProvider";
const BUSINESS_URL = 'business/'

const Businesses = () => {
    const token = window.localStorage.getItem("Bearer");
    const { setBusiness } = useContext(AuthContext)
    const { Title } = Typography;
    const [businesses, setBusinesses] = useState([])
    const [success, setSuccess] = useState(false)

    const fetchBusiness = useCallback(async () => {
        try {
            const response = await axios.get(BUSINESS_URL, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                withCredentials: true
            })
            setBusinesses(response.data)
        }
        catch (err) {
            console.log(err)
        }
    }, [token])
    useEffect(() => {
        fetchBusiness().catch(console.error)
    }, [fetchBusiness])
    const onChangeBusiness = (key) => {
        window.localStorage.setItem("business_id", key)
        const fetchBusinessData = async () => {
            try {
                const response = await axios.get(`${BUSINESS_URL}${key}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                })
                setBusinesses(response?.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchBusinessData()
        setSuccess(true)
    };
    const deleteBusiness = async (id) => {
        try {
            await axios.delete(`${BUSINESS_URL}${id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            fetchBusiness()
        } catch (error) {
            console.log(error)
        }
    }
    const onDeleteBusiness = async (id) => {
        Modal.confirm({
            title: "Are you sure you want to delete?",
            okText: "Yes", okType: "danger",
            onOk: () => {
                deleteBusiness(id)
            }
        })
    }

    if (success) {
        return <Redirect to="/dashboard" />
    }
    if (!token) {
        return <Redirect to="/" />
    }


    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                {businesses.length === 0 &&
                    <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                        <Row align="middle" gutter={[24, 0]} span={24}>
                            <Col span={14}>
                                <h6><b>You do not have any Business</b></h6>
                            </Col>
                            <Col span={10}>
                                <div className="log-btn">
                                    <Link to="/add-business">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                background: "#45BC5B",
                                                borderColor: "#45BC5B"
                                            }}
                                        ><p style={{ color: '#fff' }}>Add Business</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Card>}
                {businesses.length > 0 &&
                    <>
                        <Col style={{ padding: 12 }} xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 24 }}>
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]} span={24}>
                                        <Col span={8}>
                                            <Title level={3}>
                                                {businesses.length}
                                            </Title>
                                            <p className="card-p">Businesses</p>
                                        </Col>
                                        <Col span={10} offset={6}>
                                            <div className="log-btn">
                                                <Link to="/add-business">
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        style={{
                                                            background: "#45BC5B",
                                                            borderColor: "#45BC5B"
                                                        }}
                                                    ><p style={{ color: '#fff' }}>Add Business</p>
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col style={{ padding: 12 }} xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 24 }}>
                            <Space direction="vertical" size={20}>
                                {businesses.map((c) => (
                                    <Row gutter={[24, 0]} className="dash-mobile-layout" key={c.business_id}>
                                        <Col span={24}>
                                            <Card bordered={false} className="criclebox " >
                                                <Row align="middle" gutter={[24, 0]}>
                                                    <Col span={24}>
                                                        <Title level={4}>
                                                            {c.name}
                                                        </Title>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p className='businesses-list'>{c.city}, {c.country}</p>
                                                    </Col>
                                                    <Col span={7}>
                                                        <div className="log-btn">
                                                            <Button
                                                                onClick={() => onChangeBusiness(c.business_id)}
                                                                type="primary"
                                                                shape="round"
                                                                style={{
                                                                    background: "#45BC5B",
                                                                    borderColor: "#45BC5B"
                                                                }}
                                                            ><p style={{ color: '#fff' }}>Select Business</p>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                    <Col span={6} offset={2}>
                                                        <div className="log-btn">
                                                            <Button
                                                                onClick={() => onDeleteBusiness(c.business_id)}
                                                                type="primary"
                                                                shape="round"
                                                                style={{
                                                                    background: "#e02d1e",
                                                                    borderColor: "#e02d1e"
                                                                }}
                                                            ><p style={{ color: '#fff' }}>Delete</p>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                ))}
                            </Space>
                        </Col>
                    </>}
                <Footer />
            </Row>

            <Row className="dash-web-layout">
                <Col span={24} >
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={20}>
                                <Title level={3}>Businesses Overview</Title>
                            </Col>
                            <Col span={4}>
                                <div className="log-btn">
                                    <Link to="/add-business">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            style={{
                                                marginLeft: "auto",
                                                background: "#ffff",
                                                borderColor: "#1DAFED"
                                            }}
                                        ><p style={{ color: '#1DAFED' }}>Add Business</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Divider dashed="true" />
                {businesses.length === 0 &&
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={24} lg={24} className="mb-24 ">
                            <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col span={12}>
                                            <Descriptions>
                                                <Descriptions.Item>You do not have any Business recorded.<br></br>
                                                    Get started to experience automatic sychronization for your business
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Col span={12}>
                                            <Col span={24} md={16} lg={16} className="mb-24 " style={{
                                                marginBottom: "-150px",
                                                left: "100px",
                                                position: "relative"
                                            }}>
                                                <Card bordered={false} className="criclebox ">
                                                    <div className="number">
                                                        <Row align="middle" gutter={[24, 0]}>
                                                            <Col span={24}>
                                                                <Title level={5}>
                                                                    Business
                                                                </Title><br></br>
                                                            </Col>
                                                            <Col span={24}>
                                                                <div className="number">
                                                                    <Row align="middle" gutter={[24, 0]}>
                                                                        <Col span={6} className="sales-record-amount">
                                                                            <Col span={6} style>
                                                                                <MdOutlineAddBusiness size={30} />
                                                                            </Col>
                                                                        </Col>
                                                                        <Col span={18}>
                                                                            <h6>Add business to experience automatic sychronization.</h6>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <div>
                                                                <br></br>
                                                            </div>
                                                            <Col xs={24} md={24} className="d-flex">
                                                                <div className="log-btn">
                                                                    <Link to="/add-business">
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            style={{
                                                                                background: "#1DAFED",
                                                                                borderColor: "#1DAFED"
                                                                            }}
                                                                        ><p style={{ color: '#fff' }}>Add Business</p>
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>

                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                }
                {businesses.map((c) => (
                    <Col span={24} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} className="mb-24 ">
                        <Card bordered={false} key={c.business_id}>
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={24}>
                                        <Title level={4}>
                                            {c.name}
                                        </Title>
                                    </Col>
                                    <><br></br></>
                                    <Col span={10}>
                                        <span>
                                            {c.city}
                                        </span>
                                    </Col>
                                    <Col span={10} offset={2}>
                                        <span>{c.country}</span>
                                    </Col>
                                    <Col span={10}>
                                        <div className="log-btn">
                                            <Button
                                                onClick={() => onChangeBusiness(c.business_id)}
                                                type="primary"
                                                shape="round"
                                                style={{
                                                    background: "#1DAFED",
                                                    borderColor: "#1DAFED"
                                                }}
                                            ><p style={{ color: '#fff' }}>Select Business</p>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col span={8} offset={6}>
                                        <div className="log-btn">
                                            <Button
                                                onClick={() => onDeleteBusiness(c.business_id)}
                                                type="primary"
                                                shape="round"
                                                style={{
                                                    background: "#e02d1e",
                                                    borderColor: "#e02d1e"
                                                }}
                                            ><p style={{ color: '#fff' }}>Delete</p>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}

            </Row>
        </div>
    )
};

export default Businesses;
