import { Layout, Row, Col } from "antd";

const Footer = () =>{
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">Reja</li>&nbsp;&nbsp;&nbsp;
              <li className="nav-item"> About Us</li>&nbsp;&nbsp;&nbsp;
              <li className="nav-item"> News Letter</li>&nbsp;&nbsp;&nbsp;
              <li className="nav-item">Career</li>&nbsp;&nbsp;&nbsp;
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
