import {
    Avatar, Button, Card, Col, Form,
    Input, Row, Space, Typography
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { RiNumber1 } from "react-icons/ri";
import { Redirect } from "react-router-dom";
import axios from '../api/axios';
const ADD_BUSINESS_URL = 'business/';

const { Title } = Typography;

const AddBusiness = () => {
    const errRef = useRef(null);
    const token = window.localStorage.getItem("Bearer")
    const IsLoggedIn = window.localStorage.getItem("Bearer")

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone_no, setPhoneNo] = useState('');
    const [url, setUrl] = useState('');
    const [sector, setSector] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setErrMsg('');
    }, [name, email, phone_no])

    const onChangeName = (e) => {
        const name = e.target.value;
        setName(name);
    };
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangePhoneNo = (e) => {
        const phone_no = e.target.value;
        setPhoneNo(phone_no);
    };
    const onChangeUrl = (e) => {
        const url = e.target.value;
        setUrl(url);
    };
    const onChangeSector = (e) => {
        const sector = e.target.value;
        setSector(sector);
    };
    const onChangeCity = (e) => {
        const city = e.target.value;
        setCity(city);
    };
    const onChangeCountry = (e) => {
        const country = e.target.value;
        setCountry(country);
    };

    const handleSubmit = async () => {
        console.warn(name, phone_no, city, email)

        try {
            const response = await axios.post(ADD_BUSINESS_URL,
                JSON.stringify({ name, email, phone_no, city, country, url, sector }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true
                }
            );
            const business_id = response?.data?.business_id
            window.localStorage.setItem("business_id", business_id);
            setSuccess(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add business');
            }
            errRef.current.focus();
        }
    }

    if (success) {
        return <Redirect to="/businesses" />
    }
    if (!IsLoggedIn) {
        return <Redirect to="/" />
    }
    return (
        <div className="layout-content">
            <Row gutter={[24, 0]} className="dash-mobile-layout">
                <Col xs={24} md={12} className="col-info">
                    <Card bordered={false} className="criclebox ">
                        <Title className="font-regular text-muted" level={4}>
                            Add Business Details here
                        </Title>
                        <p ref={errRef} className='Error-p'>
                            {errMsg}
                        </p>
                        <Form
                            onFinish={handleSubmit}
                            layout="vertical"
                            className="row-col"
                        >
                            <Form.Item
                                className="username"
                                label={<>Business Name<span style={{ color: "red" }}>*</span></>}
                                name="name"
                                id="name"
                                onChange={onChangeName}
                                value={name}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Business name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Business name" />
                            </Form.Item>

                            <Form.Item
                                className="username"
                                label={<>Business Email<span style={{ color: "red" }}>*</span></>}
                                name="email"
                                id="email"
                                onChange={onChangeEmail}
                                value={email}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Business Email!",
                                    }, { type: "email" }
                                ]}
                            >
                                <Input placeholder="Business Email" />
                            </Form.Item>

                            <Form.Item
                                className="username"
                                label={<>Business Phone Number<span style={{ color: "red" }}>*</span></>}
                                name="phone_no"
                                id="phone_no"
                                onChange={onChangePhoneNo}
                                value={phone_no}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Business Phone Number!",
                                    },
                                ]}
                            >
                                <Input placeholder="Business Phone Number" />
                            </Form.Item>

                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={12}>
                                        <Form.Item
                                            className="username"
                                            label="URL"
                                            name="url"
                                            id="url"
                                            // ref={userRef}
                                            onChange={onChangeUrl}
                                            value={url}
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="URL" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            className="username"
                                            label="Sector"
                                            name="sector"
                                            id="sector"
                                            // ref={userRef}
                                            onChange={onChangeSector}
                                            value={sector}
                                            rules={[
                                                {
                                                    required: false
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Sector" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col span={12}>
                                        <Form.Item
                                            className="username"
                                            label="City"
                                            name="city"
                                            id="city"
                                            onChange={onChangeCity}
                                            value={city}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please input City!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="City" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            className="username"
                                            label="Country"
                                            name="country"
                                            id="country"
                                            onChange={onChangeCountry}
                                            value={country}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please input Country!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Country" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <Form.Item>
                                <div className="header-col sign-in-btn">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%", background: "#45BC5B" }}
                                        shape="round"
                                    >
                                        ADD
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Row className="dash-web-layout">
                <Space direction="vertical" size='large'>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={24}>
                                <Title level={3}>Add Business</Title>
                            </Col>
                        </Row>
                    </div>

                    <Form onFinish={handleSubmit}
                        layout="vertical"
                        className="row-col">

                        <Row gutter={[24, 0]}>
                            <Col span={24} md={20} lg={20} className="mb-24 ">
                                <Card bordered={false} className="criclebox ">
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col span={20}>
                                                <Avatar shape="circle" icon={<RiNumber1 />} />
                                                <Title level={4}>Enter Details</Title>
                                            </Col>
                                            <Col span={20}>
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                className="username"
                                                                label={<>Business Name<span style={{ color: "red" }}>*</span></>}
                                                                name="name"
                                                                id="name"
                                                                onChange={onChangeName}
                                                                value={name}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input Business name!",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Business name" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>

                                                            <Form.Item
                                                                className="username"
                                                                label={<>Business Email<span style={{ color: "red" }}>*</span></>}
                                                                name="email"
                                                                id="email"
                                                                onChange={onChangeEmail}
                                                                value={email}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input Business Email!",
                                                                    }, { type: "email" }
                                                                ]}
                                                            >
                                                                <Input placeholder="Business Email" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                className="username"
                                                                label={<>Business Phone Number<span style={{ color: "red" }}>*</span></>}
                                                                name="phone_no"
                                                                id="phone_no"
                                                                onChange={onChangePhoneNo}
                                                                value={phone_no}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input Business Phone Number!",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Business Phone Number" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                className="username"
                                                                label="URL"
                                                                name="url"
                                                                id="url"
                                                                onChange={onChangeUrl}
                                                                value={url}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="URL" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <div className="number">
                                                    <Row align="middle" gutter={[24, 0]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                className="username"
                                                                label="Sector"
                                                                name="sector"
                                                                id="sector"
                                                                onChange={onChangeSector}
                                                                value={sector}
                                                                rules={[
                                                                    {
                                                                        required: false
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Sector" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                className="username"
                                                                label="City"
                                                                name="city"
                                                                id="city"
                                                                onChange={onChangeCity}
                                                                value={city}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message: "Please input City!",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="City" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    className="username"
                                                    label="Country"
                                                    name="country"
                                                    id="country"
                                                    onChange={onChangeCountry}
                                                    value={country}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please input Country!",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Country" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item>
                                                    <div className="header-col sign-in-btn">
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            style={{ width: "70%", marginTop: "50px", background: "#45BC5B" }}
                                                            shape="round"
                                                        >
                                                            <p style={{ color: "#ffff" }}>ADD</p>
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Space>

            </Row>
        </div >
    )
};

export default AddBusiness;
