import {
    Row,
    Col,
    Button,
    Typography,
    Card,
    Descriptions
} from 'antd'
import { GiExpense } from "react-icons/gi"
import { Link } from 'react-router-dom'
const { Title } = Typography

const NoExpenseCard = () => {
    return (
        <Row gutter={[24, 0]}>
            <Col span={24} md={24} lg={24} className="mb-24 ">
                <Card bordered={false} className="criclebox " style={{ background: "#fef5f5" }}>
                    <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col span={12}>
                                <Descriptions >
                                    <Descriptions.Item>You do not have any Expense recorded.<br></br>
                                        Get started to experience automatic sychronization for your business
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col span={12}>
                                <Col span={24} md={16} lg={16} className="mb-24 " style={{
                                    marginBottom: "-150px",
                                    left: "100px",
                                    position: "relative"
                                }}>
                                    <Card bordered={false} className="criclebox ">
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col span={24}>
                                                    <Title level={5}>
                                                        Expenses
                                                    </Title><br></br>
                                                </Col>
                                                <Col span={24}>
                                                    <div className="number">
                                                        <Row align="middle" gutter={[24, 0]}>
                                                            <Col span={6} className="sales-record-amount">
                                                                <Col span={6} style>
                                                                    <GiExpense size={30} />
                                                                </Col>
                                                            </Col>
                                                            <Col span={18}>
                                                                <h6>Add business Expenses.</h6>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <div>
                                                    <br></br>
                                                </div>
                                                <Col xs={24} md={24} className="d-flex">
                                                    <div className="log-btn">
                                                        <Link to="/add-expense">
                                                            <Button
                                                                type="primary"
                                                                shape="round"
                                                                style={{
                                                                    background: "#1DAFED",
                                                                    borderColor: "#1DAFED"
                                                                }}
                                                            ><p style={{ color: '#fff' }}>Add Expense</p>
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>

                            </Col>
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default NoExpenseCard