import { Card, Form, Input, message, Modal, Typography } from 'antd';
import { useRef, useState } from 'react';
import axios from '../../../api/axios';
const EXPENSE_CATEGORY_URL = (business_id) => `business/${business_id}/expense/category/`;

const { Title } = Typography
const ExpenseCategory = ({ isModalVisible, setIsModalVisible, fetchCategoryData }) => {
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    const errRef = useRef(null)
    const [category_name, setCategoryName] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const onChangeCategoryName = (e) => {
        const category_name = e.target.value;
        setCategoryName(category_name);
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    }
    const handleOk = () => {
        handleSubmit()
    }

    const handleSubmit = async () => {
        try {
            await axios.post(EXPENSE_CATEGORY_URL(business_id),
                JSON.stringify({ name: category_name }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            message.success('You have successfully added a new category', 3)
            fetchCategoryData()
            setIsModalVisible(false)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add Category');
            }
            errRef.current.focus();
        }
    }

    return (
        <Modal visible={isModalVisible} okText="Add" onOk={handleOk} onCancel={handleCancel} >
            <Card className="criclebox ">
                <Title className="font-regular text-muted" level={4}>
                    Add Category Details here
                </Title>
                <p ref={errRef} className='Error-p'>
                    {errMsg}
                </p>
                <Form>
                    <Form.Item
                        className="username"
                        label="Category Name"
                        name="name"
                        id="name"
                        onChange={onChangeCategoryName}
                        value={category_name}
                        rules={[
                            {
                                required: true,
                                message: "Please input Category Name!",
                            },
                        ]}
                    >
                        <Input placeholder="Category Name" />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    )
}

export default ExpenseCategory
