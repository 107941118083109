import {
    Button, Card, Col, DatePicker, Divider, Dropdown, Form,
    Input, Menu, Modal, Radio, Row, Space, Table, Typography
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from '../api/axios';
const AMOUNT_DISCOUNT_URL = (business_id) => `business/${business_id}/discount/amount/`;
const PERCENT_DISCOUNT_URL = (business_id) => `business/${business_id}/discount/percent/`;

const { Title } = Typography
const { RangePicker } = DatePicker;

const Discount = () => {
    const errRef = useRef(null);
    const token = window.localStorage.getItem("Bearer");
    const business_id = window.localStorage.getItem("business_id")
    const [isModalVisible, setIsModalVisible] = useState(false)

    const [discount, setDiscount] = useState([])
    const [discountP, setDiscountP] = useState([])
    const [valid_from, setValidFrom] = useState('')
    const [valid_to, setValidTo] = useState('')
    const [amount, setAmount] = useState('')
    const [percent, setPercent] = useState('')
    const [name, setName] = useState('');
    const [errMsg, setErrMsg] = useState('')
    const [value, setValue] = useState(1)
    const [keys, setKeys] = useState(1)
    const [isEditing, setIsEditing] = useState(false)
    const [editDiscount, setEditDiscount] = useState(null)
    const [isEditingDp, setIsEditingDp] = useState(false)
    const [editDiscountP, setEditDiscountP] = useState(null)

    const columns = [
        {
            title: (<p className="table-titles">Name</p>),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (<p className="table-titles">Amount</p>),
            dataIndex: 'amount',
            key: 'amount ',
        },
        {
            title: (<p className="table-titles">Valid From</p>),
            dataIndex: 'valid_from',
            key: 'valid_from ',
        },
        {
            title: (<p className="table-titles">Valid To</p>),
            dataIndex: 'valid_to',
            key: 'valid_to ',
        },
        {
            title: (<p className="table-titles">Action</p>),
            key: 'action',
            render: (record) => (
                <div className="sales-btn">
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickDiscount(record)
                        }}
                        style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>Edit</p>
                    </Button>
                </div>
            ),
        }

    ];
    const percentage_columns = [
        {
            title: (<p className="table-titles">Name</p>),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: (<p className="table-titles">Percent</p>),
            dataIndex: 'percent',
            key: 'percent ',
        },
        {
            title: (<p className="table-titles">Valid From</p>),
            dataIndex: 'valid_from',
            key: 'valid_from ',
        },
        {
            title: (<p className="table-titles">Valid To</p>),
            dataIndex: 'valid_to',
            key: 'valid_to ',
        },
        {
            title: (<p className="table-titles">Action</p>),
            key: 'action',
            render: (record) => (
                <div className="sales-btn">
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            onClickDiscountP(record)
                        }}
                        style={{
                            background: "#dcf0f9",
                            borderColor: "#dcf0f9"
                        }}
                    ><p style={{ color: '#1DAFED' }}>Edit</p>
                    </Button>
                </div>
            ),
        }

    ]
    const onClickDiscount = (record) => {
        setIsEditing(true)
        setEditDiscount({ ...record })
    }

    const resetEditing = () => {
        setIsEditing(false)
        setEditDiscount(null)
    }
    const onClickDiscountP = (record) => {
        setIsEditingDp(true)
        setEditDiscountP({ ...record })
    }

    const resetEditingDp = () => {
        setIsEditingDp(false)
        setEditDiscountP(null)
    }
    const showModal = (key) => {
        setKeys(Number(key.key))
        setIsModalVisible(true);
    };
    const menu = (
        <Menu>
            <Menu.Item key={1} onClick={showModal}>
                <p>Add Amount Discount</p>
            </Menu.Item>
            <Menu.Item key={2} onClick={showModal}>
                <p>Add Percent Discount</p>
            </Menu.Item>
        </Menu>
    )
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setErrMsg('');
    }, [name, amount, valid_from, valid_to])

    const onChangeName = (e) => {
        const name = e.target.value;
        setName(name);
    };
    const onChangeAmount = (e) => {
        const amount = e.target.value;
        setAmount(amount);
    }
    const onChangeAmountP = (e) => {
        const percent = e.target.value;
        setPercent(percent);
    }
    const onChangeDate = (date, dateString) => {
        const dates = dateString
        setValidFrom(dates[0])
        setValidTo(dates[1])
    }

    const patchDiscountData = async (data) => {
        try {
            await axios.patch(`${AMOUNT_DISCOUNT_URL(business_id)}${data?.discount_amount_id}`,
                JSON.stringify({ name: data?.name, amount: data?.amount, valid_from: data?.valid_from, valid_to: data?.valid_to }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // }
                })
            fetchDiscountData()
        } catch (error) {
            console.log(error)
        }
    }
    const patchDiscountPData = async (data) => {
        try {
            await axios.patch(`${PERCENT_DISCOUNT_URL(business_id)}${data?.discount_percent_id}`,
                JSON.stringify({ name: data?.name, percent: data?.percent, valid_from: data?.valid_from, valid_to: data?.valid_to }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    // params: {
                    //     "business_id": business_id
                    // }
                })
            fetchDiscountPData()
        } catch (error) {
            console.log(error)
        }
    }
    const fetchDiscountData = useCallback(async () => {
        try {
            const response = await axios.get(PERCENT_DISCOUNT_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setDiscount(response.data.reverse())
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id,])
    const fetchDiscountPData = useCallback(async () => {
        try {
            const response = await axios.get(AMOUNT_DISCOUNT_URL(business_id), {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                // params: {
                //     "business_id": business_id
                // }
            })
            setDiscountP(response?.data.reverse())
        } catch (error) {
            console.log(error)
        }
    }, [token, business_id])
    const handleDiscount = async () => {
        try {
            await axios.post(AMOUNT_DISCOUNT_URL(business_id),
                JSON.stringify({ name, amount, valid_from, valid_to }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            fetchDiscountData()
            handleOk()
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add customer');
            }
            errRef.current.focus();
        }
    }
    const handleDiscountP = async () => {
        try {
            await axios.post(PERCENT_DISCOUNT_URL(business_id),
                JSON.stringify({ name, percent, valid_from, valid_to }),
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "content-type": "application/json"
                    },
                    withCredentials: true,
                    // params: {
                    //     "business_id": business_id
                    // }
                }
            )
            fetchDiscountPData()
            handleOk()
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Cant add customer');
            }
            errRef.current.focus();
        }
    }
    useEffect(() => {
        fetchDiscountData().catch(console.error())
        fetchDiscountPData().catch(console.error())
    }, [fetchDiscountData, fetchDiscountPData])
    const onRadioChange = (e) => {
        setValue(e.target.value)
    }
    if (!token) {
        return <Redirect to="/" />
    }
    if (!business_id) {
        return <Redirect to="/businesses" />
    }
    return (
        <Row className="dash-web-layout">
            <Col span={24}>
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col span={20}>
                            <Title level={3}>Discount Overview</Title>
                        </Col>
                        <Col span={4}>
                            <div className="log-btn">
                                <Dropdown overlay={menu} placement="topCenter">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        style={{
                                            marginLeft: "auto",
                                            background: "#ffff",
                                            borderColor: "#1DAFED"
                                        }}
                                    ><p style={{ color: '#1DAFED' }}>Add Discount</p>
                                    </Button>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Divider dashed="true" />
            <Col span={24} >
                <Card bordered={false} className="criclebox " title="Discount Table"
                    extra={
                        <>
                            <Radio.Group value={value} onChange={onRadioChange}>
                                <Radio.Button value={1}>By Amount</Radio.Button>
                                <Radio.Button value={2}>By Percentage</Radio.Button>
                            </Radio.Group>
                        </>
                    }
                >
                    {value === 1 &&
                        <Table columns={columns} dataSource={discount} />
                    }
                    {value === 2 &&
                        <Table columns={percentage_columns} dataSource={discountP} />
                    }
                </Card>
            </Col>
            {keys === 1 &&
                <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                    <Card className="criclebox ">
                        <Title className="font-regular text-muted" level={4}>
                            Add Discount Details here
                        </Title>
                        <p ref={errRef} className='Error-p'>
                            {errMsg}
                        </p>
                        <Form onFinish={handleDiscount}>
                            <Form.Item
                                className="username"
                                label={<>Discount Name<span style={{ color: "red" }}>*</span></>}
                                name="name"
                                id="name"
                                onChange={onChangeName}
                                value={name}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Discount Name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Discount Name" />
                            </Form.Item>
                            <Form.Item
                                className="username"
                                label={<>Discount Amount<span style={{ color: "red" }}>*</span></>}
                                name="amount"
                                id="amount"
                                onChange={onChangeAmount}
                                value={amount}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Discount Amount!",
                                    },
                                ]}
                            >
                                <Input placeholder="Discount Amount" />
                            </Form.Item>
                            <Form.Item
                                className="username"
                                label={<>Duration<span style={{ color: "red" }}>*</span></>}
                                name="valid_from"
                                id="valid_from"
                                value={valid_from}
                                rules={[
                                    {
                                        required: false
                                    },
                                ]}
                            >
                                <Space>
                                    <RangePicker onChange={onChangeDate} />
                                </Space>
                            </Form.Item>
                            <Form.Item>
                                <div className="header-col sign-in-btn">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%", background: "#1DAFED" }}
                                        shape="round"
                                    >
                                        ADD
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </Modal>
            }
            {keys === 2 &&
                <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                    <Card className="criclebox ">
                        <Title className="font-regular text-muted" level={4}>
                            Add Discount Details here
                        </Title>
                        <p ref={errRef} className='Error-p'>
                            {errMsg}
                        </p>
                        <Form onFinish={handleDiscountP}>
                            <Form.Item
                                className="username"
                                label={<>Discount Name<span style={{ color: "red" }}>*</span></>}
                                name="name"
                                id="name"
                                onChange={onChangeName}
                                value={name}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Discount Name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Discount Name" />
                            </Form.Item>
                            <Form.Item
                                className="username"
                                label={<>Discount Percent<span style={{ color: "red" }}>*</span></>}
                                name="percent"
                                id="percent"
                                onChange={onChangeAmountP}
                                value={amount}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Discount Percent!",
                                    },
                                ]}
                            >
                                <Input placeholder="Discount Percent" />
                            </Form.Item>
                            <Form.Item
                                className="username"
                                label={<>Duration<span style={{ color: "red" }}>*</span></>}
                                name="valid_from"
                                id="valid_from"
                                value={valid_from}
                                rules={[
                                    {
                                        required: false
                                    },
                                ]}
                            >
                                <Space>
                                    <RangePicker onChange={onChangeDate} />
                                </Space>
                            </Form.Item>
                            <Form.Item>
                                <div className="header-col sign-in-btn">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%", background: "#1DAFED" }}
                                        shape="round"
                                    >
                                        ADD
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </Modal>
            }
            <Modal title="Edit Amount Discount" okText="Save" visible={isEditing} onCancel={() => { resetEditing() }}
                onOk={() => {
                    setDiscount((pre) => {
                        return pre.map((record) => {
                            if (record.discount_amount_id === editDiscount.discount_amount_id) {
                                return patchDiscountData(editDiscount)
                            } else {
                                return record
                            }
                        })
                    })
                    resetEditing()
                }}>
                <Form.Item className="username" label="Dicount Name">
                    <Input value={editDiscount?.name}
                        onChange={(e) => {
                            setEditDiscount(pre => {
                                return { ...pre, name: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item className="username" label="Dicount Amount">
                    <Input value={editDiscount?.amount}
                        onChange={(e) => {
                            setEditDiscount(pre => {
                                return { ...pre, amount: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item className="username" label="Duration">
                    <RangePicker
                        onChange={(data, dateString) => {
                            setEditDiscount(pre => {
                                return { ...pre, valid_from: dateString[0], valid_to: dateString[1] }
                            })
                        }} />
                </Form.Item>
            </Modal>
            <Modal title="Edit Percent Discount" okText="Save" visible={isEditingDp} onCancel={() => { resetEditingDp() }}
                onOk={() => {
                    setDiscountP((pre) => {
                        return pre.map((record) => {
                            if (record.discount_percent_id === editDiscountP.discount_percent_id) {
                                return patchDiscountPData(editDiscountP)
                            } else {
                                return record
                            }
                        })
                    })
                    resetEditingDp()
                }}>
                <Form.Item className="username" label="Dicount Name">
                    <Input value={editDiscountP?.name}
                        onChange={(e) => {
                            setEditDiscountP(pre => {
                                return { ...pre, name: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item className="username" label="Dicount Percent">
                    <Input value={editDiscountP?.percent}
                        onChange={(e) => {
                            setEditDiscountP(pre => {
                                return { ...pre, percent: e.target.value }
                            })
                        }} />
                </Form.Item>
                <Form.Item className="username" label="Duration">
                    <RangePicker
                        onChange={(data, dateString) => {
                            setEditDiscountP(pre => {
                                return { ...pre, valid_from: dateString[0], valid_to: dateString[1] }
                            })
                        }} />
                </Form.Item>
            </Modal>
        </Row>
    )
}

export default Discount
