import React from 'react';

const AddCategory = () => {
  return (
      <>
      <p>Category</p>
      </>
  ) 
};

export default AddCategory;
